.tool-events-section {
  padding: 60px 0 0;

  .inner-row {
    padding-top: 30px;
    border-bottom: 1px solid #a3e1ee;
  }

  .events-slider-container {
    h3 {
      margin: 10px 0;
      color: $black;
      text-transform: uppercase;
    }

    .slider-item {
      margin-right: 20px;
      @media (max-width: $mobile) {
        opacity: 0.5;
        transition: opacity 500ms;
        width: 270px;
        padding-bottom: 20px;
        &.slick-active {
          opacity: 1;
        }
      }
    }

    .img-box {
      .image-tag {
        position: relative;
        width: 100%;

        img {
          max-width: 360px;
          width: 100%;
        }
      }

      &:hover {
        a {
          h3 {
            color: $BatteryChargedBlue;
          }

          &.image-tag {
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 360px;
              height: 100%;
              background: rgba(26, 181, 213, 0.31);
              z-index: 3;

              @media (max-width: $tablet) {
                width: 320px;
              }

              @media (max-width: $mobile) {
                width: 344px;
              }

              @media (max-width: ($mobile - 1)) {
                width: 270px;
              }
            }
          }
        }
      }

      span {
        display: block;
        margin-top: 42px;
        width: 100%;
        font-family: $akkuratProRegular;
        font-size: 14px;
        line-height: 14px;
        color: $black;
        text-transform: uppercase;
      }
    }

    .details {
      span {
        font-size: 14px;
        line-height: 14px;
        display: block;
        color: $oldsilver;
        font-family: $akkuratProRegular;
        margin-bottom: 5px;
      }

      p, ol {
        font-size: 14px;
        line-height: 20px;
        font-family: $akkuratProRegular;
        color: $black;
      }

      ol {
        padding-left: 1em;
      }

      p + ol, p + p, li + li {
        margin-top: 0.5em;
      }

      a {
        color: $blue;
      }
    }
  }

  .slick-list {
    padding-bottom: 50px;
    @media (max-width: $mobile) {
      padding-bottom: 20px;
    }
  }

  .slick-next,
  .slick-prev {
    width: auto;
    margin: 10px 0;
    font-family: $conduitITCStdMedium;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: $BatteryChargedBlue;
    bottom: 0;
    top: auto;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background-image: url(../../../assets/images/tools-ideas/iconNewsArrowRight.svg);
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: right;
      width: 100%;
      height: 100%;
      display: inline-block;
    }

    @media (max-width: $mobile) {
      display: none !important;
    }

    &.slick-disabled {
      filter: brightness(0);
      opacity: 0.3;
    }
  }

  .slick-prev {
    left: 0%;
    padding-left: 25px;

    &::before {
      // padding-right: 5px;
      color: $BatteryChargedBlue;
      transform: rotate(180deg);
    }
  }

  .slick-next {
    right: 0%;
    padding-right: 25px;

    &::before {
      color: $BatteryChargedBlue;
    }
  }

  .slick-dots {
    display: none !important;
    bottom: -40px;
    text-align: left;
    li {
      button {
        &::before {
          opacity: 1;
          width: 10px;
          height: 10px;
          border: none;
          border-radius: 50%;
          background-color: #eeeeee;
          color: #eeeeee;
          font-size: 0;
          line-height: 10px;
        }
      }
      &.slick-active {
        button {
          &::before {
            background-color: #1ab5d5;
            color: #1ab5d5;
          }
        }
      }
    }

    @media (max-width: $mobile) {
      display: block !important;
    }
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0 !important;
  }
}

.tool-ideas-section {
  padding: 80px 0;
  @media (max-width: $mobile) {
    padding: 80px 0 0;
  }
  .container {
    @media (max-width: $mobile) {
      padding: 0;
    }
  }
  .inner-container {
    background-color: $black;
    padding: 18px 20px 50px;
    @media (max-width: $mobile) {
      padding: 76px 20px 0px;
    }
  }
  .title-underline {
    color: $white;
    @media (max-width: $mobile) {
      font-size: 24px;
    }
    &::after {
      background-color: $white;
    }
  }
  .inner-row {
    margin-top: 60px;
    // display: flex;
    // justify-content: space-between;
    display: grid;
    // grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    text-align: center;
    @media (max-width: $laptop) {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-flow: unset;
      grid-auto-columns: unset;
      gap: 20px;
    }
    @media (max-width: $mobile) {
      grid-template-columns: repeat(1, 1fr);
      margin-top: 40px;
      gap: 0;
    }
  }
  .inner-col {
    @media (max-width: $mobile) {
      border-bottom: 2px solid rgba(255, 255, 255, 0.5);
      &:last-child {
        border-bottom: none;
      }
    }
    .title-img {
      display: block;
      @media (max-width: $mobile) {
        display: flex;
        align-items: center;
        padding-bottom: 40px;
        padding: 40px 10px;
      }
      &:hover {
        img {
          opacity: 1;
        }
      }
    }
    &:last-child {
      a {
        padding-bottom: 0;
        @media (max-width: $mobile) {
          padding-bottom: 40px;
        }
      }
    }
    h3 {
      margin: 40px 0 0;
      color: $white;

      @media (max-width: $mobile) {
        font-size: 23px;
        margin: 0;
        display: inline-block;
        padding-left: 70px;
      }
    }
    img {
      width: 100%;
      max-width: 80px;
      aspect-ratio: 80/80;
      object-fit: contain;
      opacity: 0.5;
      @media (max-width: $mobile) {
        max-width: 50px;
      }
    }
    .content {
      display: none;

      @media (min-width: 768px) {
        display: none !important;
      }
      a {
        display: block;
        text-align: left;
        margin-top: 15px;
        margin-right: 15px;
        margin-left: 15px;
        padding-bottom: 15px;
        padding-left: 36px;
        font-size: 14px;
        text-transform: capitalize;
        border-bottom: 1px solid dimgray;
        color: $white;
        position: relative;
        &:last-child {
          border-bottom: none;
          padding-bottom: 40px;
        }
        &::before {
          content: "";
          background-image: url(../../../assets/images/tools-ideas/iconDownload.svg);
          background-repeat: no-repeat;
          background-size: 14px 18px;
          background-position: 8px 4px;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      @media (max-width: $mobile) {
        br {
          display: block;
        }
      }
    }
  }
  .video-url {
    .content {
      a {
        &::before {
          background-size: 18px;
          background-image: url(../../../assets/images/tools-ideas/iconPlay.svg);
        }
      }
    }
  }
}
.tool-details-section {
  // padding: 134px 0;
  padding: 50px 0;  

  @media (max-width: $tablet) {
    // padding: 70px 0;
  }

  @media (max-width: $mobile) {
    display: none;
  }

  .inner-row {
    display: grid;
    grid-template-columns: 0.6fr 2fr;    
    border-bottom: 1px solid #a3e1ee;
    @media (max-width: $contact_us_screen) {
      grid-template-columns: 1fr;
      gap: 40px;
    }
    .content {
      padding-right: 10px;
      @media (max-width: $contact_us_screen) {
        max-width: 530px;
      }
    }
  }
  .details-box {
    border-left: 1px solid #a3e1ee;
    @media (max-width: $contact_us_screen) {
      border-left: none;
    }
  }
  .details-box-inner {
    background-color: #eeeeee;
    display: grid;
    grid-template-columns: 0.7fr 1.5fr 0.6fr;
    gap: 20px;
    padding: 12px;
    margin: 0 0 24px 22px;
    @media (max-width: $contact_us_screen) {
      margin: 0 0 24px 0px;
    }
    .img-bg {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(../../../assets/images/tools-ideas/imgWPManage.webp);
      background-repeat: no-repeat;
      background-size: cover;
      aspect-ratio: 210/245;
      align-self: center;
      max-height: 238px;

      img {
        width: 100%;
        max-width: 90px;
        aspect-ratio: 90/90;
        object-fit: contain;
      }
    }
    .title{
      align-self: center;

      h3 {
        margin-bottom: 12px;
      }
    }
  }
  .user-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .user-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: $akkuratProRegular;
        font-size: 13px;
        text-transform: uppercase;
      }
      .video-time {
        display: none;
      }
      img {
        width: 100%;
        max-width: 40px;
        aspect-ratio: 40/40;
        object-fit: contain;
        border-radius: 50%;
        margin-left: 10px;
        @media (max-width: $tablet) {
          max-width: 30px;
          aspect-ratio: 30/30;
        }
      }
    }
  }
  .inner-row-bottom {
    text-align: right;
    a {
      position: relative;
      padding: 17px 78px 17px 0;
      margin: 0 17px 31px 0;
      display: inline-block;
      font-family: $conduitITCStdMedium;
      font-size: 18px;
      text-transform: uppercase;
      // color: $midBlue;
      letter-spacing: 0.05em;

      &:hover {
        color: $darkBlue;
      }

      &::after {
        content: "";
        background-image: url(../../../assets/images/tools-ideas/iconLibraryToggleOpen.svg);
        background-repeat: no-repeat;
        height: 100%;
        position: absolute;
        aspect-ratio: 200/150;
        top: 0;
        left: auto;
        right: -10px;
      }
      &.active {
        &::after {
          background-image: url(../../../assets/images/tools-ideas/iconLibraryToggleCloseHover.svg);
        }
      }
    }
  }
}
.short-videos {
  .details-box-inner {
    grid-template-columns: 0.5fr 1.5fr 0.8fr;
    background-color: #1ab5d5;
    align-items: center;
    .img-bg {
      background-image: none;
    }
    .title {
      padding: 20px;
      border-left: 1px solid #fff;
      h3,
      p {
        color: $white;
      }
    }
  }
  .user-box {
    background-position: center;
    margin: -12px;
    padding: 12px;
    position: relative;
    aspect-ratio: 1/1;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 152, 185, 0.6);
    }
    .user-details {
      align-self: flex-start;
      color: $white;
      position: relative;
      .video-time {
        display: block;
      }
      .name,
      img {
        display: none;
      }
    }
    a {
      align-self: center;
      position: relative;
      // border-color: transparent;
      @include watch-vide-btn-after;
      padding-right: 40px;
    }
  }
}
