.case-studies-temp-hero {
  margin: 100px 0;

  @media (max-width: $mobile) {
    margin: 80px 0;
  }

  sup {
    line-height: 1;
  }

  .inner-row {
    display: flex;
    gap: 200px;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $tablet) {
      display: block;
    }

    .title-part {
      max-width: 500px;

      @media (max-width: $tablet) {
        max-width: 100%;
        margin-bottom: 40px;

        p {
          display: none;
        }
      }

      h3, .typography-h3 {
        @media (max-width: $mobile) {
          font-size: 28px;
        }
      }

      img {
        @media (min-width: 992px) {
          display: none;
        }

        width: 100%;

        @media (max-width: $mobile) {
          width: calc(100% + 40px);
          margin: 0px -20px;
        }
      }
    }

    .project-industry {
      max-width: 200px;

      @media (max-width: $tablet) {
        max-width: 100%;
      }

      p:not(.description):not(.no-link):not(.quote) {
        font-size: 12px;
        font-family: $akkuratProRegular;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.015em;
      }

      h6 {
        font-family: $akkuratProRegular;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.075em;
        margin-bottom: 10px;

        &.attribution {
          // reset to base h6 styles...
          font-size: 23px;
          font-weight: 500;
          margin-bottom: 30px;
          line-height: 104.348%;
          letter-spacing: 0.46px;
          text-transform: uppercase;
          font-family: $conduitITCStdMedium;
        }
      }

      .project {
        margin-bottom: 35px;
      }

      .industry {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p.description {
          font-size: 15px;
          line-height: 23px;
          margin-top: 50px;

          @media (min-width: 992px) {
            display: none;
          }
        }

        p.no-link {
          margin-top: 0;
          font-family: $conduitITCStdRegular;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          display: inline-block;
        }

        > a:not(.pdf) {
          margin-top: 0;
          font-family: $conduitITCStdRegular;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          border-bottom: 1px solid #8eccd9;
          display: inline-block;

          &:not(:hover) {
            color: $black;
          }
        }

        > a {
          display: inline-block;
          margin-top: 36px;
          color: $midBlue;
          font-family: $conduitITCStdRegular;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0.05em;
          text-transform: uppercase;

          &:hover {
            color: $blue;
          }

          @media (max-width: $tablet) {
            display: none;
          }
        }

        img {
          @media (min-width: 992px) {
            display: none;
          }

          @media (max-width: $tablet) {
            margin-top: 87px;
            width: 100%;
          }

          @media (max-width: $mobile) {
            margin: 0px -20px;
            width: calc(100% + 40px);
            margin-top: 50px;
          }
        }

        .quote-text {
          @media (min-width: 992px) {
            display: none;
          }

          @media (max-width: $tablet) {
            margin-top: 27px;

            p {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}

.case-studies-section {
  sup {
    line-height: 1;
  }

  .component-item {
    &:not(:last-child) {
      margin-bottom: 140px;

      @media (max-width: $tablet) {
        margin-bottom: 100px;
      }
    }

    .image-gallery {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      max-width: 1070px;
      margin: 80px auto;
      width: 100%;

      @media (max-width: $mobile) {
        gap: 20px;
        grid-template-columns: 1fr;
        width: calc(100% + 40px);
        margin: 0px -20px;
        margin-bottom: 50px;
      }

      img {
        width: 100%;
        border: 1px solid #cdcfd0;
        display: block !important;

        @media (max-width: $mobile) {
          border: none;
        }
      }
    }

    .case-studies-banner-img {
      text-align: center;
      margin-bottom: 75px;

      @media (max-width: $tablet) {
        display: none;
      }

      img {
        width: 100%;
        max-width: 940px;
        aspect-ratio: 900/590;
        object-fit: cover;
        display: block;
        margin: auto;
      }

      &.grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        padding: 80px;

        @media (max-width: $tablet) {
          display: none;
        }
      }
    }

    .content {
      h6 {
        font-size: 28px;
        max-width: 744px;
        margin: 0px auto 30px;

        &.attribution {
          // from base h6 style
          font-size: 23px;
        }
      }

      .description {
        max-width: 744px;
        margin: 0px auto;
        margin-bottom: 80px;

        @media (max-width: $mobile) {
          margin-bottom: 50px;
        }

        p {
          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
      }

      .description-large {
        max-width: 744px;
        margin: 0px auto;
        margin-bottom: 118px;

        @media (max-width: $mobile) {
          margin-bottom: 50px;
        }

        h5 {
          @media (max-width: $mobile) {
            font-size: 18px;
            line-height: 26px;
          }

          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
      }

      .quote-text {
        @media (max-width: $tablet) {
          display: none;
        }
      }

      img {
        @media (min-width: 992px) {
          display: none;
        }

        @media (max-width: $tablet) {
          width: 100%;
        }

        @media (max-width: $mobile) {
          margin: 0px -20px;
          width: calc(100% + 40px);
        }
      }
    }
  }
}

.quote-text {
  max-width: 744px;
  margin: 0px auto;
}

.casestudies-next {
  .container {
    display: flex;
    justify-content: start;
    a {
      position: relative;
      margin-left: 20px;

      br {
        display: block;
      }

      &::after {
        content: "";
        position: absolute;
        width: 15px;
        height: 13px;
        top: 5px;
        left: -20px;
        background-image: url(../../images/services/list-arrow.svg);
        background-size: contain;
        transform: rotate(180deg);
      }
    }
  }
}

.hand-picked {
  .case-studies-select-box {
    width: 710px;
    min-height: 218px;
    padding-right: 55px;
    padding-left: 55px;
    padding-top: 33px;
    padding-bottom: 32px;
    background-color: #e7f5fa;
    @media (max-width: $tablet) {
      width: 100%;
    }
    @media (max-width: $mobile) {
      padding: 32px 23px;
      min-height: auto;
    }
  }
  .title {
    h3 {
      font-size: 49px;
      line-height: 52px;
      color: $grey;
      @media (max-width: $tablet) {
        font-size: 37px;
        padding-left: 55px;
      }
      @media (max-width: $mobile) {
        font-size: 28px;
        line-height: 30px;
        padding-left: 15px;
      }
    }
  }
  .modul-box {
    margin-top: -100px;
    @media (max-width: $tablet) {
      padding: 0 55px;
    }
    @media (max-width: $mobile) {
      padding: 0;
      margin-top: 10px;
    }
    a {
      position: relative;
      display: inline-flex;
      width: 100%;
      &:hover {
        .detail-box {
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-end;
          height: 100%;
          padding-bottom: 60px;
          background-color: rgba(60, 60, 60, 0.9);
          @media (max-width: $laptop) {
            padding-bottom: 0px;
            justify-content: center;
          }
          .label-title {
            width: 62%;
            color: $white;
            font-size: 24px;
            line-height: 23px;
            position: relative;
            @media (max-width: $tablet) {
              width: 100%;
              font-size: 20px;
              line-height: 20px;
            }
            &::before {
              content: "";
              display: block;
              position: absolute;
              top: -8px;
              left: 0;
              width: 37px;
              height: 1px;
              background-color: $white;
            }
          }
          .label-desc {
            font-size: 13px;
            line-height: 15px;
            display: block;
            margin-top: 16px;
            color: $white;
            font-family: $akkuratProRegular;
            text-transform: none;
          }
        }
      }
    }
    .detail-box {
      padding: 14px 23px;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: #eeeeee;
      color: $grey;
      font-family: $conduitITCStdMedium;
      font-weight: 400;
      text-transform: uppercase;
      @media (max-width: $tablet) {
        font-size: 14px;
        line-height: 18px;
      }
      .label-desc {
        display: none;
      }
    }
    .box-group {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      margin-bottom: 130px;
      padding-bottom: 47px;
      position: relative;
      @media (max-width: $laptop) {
        padding-bottom: 0;
      }
      @media (max-width: $tablet) {
        margin-bottom: 80px;
      }
      &:last-child {
        margin-bottom: 0;
        @media (max-width: $laptop) and (min-width: 992px) {
          margin-left: 33.33%;
          padding-bottom: 0;
          .small {
            width: 49%;
          }
        }
      }
      &:nth-child(2) {
        @media (max-width: $laptop) {
          margin-bottom: 230px;
        }
        @media (max-width: $tablet) {
          margin-bottom: 66px;
        }
      }
      &:nth-child(4) {
        @media (max-width: $laptop) {
          margin-bottom: 230px;
        }
        @media (max-width: $tablet) {
          margin-bottom: 66px;
        }
      }
    }
  }
  @media (min-width: 992px) {
    .offset {
      margin-left: calc(33.33% - -4px);
    }

    .half-offset {
      margin-left: calc(16.33% - -4px);
    }
  }

  .small {
    width: calc(33.33% - 7px);
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    aspect-ratio: 1/1;
    @media (max-width: $tablet) {
      width: calc(50% - 7px);
    }
    @media (max-width: $mobile) {
      width: 100%;
      aspect-ratio: auto;
    }
    a {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .big {
    @media (min-width: 768px) {
      width: calc(50% - 7px);
    }
  }
  @media (max-width: $laptop) and (min-width: 992px) {
    .offset-laptop {
      margin-left: calc(33.33% - -4px);
    }
    .half-offset-lapy {
      margin-left: calc(16.33% - -4px);
    }
    .offset-right-lapy {
      margin-right: calc(33.33% - -4px);
    }
    .half-offset-right-lapy {
      margin-right: calc(16.33% - -4px);
    }
    .lapy-automic {
      order: 2;
      margin-left: 0;
      position: absolute;
      bottom: -12.8%;
      height: auto;
    }
    .lapy-lakehead-university {
      width: 100%;
      height: 300px;
      margin-top: -153px;
      a {
        width: calc(33.33% - 7px);
      }
    }
  }
  .credit-suisse-inner {
    @media (max-width: $tablet) and (min-width: 640px) {
      position: relative;
      top: 150px;
    }
  }
  @media (max-width: $tablet) and (min-width: 640px) {
    .w-tablet-100 {
      width: 100%;
    }
  }

  .get-in-touch {
    display: none;
    @media (max-width: $mobile) {
      display: block;
      padding: 55px 0;
      a {
        position: relative;
        display: block;
        background-image: url(../../images/casestudies/graphicRelatedSm@2x.webp);
        background-repeat: no-repeat;
        background-size: 100% 170px;
        padding: 9px 0px 25px 27px;
      }
      h6 {
        font-family: $conduitITCStdBold;
        font-size: 96px;
        font-weight: 400;
        line-height: 96px;
        color: $white;
        display: inline-block;
        margin-bottom: 0;
      }
      p {
        color: $white;
        font-family: $akkuratProRegular;
        font-size: 16px;
        line-height: 22px;
        width: 60%;
      }
      span {
        padding-top: 0;
        box-sizing: border-box;
        position: absolute;
        top: 25px;
        right: 20px;
        color: white;
        font-family: $conduitITCStdMedium;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-transform: uppercase;
        display: block;
        width: 50px;
        height: 73px;
        &::before {
          content: "";
          position: absolute;
          top: 2px;
          left: -10px;
          width: 1px;
          height: 100%;
          background-color: $blue;
        }
        &::after {
          content: "";
          position: absolute;
          top: 42px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-image: url(../../images/icons/common/arrow-thik.svg);
          background-repeat: no-repeat;
          background-size: 40px;
        }
      }
    }
  }
  .box-module-group {
    position: absolute;
    // bottom: 0;
    bottom: -54px;
    height: inherit;
    align-items: flex-end;

    @media (max-width: $large_screens - 1px) {
      bottom: -93px;
    }

    @media (max-width: $laptop) {
      left: 50%;
      bottom: 30%;
    }

    @media (max-width: $tablet) {
      position: relative;
      top: auto;
      left: 0;
      bottom: 0;
      align-items: flex-start;
    }

    @media (max-width: $mobile) {
      position: relative;
      left: 0;
      bottom: 0;
      align-items: flex-start;
    }
  }
  .box-module-inner {
    bottom: -107px;
    @media (max-width: $laptop) {
      // order: 3;
      position: relative;
      left: 0;
      bottom: 31px;
    }
    .box-module-content {
      &::before {
        @media (max-width: $laptop) {
          top: 0;
          bottom: auto;
          height: 30px;
        }
        @media (max-width: $tablet) and (min-width: 640px) {
          top: 50%;
          right: -15%;
          left: auto;
          height: 1px;
          width: 30px;
        }
        @media (max-width: 640px) {
          top: 20px;
        }
      }
    }
  }
  .box-module-content {
    padding-right: 26px;
    padding-left: 32px;
    padding-top: 32px;
    position: relative;
    @media (max-width: 640px) {
      padding-top: 50px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 20px;
      background-color: #a1a3a6;
      @media (max-width: $laptop) {
        top: auto;
        bottom: -25%;
        height: 40px;
      }
      @media (max-width: $tablet) {
        top: 0;
        bottom: 0;
        height: 25px;
      }
    }
    h6 {
      color: $black;
      font-style: italic;
      font-family: $canelaLight;
      font-weight: 400;
      margin-bottom: 20px;
      text-transform: none;
      @media (max-width: $tablet) {
        font-size: 18px;
        line-height: 23px;
      }
    }
    .attribution {
      font-style: normal;
      font-weight: 500;
      line-height: 104.348%;
      text-transform: uppercase;
      font-family: $conduitITCStdMedium;

      @media (max-width: $tablet) {
        font-size: 23px;
        line-height: 104.348%;
      }

      .attribution-title {
        @extend body;
        text-transform: none;

        @media (max-width: $tablet) {
          font-size: 18px;
          line-height: 144.444%;
        }
      }
    }
  }
  .blog-box-cta {
    align-self: center;
    @media (max-width: $tablet) and (min-width: 640px) {
      width: calc(51% - 7px);
    }
    @media (max-width: 640px) {
      display: none;
    }
    span {
      display: block;
    }
    a {
      padding-left: 20px;
      display: block;
      &:hover {
        .blog-author {
          color: $blue;
        }
      }
    }
    .blog-label {
      font-size: 12px;
      line-height: 14px;
      color: #a1a3a6;
      font-family: $akkuratProRegular;
      font-weight: 400;
      text-transform: uppercase;
    }
    .blog-title {
      font-size: 36px;
      line-height: 37px;
      font-family: $conduitITCStdMedium;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    .blog-author {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 35px;
      padding-bottom: 3px;
      text-transform: uppercase;
      position: relative;
      font-family: $akkuratProRegular;
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 42px;
        height: 1px;
        background-color: #1ab5d5;
        font-size: 0;
      }
    }
    .blog-desc {
      font-size: 14px;
      line-height: 20px;
      color: #3c3c3c;
      font-family: $akkuratProLight;
      font-weight: 400;
      margin-bottom: 7px;
    }
    .blog-link {
      font-size: 14px;
      line-height: 20px;
      color: #0098b9;
      font-family: $akkuratProLight;
      font-weight: 400;
      position: relative;
      padding-left: 20px;
      &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: url(../../images/icons/common/arrow-thik.svg);
        background-repeat: no-repeat;
        background-size: 13px;
      }
    }
  }
  .our-work-sec {
    position: relative;
    @media (max-width: $laptop) and (min-width: 992px) {
      margin-left: 33%;
    }
    .line-hr {
      // width: calc(29% - 5%);
      width: 32.5%;
      text-align: center;
    }
    .hr {
      width: 1px;
      height: 150px;
      display: inline-block;
      background-color: #cdcfd0;
    }
    .projects-count {
      padding: 20px 60px;
      margin-bottom: 0;
      @media (max-width: $mobile) {
        padding: 20px;
      }
      .num-text {
        text-align: left;
      }
      @media (max-width: $laptop) {
        .count-num {
          font-size: 90px;
          line-height: 40px;
        }
        .count-text {
          font-size: 51px;
          line-height: 40px;
        }
      }
    }
    .projects-years {
      padding: 0px 60px;
      grid-template-columns: 1fr;
      @media (max-width: $mobile) {
        padding: 0 20px;
      }
      .count-text {
        max-width: 100%;
      }
      @media (max-width: $laptop) {
        .count-text {
          font-size: 36px;
          line-height: 40px;
        }
      }
    }
  }
}
.select-box-option {
  display: flex;
  @media (max-width: $mobile) {
    flex-wrap: wrap;
  }
  label {
    font-size: 22px;
    line-height: 35px;
    color: $grey;
    font-family: $akkuratProLight;
    font-weight: 400;
  }
  span {
    border-bottom: 1px solid $blue;
    display: inline-block;
    margin-top: 5px;
    color: $blue;
    cursor: pointer;
    position: relative;
    padding-right: 100px;
    @media (max-width: $mobile) {
      display: block;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      background-image: url(../../images/casestudies/downArrowBlue.svg);
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: center;
    }
    &.open {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
  .toggle-menu {
    margin-left: 10px;
    position: relative;
    @media (max-width: $mobile) {
      width: 100%;
      margin-left: 0;
    }
  }
  ul {
    list-style: none;
    display: none;
    position: absolute;
    top: 32px;
    left: 0px;
    right: 0;
    z-index: 2;
    width: 250px;
    @media (max-width: $mobile) {
      width: 100%;
    }
    li {
      background-color: $blue;
      padding: 9px 35px;
      &:first-child {
        padding-top: 15px;
      }
      &:last-child {
        padding-bottom: 35px;
      }
      a {
        color: $white;
        position: relative;
        &.selected {
          &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: -25px;
            width: 100%;
            height: 100%;
            background-image: url(../../images/icons/common/arrow-thik.svg);
            background-repeat: no-repeat;
            filter: brightness(0) invert(1);
            background-size: 13px;
          }
        }
        &:hover {
          &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: -25px;
            width: 100%;
            height: 100%;
            background-image: url(../../images/icons/common/arrow-thik.svg);
            background-repeat: no-repeat;
            filter: brightness(0) invert(1);
            background-size: 13px;
          }
        }
      }
    }
  }
}
.case-studies-page {
  .inner-row {
    gap: 50px;
    .hand-picked {
      padding-top: 50px;
    }
    @media (max-width: $laptop) {
      grid-template-columns: auto;
      .work-side-nav {
        display: none;
      }
    }
  }
}
.visible-mobile {
  display: none;
  @media (max-width: $mobile) {
    display: block;
    padding: 26px 0 5px;
    a {
      display: block;
      padding-left: 30px;
      color: #f7902f;
      font-family: $canelaLight;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      position: relative;
      font-style: italic;
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: url(../../images/casestudies/iconCircleDownOrange.svg);
        background-repeat: no-repeat;
        background-size: 26px;
      }
    }
  }
}
