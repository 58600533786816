.ux-training-page {
    position: relative;
    overflow-x: hidden;

    .site-header {
        position: fixed;
    }

    .page-sub-header-section {
        position: fixed;
        z-index: 30;
    }
}

.page-sub-header-section {

    .inner-row {
        .training-subnav-container {
            flex-direction: column;
            align-items: flex-start;

            span {
                color: $blue;
            }
        }
    }
}

.ux-training-section {
    margin: 55px 0 150px;

    @media (max-width: $tablet) {
        margin: 100px 0px;
    }

    @media (max-width: $mobile) {
        margin: 55px 0px 100px;
    }

    .row {
        display: grid;
        grid-template-columns: 1.5fr 0.8fr;
        align-items: center;

        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
            gap: 30px;
        }

        .content {
            display: flex;
            align-items: center;

            @media (max-width: $mobile) {
                flex-direction: column;
                gap: 30px;
                align-items: flex-start;
            }

            .inner {
                max-width: 450px;

                .ux-trainig-description {
                    span {
                        color: $blue;
                    }
                }

                @media (max-width: $laptop) {
                    max-width: 360px;
                }

                p {
                    margin: 35px 0 41px;
                }
            }

            .side-image {
                border-left: 1px solid $nonphotoblue;
                padding: 35px 0px 56px 56px;
                margin-left: 44px;

                @media (max-width: $mobile) {
                    margin-left: 0;
                    padding: 30px;
                    border-top: 1px solid $nonphotoblue;
                    border-left: none;
                    width: 100%;
                    text-align: center;
                }

                img {
                    max-width: 137px;
                }

                img.render-correct-image-size {
                    width: 100%;
                    height: 100%;
                }

                span {
                    display: block;
                    font-size: 14px;
                    line-height: 18px;
                    color: $blue;
                    font-family: $akkuratProRegular;
                }
            }
        }

        .work-process {
            .inner {
                min-height: 246px;
                max-width: 280px;
                padding: 60px 30px 30px;
                position: relative;
                background-position: center;
                background-size: cover;
                margin-left: auto;

                @media (max-width: $mobile) {
                    margin: 0px auto;
                }

                h6,
                span {
                    color: $white;
                    position: relative;
                    margin-bottom: 5px;
                }

                span {
                    font-size: 14px;
                    display: block;
                }

                .visit-vid-btn {
                    margin-top: 65px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    background-color: $blue;
                    opacity: 0.8;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }

            }
        }
    }
}

.training-computer-section {
    margin: 150px 0;

    @media (max-width: $tablet) {
        margin: 100px 0px;
    }

    .row {
        display: grid;
        grid-template-columns: 1.5fr 0.8fr;
        gap: 25px;

        @media (max-width: $laptop) {
            grid-template-columns: 1fr;
        }

        .team-image {
            img {
                width: 100%;
            }
        }

        .content-box {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media (max-width: $laptop) {
                flex-direction: column;
            }

            @media (max-width: $tablet) {
                flex-direction: row;
                gap: 110px;
            }

            @media (max-width: $mobile) {
                flex-direction: column;
                gap: 30px;
            }


            .simply-put {
                max-width: 280px;
                padding: 25px;
                border: 1px solid $buttonBlue;

                @media (max-width: 1280px) {
                    padding: 20px;
                }

                @media (max-width: $laptop) {
                    max-width: 100%;
                    margin: 0;
                }

                span {
                    font-size: 14px;
                    text-transform: uppercase;
                    color: $blue;
                    font-family: $akkuratProRegular;

                    &:last-child {
                        text-transform: none;
                        display: block;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

}


.training-bottom-section {
    margin-top: 60px;

    @media (max-width: $mobile) {
        margin-top: 105px;
    }

    @media only screen and (max-width: $tablet) {
        margin-top: 110px;
    }

    @media only screen and (max-width: $laptop) {
        margin-top: 140px;
    }

    .container {
        text-align: center;

        .bottom-link {
            position: relative;
            display: inline-block;
            border-bottom: 1px solid $non-photo-blue;
        }
    }
}

// New Page CSS
.ux-hero-section {
    position: relative;

    .ux-hero-content {
        max-width: 488px;
        width: 100%;
        padding: 110px 0 0;

        @media (max-width: $laptop) {
            padding: 80px 0 0;
            max-width: 410px;
        }

        @media (max-width: $mobile) {
            padding: 50px 0 0;
        }

        .sub-title {
            margin-bottom: 17px;
            letter-spacing: 0.56px;
        }

        h1 {
            margin-bottom: 43px;

            @media (max-width: $laptop) {
                // font-size: 14vw;
            }
        }

        .arrow-down {
            margin: 2px 0px 48px -4px;
        }

        .ux-title-slider {

            &:not(.slick-initialized) {

                span:not(:first-child) {
                    display: none;
                }
            }
        }
    }

    .ux-hero-slider {
        position: absolute;
        right: -790px;
        top: 0;
        max-width: 1564px;
        z-index: -1;

        @media (max-width: ($large_screens - 1px)) {
            right: -859px;
        }

        @media (max-width: $laptop) {
            right: -485px;
            max-width: 1036px;
        }

        @media (max-width: $tablet) {
            right: -660px;
        }

        @media (max-width: $mobile) {
            max-width: 200%;
            right: -125%;
            opacity: 0.2;
        }

        img {
            width: 100%;
            height: 100%;
            -webkit-mask-image: url(../../images/ux-training/ux-hero-shape.svg);
            -webkit-mask-size: contain;
            -webkit-mask-position: top right;
            -webkit-mask-repeat: no-repeat;
        }
    }
}

.ux-expert-section {
    margin-top: 240px;
    // margin-top: 77px;

    @media (max-width: $tablet) {
        margin-top: 60px;
    }

    @media (max-width: $mobile) {
        margin-top: 100px;
    }

    .sec-title {
        margin-bottom: 69px;
        max-width: 895px;
        margin-left: auto;

        @media (max-width: $mobile) {
            text-align: center;
        }
    }

    .icon-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
        max-width: 895px;
        margin-left: auto;

        @media (max-width: $mobile) {
            grid-template-columns: 1fr;
            gap: 60px;
        }

        .icon-grid-item {
            padding-right: 20px;

            h6 {
                margin: 31px 0 24px;
                line-height: 25.9px;
            }
        }
    }
}

%training-slider-controls {
    .slick-arrow {
        width: 42px;
        height: 42px;
        background-size: contain;
        background-repeat: no-repeat;
        top: 58%;
        z-index: 1;

        @media (max-width: $tablet) {
            top: 100%;
        }

        &::before {
            content: "";
        }

        &.slick-disabled {
            display: none !important;
        }

        &.slick-next {
            background-image: url(../../images/icons/common/slider_arrow.svg);
            right: 0px;
        }

        &.slick-prev {
            background-image: url(../../images/icons/common/slider_arrow_prev.svg);
            left: 0;
        }
    }

    .slick-dots {

        li {
            margin: 0px 1px;

            button {
                &::before {
                    color: $oldsilver;
                    opacity: 1;
                    font-size: 14px;
                }
            }

            &.slick-active {

                button {
                    &::before {
                        color: $blue;
                    }
                }
            }
        }
    }
}

.testimonial-slider {
    margin-top: 109px;
    margin-bottom: 104px;
    background-color: $offWhite;
    padding: 88px 0 89px;
    position: relative;
    z-index: 1;

    @media (max-width: $mobile) {
        padding: 70px 0;
        margin: 70px 0;

    }

    .testimonial-main {

        .slider-inner {

            .content {
                display: flex;
                align-items: flex-start;
                gap: 20px;
                max-width: 773px;
                width: 100%;
                margin: 0px auto 50px;
                position: relative;
                left: -10px;
                padding-top: 10px;

                @media (max-width: $tablet) {
                    padding: 20px;
                    flex-direction: column;
                }

                svg {
                    min-width: 26px;
                    position: relative;
                    top: -12px;
                }

                h5 {
                    margin-bottom: 0;
                }

                hr {
                    background: $americansilver;
                    height: 1px;
                    width: 86px;
                    margin: 13px 0 17px;
                    border: none;
                }

                .author {
                    display: flex;
                    gap: 5px;
                    align-items: baseline;
                    margin-left: 2px;

                    h6 {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @extend %training-slider-controls;
        // .slick-arrow {
        //     width: 42px;
        //     height: 42px;
        //     background-size: contain;
        //     background-repeat: no-repeat;
        //     top: 58%;
        //     z-index: 1;

        //     @media (max-width: $tablet) {
        //         top: 100%;
        //     }

        //     &::before {
        //         content: "";
        //     }

        //     &.slick-disabled {
        //         display: none !important;
        //     }

        //     &.slick-next {
        //         background-image: url(../../images/icons/common/slider_arrow.svg);
        //         right: 0px;
        //     }

        //     &.slick-prev {
        //         background-image: url(../../images/icons/common/slider_arrow_prev.svg);
        //         left: 0;
        //     }
        // }

        // .slick-dots {

        //     li {
        //         margin: 0px 1px;

        //         button {
        //             &::before {
        //                 color: $oldsilver;
        //                 opacity: 1;
        //                 font-size: 14px;
        //             }
        //         }

        //         &.slick-active {

        //             button {
        //                 &::before {
        //                     color: $blue;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}

.scrolling-1-wrapper,
.scrolling-2-wrapper {
    @media (min-width: $laptop) {
        position: relative;
        // padding-left: 100vw;
        margin-left: 35vw;
        margin-right: 12vw;
        // overflow-x: hidden;

        // &:after {
        //     content: "";
        //     height: 101%;
        //     top: -1px;
        //     left: 0;
        //     position: absolute;
        //     z-index: 9;
        //     background-color: $white;
        //     width: 35vw;
        //     display: block;
        // }
    }

    @media (min-width: #{$laptop + 1}) and (max-width: #{$large_screens - 1}) {
        margin-left: 29vw;
        margin-right: 8vw;
    }
}

.learn-way-section {
    margin-bottom: 104px;
    // height: 100vh;
    overflow: hidden;

    .learn-way-container {
        display: block;

        @media (max-width: $laptop) {
            display: none;
        }

        @extend %training-slider-controls;

        // override placeholder control config
        .slick-arrow {
            top: 50%;

            &.slick-prev {
                left: -4vw;
            }
            &.slick-next {
                right: -4vw;
            }
        }

        .slick-dots {
            bottom: -35px;
        }
    }

    .learn-way-container-small {
        display: none;

        @media (max-width: $laptop) {
            display: flex;
        }

        .slider-slide {
            .slider-inner {
                display: grid;
                grid-template-columns: 1fr 1fr;
                transition: $transition;

                @media (max-width: $mobile) {
                    display: block;
                    // grid-template-columns: 1fr;
                }

                .content {
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: space-between;
                    background-color: $offWhite;
                    padding: 30px;

                    @media (max-width: $mobile) {
                        padding: 20px;
                    }

                    &.no-active {
                        background-color: $lightBlue;
                    }

                    .title {

                        h3 {
                            margin-bottom: 0.1em;
                        }

                        h3 + h6 {
                            margin-bottom: 28px;
                        }
                    }

                    .delivery-tags {
                        display: flex;
                        gap: 24px;
                        margin-bottom: 28px;
                        margin-top: 12px;

                        .tag {
                            .tag-img {
                                background-color: $offWhite;
                                width: 40px;
                                height: 40px;
                                border-radius: 50px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-bottom: 4px;
                            }

                            img {
                                display: block;
                            }
                        }
                    }

                    .btn-default-arrow {
                        align-self: flex-start;
                        margin-top: 12px;
                    }
                }

                .side-image {
                    display: block;
                    object-fit: cover;
                    min-width: 100%;

                    @media (max-width: $mobile) {
                        max-width: 100%;
                    }
                }

                .lear-image {
                    filter: grayscale(1);
                    -webkit-filter: grayscale(1);
                }
            }

            &:nth-child(2n+1) {
                .slider-inner {
                    .content.no-active {
                        background-color: $offWhite;
                    }
                }
            }

            &.slick-active,
            &.active,
            &:focus {
                .content {
                    background-color: $lightBlue;

                    .delivery-tags {
                        .tag {
                            .tag-img {
                                background-color: $lightBlue;
                            }
                        }
                    }
                }

                .lear-image {
                    filter: grayscale(0);
                    -webkit-filter: grayscale(0);
                }
            }

            @media (max-width: $laptop) {

                &:hover,
                &:focus {
                    .content {
                        // background-color: $offWhite;

                        .delivery-tags {
                            .tag {
                                .tag-img {
                                    background-color: $lightBlue;
                                }
                            }
                        }
                    }

                    .lear-image {
                        filter: grayscale(0);
                        -webkit-filter: grayscale(0);
                    }
                }
            }
        }

        @extend %training-slider-controls;

        // override placeholder control config
        .slick-arrow {
            top: 100%;
        }

        .slick-dots {
        }
    }

    .title-inner {
        display: flex;
        // justify-content: end;
        align-items: flex-start;
        gap: 110px;
        max-width: 895px;
        margin-left: auto;

        // margin-top: -100px;
        @media (max-width: $laptop) {
            gap: 40px;
            max-width: 100%;
        }

        @media (max-width: $mobile) {
            flex-direction: column;
            gap: 0px;
            max-width: 100%;
        }

        h5 {
            max-width: 370px;

            @media (max-width: $tablet) {
                max-width: 290px;
            }

            @media (max-width: $mobile) {
                max-width: 100%;
            }
        }
    }

    .learn-way-slider {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px;
        margin-top: 30px;

        @media (max-width: $laptop) {
            width: 90%;
            margin: auto;
            flex-wrap: wrap;
        }

        .slider-start-filler {
          -spacer-width: 25vw;
          min-width: var(-spacer-width);
          width: var(-spacer-width);
          max-width: var(-spacer-width);
          display: inline-block;

          @media (max-width: $laptop) {
            display: none;
          }
        }

        .slider-slide {
            // min-width: 774px;
            display: inline-block;

            @media (max-width: $laptop) {
                min-width: 100%;

            }

            .slider-inner {
                display: grid;
                // grid-template-columns: 48.5% 48.5%;
                grid-template-columns: 1fr 1fr;
                // gap: 20px;
                transition: $transition;
                height: 100%;

                @media (max-width: $mobile) {
                    display: block;
                    // grid-template-columns: 1fr;
                }

                .content {
                    background-color: $offWhite;
                    padding: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    transition: $transition;

                    @media (max-width: $tablet) {
                        padding: 30px;

                    }

                    @media (max-width: $mobile) {
                        padding: 20px;

                    }

                    &.no-active {
                        background-color: $lightBlue;
                    }

                    .title {

                        h3 {
                            margin-bottom: 0.1em;
                        }

                        h3 + h6 {
                            margin-bottom: 28px;
                        }
                    }

                    .delivery-tags {
                        display: flex;
                        gap: 24px;
                        margin-bottom: 28px;
                        margin-top: 12px;

                        .tag {
                            .tag-img {
                                background-color: $offWhite;
                                width: 40px;
                                height: 40px;
                                border-radius: 50px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-bottom: 4px;
                            }

                            img {
                                // margin-bottom: 4px;
                                display: block;
                            }
                        }
                    }

                    .btn-default-arrow {
                        align-self: flex-start;
                        margin-top: 12px;
                    }
                }

                .side-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }

                .lear-image {
                    filter: grayscale(1);
                    -webkit-filter: grayscale(1);
                }
            }

            &:nth-child(2n+1) {
                .slider-inner {
                    .content.no-active {
                        background-color: $offWhite;
                    }
                }
            }

            &.active,
            &.slick-active,
            &:focus {
                .content {
                    background-color: $lightBlue;

                    .delivery-tags {
                        .tag {
                            .tag-img {
                                background-color: $lightBlue;
                            }
                        }
                    }
                }

                .lear-image {
                    filter: grayscale(0);
                    -webkit-filter: grayscale(0);
                }
            }

            @media (max-width: $laptop) {

                &:hover,
                &:focus {
                    .content {
                        background-color: $offWhite;

                        .delivery-tags {
                            .tag {
                                .tag-img {
                                    background-color: $lightBlue;
                                }
                            }
                        }
                    }

                    .lear-image {
                        filter: grayscale(0);
                        -webkit-filter: grayscale(0);
                    }
                }
            }
        }
    }
}

.meet-our-instructors {
    .title-inner {
        gap: 60px;

        @media (max-width: $tablet) {
            gap: 20px;

        }
    }

    .learn-way-container {
        .container {
            @media (min-width: $laptop) {
                max-width: 630px;
            }
        }

        .learn-way-slider .slider-slide {
            .slider-inner .content {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: auto min-content min-content;
            }

            &:not(.active) .slider-inner .content {
                background-color: $lightgrey;
            }

            .meet-instructor-redirect {
                justify-self: flex-start;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: baseline;
                gap: 0.45em;

                &.btn-default-arrow {
                    margin-top: 24px;
                }

                &::after {
                    content: unset;
                }

                .cIconRedirect {
                    fill: $darkBlue;
                    transition: $transition;
                }

                &:hover {
                    .cIconRedirect {
                        fill: $blue;
                    }
                }
            }
        }
    }

    .learn-way-container-small {
        .slider-slide {
            .slider-inner .content {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: auto min-content min-content;
            }

            &:not(.slick-active) .slider-inner .content {
                background-color: $lightgrey;
            }

            .meet-instructor-redirect {
                justify-self: flex-start;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: baseline;
                gap: 0.45em;

                &.btn-default-arrow {
                    margin-top: 24px;
                }

                &::after {
                    content: unset;
                }

                .cIconRedirect {
                    fill: $darkBlue;
                    transition: $transition;
                }

                &:hover {
                    .cIconRedirect {
                        fill: $blue;
                    }
                }
            }
        }
    }
}

.upcoming-session-section {
    // margin-top: 120px;

    // @media (max-width: $tablet) {
    //     margin-top: 80px
    // }

    .container {
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    .title {
        max-width: 788px;
        width: 100%;
        margin-right: 107px;
        margin-bottom: 48px;

        @media (max-width: $tablet) {
            margin-right: 0;
        }

        h2 {
            margin-bottom: 57px;
        }
    }

    .session-list {
        max-width: 795px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 96px 28px;
        margin-right: 100px;

        @media (max-width: $tablet) {
            margin-right: 0;
            gap: 60px 20px;
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: $mobile) {
            grid-template-columns: 1fr;
        }

        .list-item {

            .location {
                background-color: $midBlue;
                padding: 16px 24px;
                display: flex;
                align-items: center;
                gap: 10px;

                span {
                    color: $white;
                    font-family: $akkuratProRegular;
                    font-size: 20px;
                    line-height: 20px;
                    position: relative;
                    top: 1px;
                }
            }

            .content {
                // background-color: $lightBlue;
                background-color: $lightBlue;
                padding: 26px 24px 21px;

                @media (max-width: $tablet) {
                    padding: 20px 18px 20px;
                }

                h3 {
                    margin-bottom: -2px;
                }

                h2 {
                    @include h1;
                    margin-bottom: 3px;
                }

                .session-category {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    margin-bottom: 30px;
                    margin-top: 33px;

                    span {
                        font-family: $akkuratProRegular;
                        margin-left: 6px;
                        font-size: 14px;
                        margin-top: 4px;
                    }
                }

                .btn-default-arrow {
                    &:hover {
                        &::after {
                            right: -30px;
                        }
                    }
                }
            }
        }
    }
}

.ux-team {
    margin-top: 157px;
    background-color: $lightBlue;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    position: relative;
    z-index: 1;

    @media (max-width: $tablet) {
        margin-top: 100px;
    }

    @media (max-width: $mobile) {
        grid-template-columns: 1fr;
    }

    &.revers {
        margin-top: 185px;
        margin-bottom: 120px;

        @media (max-width: $tablet) {
            margin-top: 100px;
        }

        @media (max-width: $mobile) {
            margin-top: 60px;
            margin-bottom: 60px;
        }

        .side-image {
            order: 2;

            @media (max-width: $mobile) {
                order: 1;
            }
        }

        .side-content {
            order: 1;

            @media (max-width: $mobile) {
                order: 2;
            }
        }
    }

    .side-image {
        display: block;
        width: 100%;
    }

    .side-content {
        padding: 88px;

        @media (max-width: $laptop) {
            padding: 30px;
        }

        @media (max-width: $tablet) {
            padding: 10px 20px;
        }

        @media (max-width: $mobile) {
            padding: 50px 20px;
        }

        h3 {
            margin: -3px 0 22px;
        }

        h5 {
            margin-bottom: 32px;
        }
    }
}

.faq-section {
    margin-top: 148px;

    @media (max-width: $tablet) {
        margin-top: 100px;
    }

    @media (max-width: $mobile) {
        margin-top: 60px;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: end;
    }

    .title {
        max-width: 894px;
        width: 100%;

        h2,
        h4 {
            max-width: 795px;
            width: 100%;
        }

        h2 {
            margin-bottom: 64px;

            @media (max-width: $tablet) {
                margin-bottom: 44px;
            }
        }

        a {
            &:not(:hover) {
                color: $darkBlue;
            }
        }
    }

    .faqs-main {
        max-width: 894px;
        width: 100%;
        margin-top: 21px;
        display: flex;
        justify-content: space-between;

        @media (max-width: $tablet) {
            flex-direction: column;
        }

        .faq-drop-down {
            max-width: 210px;
            margin-top: 15px;
            position: relative;

            .drop-down-trigger {
                position: relative;
                padding-right: 26px;
                cursor: pointer;

                @media (max-width: $tablet) {
                    padding: 10px;
                    margin-bottom: 15px;
                }

                &::after {
                    content: "";
                    background-image: url(../../images/icons/common/dropdown-arrow.svg);
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 13px;
                    height: 8px;
                    transition: $transition;
                }

                &::before {
                    content: "";
                    width: 50px;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    background-color: $blue;
                    top: -13px;
                    transition: $transition;

                    @media (max-width: $tablet) {
                        left: 10px;
                    }
                }

                &.active {

                    &::after {
                        transform: translateY(-50%) rotate(180deg);
                    }

                    &::before {
                        width: 100%;
                    }
                }
            }

            .drop-down-menu {
                list-style: none;
                opacity: 0;
                transition: $transition;
                position: absolute;
                background-color: $white;
                z-index: 1;

                @media (max-width: $tablet) {
                    box-shadow: 0px 10px 10px #00000017;
                    padding: 10px 10px 0;
                }

                &.active {
                    opacity: 1;
                }

                li {
                    margin-bottom: 16px;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: $akkuratProRegular;

                }
            }
        }

        .faq-accordion {
            max-width: 591px;
            width: 100%;

            @media (max-width: $tablet) {
                padding-left: 10px
            }

            .extender-accordion {

                .accordion {

                    .accordion-main {
                        border-bottom: 1px solid $americansilver;
                        margin-bottom: 24px;
                        padding-bottom: 22px;

                        &:last-child {
                            border-bottom: none;
                            margin-bottom: 0px;
                            padding-bottom: 0px;
                        }


                        .accordion-title-trigger {
                            position: relative;
                            cursor: pointer;

                            h5 {
                                max-width: 467px;
                                margin-bottom: 0;

                                @media (max-width: $mobile) {
                                    padding-right: 40px;
                                }
                            }

                            &::after {
                                content: "";
                                width: 16px;
                                height: 2px;
                                position: absolute;
                                top: 50%;
                                right: 13px;
                                background-color: $blue;
                                transition: $transition;
                            }

                            &::before {
                                content: "";
                                width: 2px;
                                height: 16px;
                                position: absolute;
                                top: calc(50% - 7px);
                                right: 20px;
                                background-color: $blue;
                                transition: $transition;
                            }
                        }

                        .accordion-description {
                            display: none;
                            margin-top: 30px;
                        }

                        &:first-child {
                            .accordion-description {
                                display: block;
                            }
                        }

                        &.open {

                            .accordion-title-trigger {
                                &::before {
                                    width: 16px;
                                    height: 2px;
                                    top: calc(50% - 0px);
                                    right: 13px;
                                }

                                &::after {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ux-client {
    margin-top: 120px;
    position: relative;
    z-index: 1;
}

.explore-perspectives {
    margin-top: 94px;

    .title-part {
        max-width: 588px;

        h3 {
            margin-bottom: 38px;

            &::after {
                bottom: -10px;
            }
        }
    }

    .side-img-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 64px;
        margin-top: 100px;
        padding-left: 32px;

        @media (max-width: $tablet) {
            gap: 24px;
        }

        @media (max-width: $mobile) {
            grid-template-columns: 1fr;
            gap: 60px;
            padding: 0;
        }

        .content {
            max-width: 512px;
            margin-top: 4px;

            span {
                font-size: 14px;
                line-height: 20px;
                font-family: $akkuratProRegular;
            }
        }

        .side-image {
            position: relative;


            img {
                display: block;

                @media (max-width: 1439px) {
                    width: 100%;
                }

                @media (max-width: $mobile) {
                    max-width: calc(100% - 30px);
                    margin-left: auto;
                }
            }

            &::before {
                content: "";
                width: 72.2%;
                height: calc(100% + 60px);
                position: absolute;
                left: -32px;
                top: -30px;
                background-color: $lightBlue;
                z-index: -1;

                @media (max-width: $mobile) {
                    left: 0px;
                }
            }
        }

        .content-pdf {
            display: inline-block;
            margin-top: 12px;

            .label {
                border-right: 1px solid $blue;
                margin-right: 10px;
                padding-right: 13px;
                letter-spacing: 0.16px;
                font-family: $akkuratProRegular;
            }

            .pdf {
                padding-right: 20px;
                background-image: url(../../images/services/download.svg);
                background-repeat: no-repeat;
                background-position: center right;
                font-family: $akkuratProRegular;
            }

            span,
            a {
                font-size: 14px;
                line-height: 20px;
                display: inline-block;

                &:not(:hover) {
                    color: $darkBlue;
                    letter-spacing: -0.04px;
                }
            }

            &:hover {

                a,
                span {
                    color: $blue;
                }

                .pdf {
                    background-image: url(../../images/services/download-hover.svg);
                }
            }
        }
    }
}

.blog-post-section {
    margin-top: 112px;

    @media (max-width: $mobile) {
        margin-top: 70px;

    }


    .post-listing {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        align-items: start;

        @media (max-width: $mobile) {
            grid-template-columns: 1fr;
            gap: 60px;
        }

        .post {
            color: $black;
            padding-right: 30px;

            @media (max-width: $tablet) {
                padding-right: 0px;
            }

            .thumbnail-image {
                overflow: hidden;
                position: relative;
                margin-bottom: 24px;
                max-width: 350px;

                img {
                    transition: $transition;
                    display: block;
                    width: 100%;
                    aspect-ratio: 350/233;
                    object-fit: contain;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: $blue;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: $transition;
                }
            }

            h6 {
                transition: $transition;
                margin-top: -4px;
                margin-bottom: 12px;
                line-height: 28px;
            }

            .author {
                display: flex;
                align-items: center;
                gap: 12px;

                span {
                    color: $darkBlue;
                    font-family: $akkuratProRegular;
                }

                img {
                    width: 36px;
                    aspect-ratio: 1/1;
                    border-radius: 100%;
                    object-fit: cover;
                }
            }

            p {
                margin-top: 26px;
            }

            .btn-default-arrow {
                margin-top: 17px;
            }

            &:hover {

                .thumbnail-image {

                    img {
                        transform: scale(1.05);
                    }

                    &::after {
                        opacity: 0.2;
                    }
                }

                h6 {
                    color: $blue;
                }
            }

            &.video {

                .thumbnail-image {

                    &::before {
                        content: "";
                        width: 68px;
                        height: 68px;
                        background-image: url(../../images/icons/common/ei_play.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 1;
                    }

                    &::after {
                        opacity: 0.2;
                    }
                }
            }
        }
    }
}

.ux-counter {
    margin-top: 108px;

    .counter-row {
        grid-template-columns: repeat(3, 1fr);
        margin-top: 58px;

        @media (max-width: $mobile) {
            grid-template-columns: repeat(2, 1fr);
            gap: 50px 20px;
        }
    }
}

.ux-counter-hr {
    height: 112px;
    max-width: 992px;
    margin: 48px auto 0;
    position: relative;

    @media (max-width: $laptop) {
        max-width: 700px;
    }

    @media (max-width: $tablet) {
        max-width: 500px;
    }

    @media (max-width: $mobile) {
        max-width: 65%;
    }

    &::after {
        content: "";
        width: 1px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $blue;
        position: absolute;
        transition: all linear 1s;
    }
}

.our-story-section {
    margin-top: 56px;

    .thumbnail-image {
        img {
            width: 100%;
            display: block;
        }
    }

    .story-content {
        display: flex;
        justify-content: space-between;
        max-width: 996px;
        margin: 52px auto 0;

        @media (max-width: $mobile) {
            flex-direction: column;
        }

        .content-box {
            max-width: 487px;
            width: 100%;
            margin-top: 23px;

            @media (max-width: $mobile) {
                margin-top: 0px;
            }

            @media (max-width: $tablet) {
                max-width: 400px;
            }

            h5 {
                margin-bottom: 58px;
            }

            p {
                margin-bottom: 16px;
            }
        }
    }
}

.sticky-arrow-list {
    position: sticky;
    top: 190px;
    // z-index: -1;
    z-index: 0;

    @media (max-width: $laptop) {
        z-index: 1;

    }

    &.sticky-remove {
        position: relative;
    }

    @media (max-width: $tablet) {
        display: none;
    }

    .dark-blue-list-arrow {
        max-width: 186px;
        margin-left: 4px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        @media (max-width: $laptop) {
            background-color: $white;
            padding: 10px;
        }

        a {
            color: $darkBlue;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            transition: all 0.3s;
            position: relative;
            padding-left: 35px;
            display: flex;
            align-items: center;


            &::before {
                content: "";
                position: absolute;
                background-image: url(../../images/icons/common/btn_arrow.svg);
                aspect-ratio: 1 / 1;
                width: 24px;
                left: 0;
                transition: all 0.3s;
            }

            &:hover {
                color: $blue;

                &::before {
                    left: 5px;
                }
            }
        }
    }
}

.our-upcoming-session {
    background-color: rgba(13, 111, 138, 0.95);
    padding: 21px 4px;
    max-width: 1060px;
    width: 100%;
    position: fixed;
    right: 0;
    bottom: 30px;
    z-index: 99;
    margin-left: auto;

    @media (max-width: $laptop) {
        max-width: 95%;

    }

    @media (max-width: $mobile) {
        max-width: 80%;
        display: none;
    }

    .session-row {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 72px;

        @media (max-width: $laptop) {
            gap: 30px;
        }

        @media (max-width: $mobile) {
            grid-template-columns: 1fr;
            gap: 15px;
        }

        .title {
            font-size: 14px;
            line-height: 20px;
            font-family: $akkuratProRegular;
            color: $white;
            margin-bottom: 0;
            max-width: 200px;
        }
    }

    .session-date {
        display: flex;
        gap: 15px;
        align-items: center;
        transition: $transition;

        img {
            aspect-ratio: 1 / 1;
            max-width: 50px;
            width: 100%;
        }

        &:hover {

            h6,
            .addres {
                color: $non-photo-blue;
            }

            .session-year {
                &::before {
                    right: -45px;

                    @media (max-width: $laptop) {
                        right: -35px;

                    }
                }
            }
        }
    }

    .session-year {
        position: relative;


        &::before {
            content: "";
            position: absolute;
            background-image: url(../../images/icons/common/btn_arrow.svg);
            aspect-ratio: 1 / 1;
            width: 24px;
            right: -40px;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s;
            filter: brightness(0) invert(1);

            @media (max-width: $laptop) {
                right: -30px;

            }

        }

        h6 {
            margin-bottom: 6px;
            color: $white;

            @media (max-width: $laptop) {
                font-size: 18px;
            }
        }

        .addres {
            font-size: 14px;
            line-height: 20px;
            color: $white;
            font-family: 'Akkurat-Pro-Regular';
        }
    }
}

/*** Set visible bz scrolling getting issue ****/
.scrolling-pin-2 {
    .client-logo-section {
        .logo-col {
            &.wow {
                visibility: visible !important;
            }
        }
    }

}