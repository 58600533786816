@import 'pages/variable';
@import 'pages/typography';
@import 'pages/icon-list';
@import 'pages/home';
@import 'pages/services';
@import 'pages/aboutus';
@import 'pages/work';
@import 'pages/glossary';
@import 'pages/our-perspective';
@import 'pages/tools-ideas';
@import 'pages/casestudies';
@import 'pages/ux-training';
@import 'pages/contact';
@import 'pages/how-we-work';
@import 'pages/blog-sign-up';

// Site Header
.site-header {
    background-color: $offWhite;
    padding: 20px 0px;
    position: sticky;
    width: 100%;
    top: 0;
    transition: all 0.5s;
    z-index: 30;

    @media (max-width: $tablet) {
        padding: 20px 0 15px;
    }

    @media (max-width: $mobile) {
        // top padding reduced to 16px and meight of logo increased to 24px to
        // allow the hamburger menu to better align.
        padding: 16px 0 16px;
    }

    .header-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.5s;

        .site-logo {
            position: relative;
            z-index: 2;

            @media (max-width: $mobile) {
                display: flex;
                align-items: center;
            }

            .logo-link {
                display: inline-block;
                text-decoration: none;
                position: relative;
                top: 3px;

                @media (max-width: $mobile) {
                    top: unset;
                    display: flex;
                    align-items: center;
                }

                .logo-image {
                    max-width: 144px;
                    width: 100%;
                    transition: all 0.5s;

                    @media (max-width: $tablet) {
                        max-width: 84px;
                    }

                    @media (max-width: $mobile) {
                        max-height: 24px;
                    }

                    &.mobile-logo {
                        position: absolute;
                        left: 0;
                        opacity: 0;
                    }
                }
            }
        }

        .site-navbar {
            display: flex;
            align-items: center;
            gap: 25px;
            transition: all 0.5s;
            min-height: 126px;

            @media (max-width: $laptop) {
                min-height: 100px;
            }

            @media (max-width: $tablet) {
                position: fixed;
                left: -100%;
                top: 63px;
                padding: 20px;
                width: 100%;
                height: 100%;
                background-color: $offWhite;
                transition: $transition;
                display: block;
                opacity: 0;
            }

            &.navbar-active {
                left: 0;
                opacity: 1;
            }

            .navbar-nav {
                list-style: none;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 10px 49px;
                transition: all 0.5s;

                @media (max-width: $tablet) {
                    display: block;
                }

                .nav-item {

                    .nav-link {
                        color: $darkBlue;
                        text-decoration: none;
                        text-transform: uppercase;
                        font-size: 14px;
                        letter-spacing: 0.84px;
                        font-family: $akkuratProRegular;
                        border-bottom: 1px solid transparent;
                        padding-bottom: 2px;

                        &:hover {
                            border-color: $darkBlue;
                        }

                        @media (max-width: $tablet) {
                            font-size: 18px;
                            line-height: 34px;
                            color: $black;
                            margin-bottom: 20px;
                            display: inline-block;
                        }

                        &.nav-link-active {
                            color: $black;
                        }
                    }
                }
            }

            .select-menu {
                align-self: flex-start;

                .select-hidden {
                    display: none;
                    visibility: hidden;
                    padding-right: 10px;
                }

                .desktop-menu-icon {
                    display: none;
                    align-items: center;
                    cursor: pointer;
                    gap: 10px;
                    transition: all 0.5s;

                    .icon {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;

                        .icon-bar {
                            background-color: $white;
                            width: 25px;
                            height: 2px;
                            display: inline-block;
                        }
                    }

                    .menu-text {
                        color: $white;
                        font-family: $conduitITCStdMedium;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 30px;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .mobile-menu-icon {
            transition: all 0.5s;

            .icon {
                position: relative;
                height: 16px;
                width: 25px;

                .icon-bar {
                    position: absolute;
                    background-color: $blue;
                    width: 25px;
                    height: 2px;
                    display: inline-block;
                    transition: $transition;

                    &:nth-child(2) {
                        top: 50%;
                    }

                    &:nth-child(3) {
                        top: 100%;
                    }
                }
            }

            @media (min-width: 992px) {
                display: none;
            }

            &.menu-active {

                .icon {

                    .icon-bar {
                        &:nth-child(3) {
                            transform: rotate(45deg);
                            top: 50%;
                        }

                        &:nth-child(2) {
                            opacity: 0;
                        }

                        &:nth-child(1) {
                            transform: rotate(-45deg);
                            top: 50%;
                        }
                    }
                }
            }
        }
    }

    &.header-minimize:not(.header-menu-open) {
        background-color: $blue;

        .header-inner {

            .site-logo {

                .logo-link {

                    .logo-image {
                        max-width: 84px;

                        &.mobile-logo {
                            opacity: 1;
                        }
                    }
                }
            }

            .site-navbar {
                min-height: 50px;

                .navbar-nav {
                    display: none;
                }

                .select-menu {
                    align-self: center;

                    .rev-select {
                        display: none;
                    }

                    .desktop-menu-icon {
                        display: flex;
                    }
                }
            }
        }
    }
}

// Site Footer
.footer-shape-bottom {
    margin: 85px 0 -1px;

    @media (max-width: $mobile) {
        margin: #{clamp_scale(55px, $mobileReferenceWidth, $mobile)} 0 -1px;
    }

    img {
        width: 100%;
        height: auto;
        display: flex;
    }
}

.help-section {
    background-color: $lightBlue;
    padding: 72px 0px 145px;

    @media (max-width: $tablet) {
        padding: 70px 0px 100px;
    }

    @media (max-width: $mobile) {
        padding:
            #{clamp_scale(72px, $mobileReferenceWidth, $mobile)}
            0
            #{clamp_scale(76px, $mobileReferenceWidth, $mobile)};
    }

    .inner-sec {
        display: flex;
        align-items: end;
        gap: 56px;
        max-width: 794px;
        margin: 0px auto;

        @media (max-width: $tablet) {
            flex-direction: column;
            align-items: center;
        }

        @media (max-width: $mobile) {
            gap: clamp_scale(35px, $mobileReferenceWidth, $mobile);
        }

        h2 {
            margin-bottom: 0px;
            max-width: 554px;
            width: 100%;

            @media (max-width: $mobile) {
                max-width: unset;
            }

            span {
                color: $pureBlack;
                font-family: $canelaLight;
                font-size: 55px;
                font-weight: 300;
                line-height: 68px;
                letter-spacing: 2.2px;
                text-transform: uppercase;
                display: block;

                @media (max-width: $tablet) {
                    font-size: 40px;
                    line-height: 50px;
                }

                @media (max-width: $mobile) {
                    margin-left: clamp_scale(42px, $mobileReferenceWidth, $mobile);
                    font-size: clamp_scale(30px, $mobileReferenceWidth, $mobile);
                    line-height: clamp_scale(36px, $mobileReferenceWidth, $mobile);
                    letter-spacing: 0.04em;
                    text-align: left;
                }

                &:last-child {
                    margin-left: 124px;
                    margin-top: -6px;

                    @media (max-width: $tablet) {
                        margin-top: 0;
                    }

                    @media (max-width: $mobile) {
                        margin-top: clamp_scale(2px, $mobileReferenceWidth, $mobile);
                        margin-right: clamp_scale(35px, $mobileReferenceWidth, $mobile);
                        margin-left: 0;
                        text-align: right;
                    }

                    img {
                        margin-right: 7px;
                        position: relative;
                        top: 7px;

                        @media (max-width: $mobile) {
                            top: 0;
                            width: clamp_scale(29px, $mobileReferenceWidth, $mobile);
                            height: clamp_scale(35px, $mobileReferenceWidth, $mobile);
                            margin: 0;
                        }
                    }
                }
            }
        }

        .lets-chat {
            max-width: 160px;
            width: 100%;
            margin-bottom: 3px;

            @media (max-width: $mobile) {
                display: flex;
                flex-flow: row nowrap;
                justify-content: stretch;
                max-width: unset;
                margin-bottom: 0;
                text-align: center;
            }

            a {
                @media (max-width: $mobile) {
                    flex: 1 0 auto;
                }
            }
        }
    }
}

footer.site-footer {
    padding-top: 65px;
    padding-bottom: 44px;

    @media (max-width: $mobile) {
        padding-top: clamp_scale(44px, $mobileReferenceWidth, $mobile);
        padding-bottom: clamp_scale(44px, $mobileReferenceWidth, $mobile);
    }

    .mobile {
        display: none;

        @media (max-width: $mobile) {
            display: block;
        }
    }

    .no-mobile {
        display: block;

        @media (max-width: $mobile) {
            display: none;
        }
    }

    .footer-inner {
        display: flex;
        align-items: end;

        @media (max-width: $mobile) {
            flex-direction: column;
            gap: 0;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .logo-section {

            @media (min-width: 992px) {
                width: 50%;
            }

            .footer-logo {
                margin-bottom: 39px;

                .logo-link {
                    max-width: 133px;
                    display: block;
                    margin-bottom: 9px;

                    @media (max-width: $mobile) {
                        max-width: unset;
                        margin: 0;
                    }

                    img {
                        @media (max-width: $mobile) {
                            aspect-ratio: 25 / 8;
                            width: clamp_scale(100px, $mobileReferenceWidth, $mobile);
                            height: auto;
                        }
                    }
                }

                .logo-caption {
                    font-size: 23px;
                    line-height: 32px;
                    font-family: $akkuratProLight;

                    @media (max-width: $mobile) {
                        font-size: clamp_scale(19px, $mobileReferenceWidth, $mobile);
                        line-height: clamp_scale(24px, $mobileReferenceWidth, $mobile);
                        text-align: left;
                    }
                }
            }

            .county-list {
                display: flex;
                margin-top: 24px;
                gap: 11px;

                @media (max-width: $laptop) {
                    gap: 10px;
                }

                @media (max-width: $tablet) {
                    gap: 5px;
                }

                @media (max-width: $mobile) {
                    display: none;
                }

                span {
                    display: inline-block;
                    font-family: $akkuratProLight;

                    &:not(:last-child) {
                        border-right: 1px solid $blue;
                        padding-right: 11.7px;

                        @media (max-width: $laptop) {
                            padding-right: 10px;
                        }

                        @media (max-width: $tablet) {
                            padding-right: 15px;
                        }
                    }

                    @media (max-width: $laptop) {
                        font-size: 12px;
                    }

                    @media (max-width: $tablet) {
                        font-size: 10px;
                    }
                }
            }
        }

        .logo-section .footer-caption, .footer-caption {
            max-width: 444px;

            @media (max-width: $mobile) {
                max-width: unset;
                margin-top: clamp_scale(33px, $mobileReferenceWidth, $mobile);
            }

            * {
                font-size: 14px;
                line-height: 20px;
                font-family: $akkuratProLight;

                @media (max-width: $mobile) {
                    font-size: clamp_scale(13px, $mobileReferenceWidth, $mobile);
                    line-height: clamp_scale(18px, $mobileReferenceWidth, $mobile);
                }
            }
        }

        .stay-up {
            padding-left: 65px;
            width: 50%;

            @media (max-width: 1439px) {
                padding-left: 30px;
            }

            @media (max-width: $tablet) {
                padding-left: 20px;
            }

            @media (max-width: $mobile) {
                width: 100%;
                padding: 0px;
            }

            .social-media-icon {
                display: flex;
                gap: 24px;

                @media (max-width: $mobile) {
                    justify-content: flex-start;
                    gap: clamp_scale(36px, $mobileReferenceWidth, $mobile);
                    margin-top: clamp_scale(15px, $mobileReferenceWidth, $mobile);
                }

                a {
                    display: inline-block;
                    transition: $transition;

                    img {
                        aspect-ratio: 1/1;
                        max-width: 30px;
                        width: 100%;

                        @media (max-width: $mobile) {
                            display: block;
                            width: clamp-scale(30px, $mobileReferenceWidth, $mobile);
                            max-width: unset;
                            height: clamp_scale(30px, $mobileReferenceWidth, $mobile);
                        }
                    }

                    &:hover {
                        transform: translateY(-3px);

                        @media (max-width: $mobile) {
                            transform: unset;
                        }
                    }
                }
            }

            .quick-links {
                margin-top: 39px;

                @media (max-width: $mobile) {
                    display: flex;
                    flex-flow: column nowrap;
                    gap: clamp_scale(12px, $mobileReferenceWidth, $mobile);
                    margin-top: clamp_scale(47px, $mobileReferenceWidth, $mobile); 
                    text-align: left;
                }

                a {
                    text-decoration: none;
                    color: $darkBlue;
                    font-family: $akkuratProRegular;
                    padding: 0px 14px;
                    letter-spacing: 0.81px;

                    &:first-child {
                        padding-left: 0px;
                    }

                    &:last-child {
                        padding-right: 0px;
                        border-left: 1px solid $blue;

                        @media (max-width: $mobile) {
                            border-left: none;
                            @include button_size();
                            margin-top: clamp_scale(12px, $mobileReferenceWidth, $mobile);
                            background-color: $lightBlue;
                            border-color: $buttonBlue;
                            text-align: center;
                        }
                    }

                    &:hover {
                        text-decoration: underline;
                    }

                    @media (max-width: $laptop) {
                        font-size: 12px;
                        letter-spacing: 0.2px;
                        padding: 0px 5px;
                    }

                    @media (max-width: $mobile) {
                        padding: 0;
                        font-size: clamp_scale(13px, $mobileReferenceWidth, $mobile);
                        line-height: clamp_scale(20px, $mobileReferenceWidth, $mobile);
                        letter-spacing: 0.06em;
                    }
                }
            }
        }

        .stay-up .stay-up-caption, .stay-up-caption {
            max-width: 328px;
            margin-bottom: 14px;

            @media (max-width: $mobile) {
                max-width: unset;
                margin: 0;
                text-align: left;
            }

            p {
                font-size: 18px;
                line-height: 24px;
                font-family: $akkuratProLight;

                @media (max-width: $mobile) {
                    font-size: clamp_scale(15px, $mobileReferenceWidth, $mobile);
                    line-height: clamp_scale(20px, $mobileReferenceWidth, $mobile);
                }
            }
        }

    }
}