.ux-glossary-hero-section {
    margin-top: 60px;

    @media (max-width: $mobile) {
        margin-top: 60px;
    }

    @media (min-height: 700px) and (max-height: 900px) {
        // adjust the padding at the bottom under 900px (limited to 700 min)
        // so that the left nav does not overlap the footer.
        padding-bottom: calc(900px - 100vh);
    }

    .container {
        display: flex;
        align-items: flex-start;
        gap: 100px;
    }

    .sticky-letter-list-wrapper {
        position: sticky;
        top: 230px;
        min-width: 80px;
        max-height: calc(100vh - 230px);
        overflow-y: auto;
        scrollbar-width: none;
        transition: $transition;

        &.header-minimize {
            top: 149px;
            max-height: calc(100vh - 149px);
        }

        &::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: $tablet) {
            display: none;
        }
    }

    .sticky-letter-list {
        list-style: none;
        // position: sticky;
        // top: 230px;
        // make room for the absolute positioned active arrow to be within the
        // container
        min-width: 80px;
        // adjust the position of the content so that it is correctly offset
        // from the active arrow
        padding-left: 30px;
        text-align: center;
        transition: $transition;

        // &.header-minimize {
        //     top: 149px;
        // }

        // @media (max-width: $tablet) {
        //     display: none;
        // }

        .list-item {
            margin-bottom: 5px;

            a {
                @include small-title-label();
                color: $blue;
                transition: $transition;
            }

            &.click_active {

                a {
                    font-size: 28px;
                    line-height: 36px;
                    background-color: $lightBlue;
                    border-radius: 100%;
                    --size: 50px;
                    width: var(--size);
                    height: var(--size);
                    display: flex;
                    --flexPosition: center;
                    align-items: var(--flexPosition);
                    justify-content: var(--flexPosition);
                    position: relative;
                    padding-top: 4px;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 50px;
                        height: 20px;
                        left: -30px;
                        background-image: url(../../images/icons/common/rt-arrow.svg);
                        background-repeat: no-repeat;
                        top: 50%;
                        transform: translateY(-50%);
                        transition: $transition;
                    }
                }
            }

            &.disable {

                a {
                    color: $grey;
                    user-select: none;
                    cursor: default;
                }
            }
        }
    }

    .glossary-content {

        .intro-title {

            .title {
                display: flex;
                justify-content: space-between;

                @media (max-width: $tablet) {
                    flex-direction: column;
                    margin-bottom: 60px;
                }

                .custom-search-box {
                    position: relative;

                    &::after {
                        content: '';
                        top: 8px;
                        right: 5px;
                        height: 15px;
                        width: 13px;
                        position: absolute;
                        pointer-events: none;
                        background-size: 100%;
                        background-repeat: no-repeat;
                        rotate: 180deg;
                        background-image: url(../../images/icons/common/select-arrow.svg);
                    }
                }

                input {
                    color: $midBlue;
                    font-size: 20px;
                    min-width: 260px;
                    border: 0;
                    border-bottom: 1px solid $midBlue;
                    font-family: $akkuratProLight;
                    background-color: $white;
                    height: 38px;
                    outline: none;
                    width: 100%;

                    &::-webkit-input-placeholder {
                        color: $midBlue;
                    }
                }
            }

            .sticky-arrow {
                background-color: $white;
                width: 100%;
                top: 210px;
                transition: $transition;
                padding: 20px 0;
                z-index: 4;

                @media (max-width: $tablet) {
                    top: 68px;
                }

                .scroll-sticky-arrow {

                    .tooltip {
                        transition: $transition;
                        position: relative;
                        background-color: $black;
                        display: inline-block;
                        color: $white;
                        padding: 5px 10px;
                        border-radius: 5px;
                        font-size: 12px;
                        margin-left: 15px;
                        opacity: 0;

                        &::before {
                            content: '';
                            border-right: 7px solid $black;
                            border-top: 7px solid transparent;
                            border-bottom: 7px solid transparent;
                            position: absolute;
                            left: -7px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    &:hover {
                        .tooltip {
                            opacity: 1;
                        }
                    }

                }

                &.active {
                    position: fixed;
                }

                &.arrow-minimize {
                    top: 134px;

                    @media (max-width: $tablet) {
                        top: 68px;
                    }
                }

                &.sticky-arrow-hide {
                    opacity: 0;
                }
            }
        }

        .content-main {
            margin-top: 60px;

            .content {
                margin-bottom: 40px;

                @media (max-width: $mobile) {
                    margin-bottom: 100px;
                }

                .alphabet {
                    color: $blue;
                    border-top: 1px solid $blue;
                    display: inline-block;
                }

                .inner {
                    display: grid;
                    grid-template-columns: 300px auto;
                    gap: 40px;

                    * {
                        color: $black;
                        transition: $transition;
                    }

                    &:not(:last-child) {
                        margin-bottom: 40px;
                    }

                    @media (max-width: $tablet) {
                        grid-template-columns: 1fr;
                        gap: 20px;
                    }

                    &:hover {

                        * {
                            color: $blue;
                        }
                    }

                    &:is(:focus, :focus-within, :visited, :target, :focus-visible, :hover, :active) {
                        -webkit-tap-highlight-color: transparent;
                    }

                    .title {
                        font-family: $akkuratProRegular;
                        text-align: right;

                        @media (max-width: $tablet) {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}

.service-glossary {
    padding: 68px 0 6px;
}

.service-glossary-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: $tablet) {
        flex-direction: column;
    }
}

.service-glossary-back {
    width: 100%;
    max-width: 48px;
    margin-top: 2px;

    @media (max-width: $tablet) {
        margin-bottom: 30px;
    }

    a {
        width: 48px;
        height: 48px;
        display: block;
        line-height: 58px;
        text-align: center;
        border-radius: 100%;
        background-color: $bubbles;

        svg {
            height: 20px;
            width: 20px;
            transform: rotate(180deg);
        }

        &:hover {
            background-color: $blue;

            svg {
                stroke: $white;
            }
        }
    }
}

.service-glossary-content {
    width: 100%;
    max-width: 706px;

    @media (max-width: $laptop) {
        padding-right: 50px;
    }

    @media (max-width: $tablet) {
        max-width: 100%;
        padding: 0;
    }

    h3 {
        position: relative;

        &:after {
            left: 0;
            width: 45px;
            content: '';
            height: 2px;
            bottom: -12px;
            display: block;
            position: absolute;
            background-color: $BatteryChargedBlue;
        }
    }

    .icon-link-section {
        margin-top: 54px;
        margin-bottom: 67px;

        @media (max-width: $tablet) {
            margin: 80px 0;
        }

        h6 {

            @media (min-width: 992px) {
                margin-bottom: 84px;
            }
        }

        .inner-row {
            max-width: 598px;

            @media (min-width: 992px) {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        .container {
            padding: 0px;
        }
    }
}

.service-glossary-content-in {
    max-width: 600px;
    margin: 29px 0 0;

    @media (max-width: $tablet) {
        max-width: 100%;
    }

    h4 {
        margin-bottom: 26px;
    }

    p {
        margin: 0 0 16px;
        line-height: 26px;
    }
}

.service-explore {
    margin: 53px 0 65px;

    @media (max-width: $mobile) {
        margin-bottom: 0;
    }

    h6 {
        margin: 0 0 83px;
    }

    ul {
        list-style-type: none;

        li {
            min-width: 199px;
            padding-left: 23px;
            display: inline-block;
            border-left: 1px solid $non-photo-blue;

            @media (max-width: $laptop) {
                min-width: 150px;
            }

            @media (max-width: $mobile) {
                min-width: 49%;
                margin-bottom: 60px;
            }

            &:nth-child(odd) {

                @media (max-width: $mobile) {
                    border: none;
                    padding: 0px;
                }
            }

            a {
                color: $black;

                img {
                    display: block;
                    margin: 0 0 25px 20px;
                }

                span {
                    display: flex;
                    color: $black;
                    font-size: 23px;
                    max-width: 150px;
                    line-height: 139.13%;
                    font-family: $akkuratProLight;

                    svg {
                        margin: 8px 9px 0 0;
                    }
                }
            }
        }

        li:first-child {
            border-left: 0;
            padding-left: 0;
        }
    }
}

.service-glossary-sidebar {
    width: 100%;
    max-width: 337px;

    @media (max-width: $tablet) {
        max-width: 50%;
    }

    @media (max-width: $mobile) {
        max-width: 100%;
    }

    .service-glossary-terms {
        padding: 30px;
        background-color: $bubbles;
        margin-top: 51px;

        h6 {
            color: $black;
            margin-bottom: 24px;
        }

        ul {
            list-style-type: none;

            li {
                margin: 12px 0 0;

                a {
                    font-size: 14px;
                    color: $darkBlue;
                    line-height: 142.857%;
                    font-family: $akkuratProRegular;

                    &:hover {
                        color: $pureBlack;
                    }
                }
            }
        }
    }

    .service-glossary-resources {
        margin-left: -2px;

        h6 {
            margin: 83px 0px 59px;
            font-family: $conduitITCStdMedium;
        }

        .glossary-resource-list {
            margin: 0 0 39px;

            h5 {
                position: relative;
                margin-bottom: 25px;

                &:after {
                    left: 0;
                    width: 27px;
                    height: 4px;
                    content: '';
                    bottom: -6px;
                    position: absolute;
                    background-color: $quicksilver;
                }
            }

            &:nth-child(2) {

                h5 {
                    &:after {
                        background-color: $blue;
                    }
                }
            }

            ul {
                list-style-type: none;

                li {
                    margin-bottom: 8px;
                    padding-left: 32px;

                    a {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

#autocomplete-list {
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    background-color: $offWhite;
    max-width: 260px;
    width: 100%;
    margin-top: 5px;

    @media (max-width: $tablet) {
        max-width: 100%;
    }
}

#autocomplete-list div {
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 1px solid $americansilver;
    font-size: 14px;
    line-height: 34px;
    font-family: $akkuratProLight;
}

.mobile-select-box {
    margin: 40px 0;

    @media (min-width: 992px) {
        display: none;
    }

    .rev-select {
        width: 100%;

        .select-styled {
            position: relative;
            background-color: $white;
            height: 38px;
            color: $blue;
            font-size: 20px;
            min-width: 260px;
            font-weight: 100;
            border-bottom: 1px solid $blue;
            font-family: $akkuratProLight;
            display: flex;
            align-items: center;
            padding-left: 0;

            &::after {
                border-color: transparent transparent $blue $blue;
                --size: 8px;
            }

            &.active {

                &::after {
                    border-color: $blue $blue transparent transparent;
                }
            }
        }

        .select-options {
            height: 220px;
            overflow: scroll;
            top: 38px;

            li {
                padding: 10px 0;
                cursor: pointer;
                border-bottom: 1px solid $americansilver;
                font-size: 14px;
                line-height: 34px;
                font-family: $akkuratProLight;
                background-color: $offWhite;
                color: $black;
            }
        }
    }
}