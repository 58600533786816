@use 'typography';
@use 'variable' as var;

.news-blog-section {
    margin-top: 38px;

    .inner-row {
        background-color: var.$lightBlue;
        padding: 35px 76px 35px 66px;
        display: flex;
        justify-content: stretch;
        align-items: center;
        gap: 40px;

        @media (max-width: var.$laptop) {
            justify-content: space-between;
            gap: unset;
            padding: 40px 30px;
        }

        @media (max-width: var.$tablet) {
            flex-direction: column;
            gap: 40px;
        }

        img {
            width: 98px;
        }

        .content {
            margin-left: 13px;
            margin-top: 3px;

            @media (max-width: var.$tablet) {
                margin: 0;
                text-align: center;
            }

            h4 {
                margin-bottom: 0;
                // commented styles that should be inherited
                // font-family: "Cambria W01 Italic";
                // font-style: italic;
                // font-size: 27px;
                // letter-spacing: 0.7px;
            }

            p {
                font-family: var.$akkuratProRegular;
                font-size: 19px;
                position: relative;
                top: 4px;
            }
        }

        .news-letter {
            display: flex;
            gap: 15px;
            align-items: center;
            min-width: 390px;
            // used to right align this block
            margin-left: auto;

            @media (max-width: var.$tablet) {
              // block needs to be centered (default)
              margin-left: unset;
            }

            @media (max-width: var.$mobile) {
                flex-direction: column;
                min-width: 310px;
            }

            .btn-secondary {
                min-width: 129px;

                @media (max-width: var.$mobile) {
                    min-width: unset;
                }
            }

            input[type=email] {
                @include var.contact-form-input;
                background: var.$white;
                outline: none;
                // remove bottom margin from mixin so alignment is ok.
                margin-bottom: 0;
                // limit width so button is on a single line
                // border: 0px;
                // height: 35px;
                // padding: 0 32px 0 16px;
                // font-size: 15px;
                // letter-spacing: 0.7px;
            }

            .btn-warning {
                padding: 8px 28px;

                .button-text {
                    font-size: 14px;
                }
            }
        }
    }

    .blog-cta {
        text-align: center;
        margin-top: 38px;
    }
}
