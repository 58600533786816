.contact-hero-section {
  margin-top: 60px;
  .contact-us {
    display: grid;
    // grid-template-columns: 30% 70%;
    // @media (max-width: $contact_us_screen) {
    grid-template-columns: 1fr;
    // }    
  }
  .contact-left{
    // @media (max-width: $contact_us_screen) {
    margin-bottom: 60px;
    // }
  }
  .contact-detail {
    // @media (max-width: $contact_us_screen) {
    display: grid;
    grid-template-columns: 444px 43%;
    justify-content: space-between;
    // }
    @media (max-width: $tablet) {
      display: grid;
      grid-template-columns: 100%;
      justify-content: space-between;
    }
    .title{
      @media (max-width: $tablet) {
      max-width: 444px;
    }
    }
    h3 {
      font-size: 55px;
      text-transform: capitalize;
      font-family: $canelaLight;
      @media (max-width: $tablet) {
            font-size: 45px;
            line-height: 55px;
      }
      @media (max-width: $mobile) {
            font-size: 34px;
            line-height: 44px;
      }

    }
    .mail {
      color: $blue;
      &:hover {
        color: #077497;
      }
    }
  }
  .select-box-option {
    .prompt-title {
      color: $grey;
      font-family: $akkuratProLight;
      // margin: 120px 0 20px;
      // @media (max-width: $contact_us_screen) {
      margin: 90px 0 20px;     
      // }
      @media (max-width: $tablet) {
        margin: 50px 0 30px;     
      } 
    }
    .toggle-menu {
      margin-left: 0;
      width: 100%;     
    }
    .toggle-menu-in {
      position: relative;
      border-bottom: 1px solid $blue;
      margin-top: 5px;
      color: $blue;
      cursor: pointer;
      display: block;
      span {
        padding-right: 0;
        border: none;
        @media (max-width: $mobile) {
          display: inline-block;
        }
        &:first-child{
          color: $black;
        }
        &::after {
          display: none;
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        background-image: url(../../images/casestudies/downArrowBlue.svg);
        background-repeat: no-repeat;
        background-size: 15px;
        background-position: center;
      }
      &.open {
        span {
          &:first-child {
            color: $grey;
          }
        }
        &::after {
          transform: rotate(180deg);
        }
      }
      .option{
        padding-right: 14px;
      }
    }
    ul {
      width: 100%;
      top: auto;
      li{
        &:last-child{
          padding-bottom: 15px;
        }
      }
    }
  }
}
.our-offices-sec {
  .book-expert {
    display: flex;
    background: $lightBlue;
    padding-top: 60px;
    @media (max-width: $tablet) {
          flex-wrap: wrap;
    }
  }
  .book-time-expert {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: $tablet) {
      width: 100%;      
    }
    h3 {
      // Commented styles inherited from base h3
      // font-family: $conduitITCStdBold;
      // font-size: 35px;
      // font-weight: 500;
      // line-height: 40px;
      // letter-spacing: 0.02em;
      // color: rgb(60, 60, 60);

      @media (max-width: $mobile) {
        text-align: center;
      }

    }
    img {
      max-width: 100%;
    }
  }
  .book-time-right {
    padding: 0px 10px 50px 20px;
    width: 40%;
    @media (max-width: $tablet) {
      width: 100%;
      text-align: center;
      padding-top: 20px;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
      color: rgb(0, 0, 0);
      font-family: $akkuratProLight;
    }
    a {
      margin-top: 30px;
    }
  }
}
.contact-right {
  padding-left: 25px;
  @media (max-width: $mobile) {
    padding-left: 0px;
  }
  .sub-title {    
    @include h3;
    color: $grey;
    margin: 60px 0 30px;        
    &::after {
      // width: 26px;
      content: "";
    width: 42px;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -18px;
    background-color: $blue;
    }
  }
  .location-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media (max-width: $tablet) {
      grid-template-columns: 1fr;     
    }
  }
  .location-wrap {
    position: relative;
    height: 296px;
    overflow: hidden;
    // @media (max-width: $contact_us_screen) {
    max-width: 450px;
    // }
    @media (max-width: $tablet) {
      margin: auto;
      max-width: 412px;
    }
    @media (max-width: $mobile) {
      margin-left: -20px;      
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        180deg,
        rgba(7, 116, 151, 0.75) 7.03%,
        rgba(7, 116, 151, 0.76) 10.01%,
        rgba(7, 116, 151, 0.95) 96.76%
      );
      opacity: 0.5;
    }
    img{
      width: 100%;
      height: 100%;
      // @media (max-width: $contact_us_screen) {
      max-width: 450px;
      // }
    }
  }
  .location-block {
    position: relative;
    padding-right: 20px;
    @media (max-width: $mobile) {
      padding-right: 0px;
    }
  }
  .contact-location {
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 230px;
    min-height: 238px;
    padding: 25px;
    color: #fff;
    background-color: rgba(60, 60, 60, 0.95);
    @media (max-width: $mobile) {
      right: -20px;
    }
    .location-title {
      margin-top: 28px;
      font-family: $conduitITCStdMedium;
      font-size: 22px;
      line-height: 30px;
      text-transform: uppercase;
      position: relative;
      padding-right: 18px;
      padding-bottom: 8px;
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        right: 0;
        width: 10px;
        height: 14px;
        background-image: url(../../images/contact/iconLocationPin.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
      &:hover {
        &::after {
          filter: brightness(0) invert(1);
        }
      }
    }
    a {
      color: $white;
      font-family: $akkuratProRegular;
      font-size: 14px;
      line-height: 22px;
    }
    .label {
      color: #a1a3a6;
      font-family: $conduitITCStdRegular;
      font-size: 12px;
      line-height: 24px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
.discuss-intro{
  padding: 39px 35px 10px;
  position: relative;
  margin-bottom: 2px;
  position: relative;
  display: none;
  &.active{
    display: block;
    .form-details{
      display: block;       
    }
  }
  &::before{
    content: '';
    position: absolute;
    display: block;
    top: 28px;
    left: 3px;
    width: 25px;
    height: 23px;
    background-image: url(../../images/contact/iconSpeechBubble.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .form-details{
    color: $deepsaffron;
    font-size: 20px;
    line-height: 24px;  
    display: none;
    font-family: $akkuratProLightItalic;    
  }
  .form-text-open{
    display: block;
  }
}
.contact-us-form{
  display: none;
    transition: max-height 0.6s;    
    .discuss-intro{
      display: block;
    }
  .label-details{    
    opacity: 0;
    transition: opacity 250ms, padding-top 250ms;
    color: #3c3c3c;
    font-size: 15px;
    line-height: 28px;    
    text-transform: uppercase;
    display: block;
    font-family: $akkuratProRegular;
    &.active{
      opacity: 1;
    }
  }
  .input-details,.textarea-details{
    @include contact-form-input;
  }
  #email-error-message,#name-error-message{
    color: #f7902f;
  }
  .input-submit{
    margin-top: 6px;
    float: right;
    outline: none;
    @extend .btn-primary;
    // Commented styles inherited from base button
    // background-color: #f7902f;
    // padding: 0 30px;
    // color: $white;
    // line-height: 35px;
    // border: none;
    // &:focus{
    //       background-color: $grey;   
    // }
  }
  #contact-thank-text{
    display: none;
    &.active{
      display: block;
    }
  }
}
.see-what-we-do{
  a{
  display: none;

  // "removed" per instruction
  // @media (max-width: $tablet) {
  //   display: block;
  //   position: relative;    
  //   width: 274px;
  //   height: 90px;
  //   margin: 46px auto 0;
  //   background-image: url(../../images/contact/graphicWhatDoMobile@2x);
  //   background-size: 278px 90px;    
  //   background-position: center;
  //   background-color: #e16f27;
  //   font-size: 18px;
  //   line-height: 90px;
  //   text-align: center;
  //   color: $white;
  //   text-transform: uppercase;
  // }
}
}