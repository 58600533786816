@use 'variable' as var;

a {
    transition: var.$transition;
    text-decoration: none;
    color: var.$black;

    &:hover{
        color: var.$blue;
    }
}

@mixin h1{
    font-size: 69px;
    font-weight: 300;
    color: var.$pureBlack;
    margin-bottom: 30px;
    line-height: 115.942%; /* 80px at 69px font size */
    letter-spacing: 2.07px; /* 3% at 69px font size */
    font-family: var.$canelaLight;
    
    @media (max-width: var.$laptop) {
        font-size: 55px;
        line-height: 65px;
    }
    
    @media (max-width: var.$tablet) {
        font-size: 40px;
        line-height: 55px;
    }
    
    @media (max-width: var.$mobile) {
        font-size: 9.091vw;
        line-height: 117%;
        letter-spacing: 0.03em;
    }
}

h1 {
    @include h1;
}

@mixin h2{
    font-size: 55px;
    line-height: 116.364%;
    margin-bottom: 30px;
    font-weight: 300;
    font-family: var.$canelaLight;
    color: var.$pureBlack;
    letter-spacing: normal;
    text-transform: unset;

    &.uppercase {
        line-height: 123.636%;
        letter-spacing: 2.2px;
        text-transform: uppercase;

        @media (max-width: var.$mobile) {
            line-height: var.clamp_scale(36px, var.$mobileReferenceWidth, var.$mobile);
            letter-spacing: unset;
        }
    }

    @media (max-width: var.$tablet) {
        font-size: 45px;
        line-height: 55px;
    }

    @media (max-width: var.$mobile) {
        margin-bottom: var.clamp_scale(18px, var.$mobileReferenceWidth, var.$mobile);
        font-size: var.clamp_scale(30px, var.$mobileReferenceWidth, var.$mobile);
        line-height: var.clamp_scale(36px, var.$mobileReferenceWidth, var.$mobile);
    }
}

h2, .typography-h2, p.typography-h2 {
    @include h2;
}

@mixin h3 {
    color: unset;
    font-size: 35px;
    line-height: 114.286%;
    font-family: var.$conduitITCStdMedium;
    font-weight: 500;
    margin-bottom: 30px;
    letter-spacing: 0.7px;
    text-transform: uppercase;

    @media (max-width: var.$mobile) {
        font-size: var.clamp_scale(27px, var.$mobileReferenceWidth, var.$mobile);
        line-height: var.clamp_scale(28px, var.$mobileReferenceWidth, var.$mobile);
        letter-spacing: 0.02em;
    }
}

h3, .typography-h3, p.typography-h3 {
    @include h3;

    // This effectively overrides the default h3 styles to instead pick up
    // the values set on the parent element. Allowing the h3 tag to be present
    // for its semantic value, only.
    &.semantic-only {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
        font-weight: inherit;
        margin-bottom: inherit;
        letter-spacing: inherit;
        text-transform: inherit;

        @media (max-width: var.$mobile) {
            font-size: inherit;
            line-height: inherit;
            letter-spacing: inherit;
        }
    }
}

@mixin h4 {
    color: unset;
    font-size: 28px;
    line-height: 128.571%;
    font-family: unset;
    font-weight: 500;
    margin-bottom: 30px;
    letter-spacing: -0.28px;
    text-transform: unset;
}

h4, .typography-h4, p.typography-h4 {
    @include h4;
}

@mixin h5 {
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 139.13%;

    @media (max-width: var.$mobile) {
        font-size: var.clamp_scale(19px, var.$mobileReferenceWidth, var.$mobile);
        line-height: var.clamp_scale(22px, var.$mobileReferenceWidth, var.$mobile);
    }
}

h5, .typography-h5, p.typography-h5 {
    @include h5;
}

@mixin h6 {
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 104.348%;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    font-family: var.$conduitITCStdMedium;

    @media (max-width: var.$mobile) {
        margin-bottom: var.clamp_scale(16px, var.$mobileReferenceWidth, var.$mobile);
        font-size: var.clamp_scale(19px, var.$mobileReferenceWidth, var.$mobile);
        line-height: var.clamp_scale(20px, var.$mobileReferenceWidth, var.$mobile);
        letter-spacing: 0.02em;
    }
}

h6, .typography-h6, p.typography-h6 {
    @include h6;
}

// span {
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 20px;

//     &.regular{
//         font-weight: 500;
//         font-family: var.$akkuratProRegular;
//     }
// }

.quote {
  position: relative;
  font-family: var.$canelaLight;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.03125em;
  margin-bottom: 30px;

  @media (max-width: var.$mobile) {
      margin-bottom: var.clamp_scale(20px, var.$mobileReferenceWidth, var.$mobile);
      font-size: var.clamp_scale(19px, var.$mobileReferenceWidth, var.$mobile);
      line-height: var.clamp_scale(26px, var.$mobileReferenceWidth, var.$mobile);
      letter-spacing: 0.02em;
  }
}

@mixin floatedQuoteMark {
  content: '\201c';
  position: absolute;
  left: -0.5em;
  color: var.$nonphotoblue;
  font-size: 60px;
  font-family: var.$canelaLight;
}

.quote.floatedQuote {
  margin-bottom: 0;

  &::before {
    @include floatedQuoteMark;
  }

  &.noAfter {
    &::after {
      content: none;
    }
  }
}

.quote-rule {
  background: var.$americansilver;
  height: 1px;
  width: 86px;
  margin: 17px 0 15px;
}

.quote-attribution {
  display: flex;
  flex-flow: row nowrap;
}

.attribution .attribution-title {
  @extend body;
  text-transform: none;
}

br {
    @media (max-width: var.$tablet) {
        display: none;
    }
}