@use './_variable';

.work-hero-section {
  margin: 40px 0 0;
  position: relative;

  .container {
    position: relative;

    @media (max-width: $mobile) {
      margin: 0 -20px;
    }
  }

  .work-banner-main {
    position: relative;
    overflow: hidden;
  }

  .work-details {
    padding: 180px 0;

    @media (max-width: $tablet) {
      padding: 100px 0;
    }

    @media (max-width: $mobile) {
      padding: 80px 0 150px;
    }
  }

  .inner-row {
    display: grid;
    grid-template-columns: auto auto;
    gap: 145px;

    .work-title {
      position: relative;
      z-index: 1;

      h1 {
        // Commented out styles that are inherited from base h1
        // font-family: $conduitITCStdMedium;
        // font-weight: 400;
        // letter-spacing: 0.015em;
        // font-size: 103px;
        // line-height: 85px;
        // color: #555;
        text-transform: uppercase;
        width: 50%;
        margin-bottom: 80px;

        @media (max-width: $tablet) {
          // Inherited from base h1
          // font-size: 54px;
          // line-height: 54px;
          width: 100%;
          margin-bottom: 50px;
        }

        @media (max-width: $mobile) {
          // Inherited from base h1
          // font-size: 40px;
          // line-height: 0.825;
          margin-bottom: 30px;
        }
      }

      h6 {
        width: 500px;
        margin-left: 30%;
        font-family: $akkuratProLight;
        text-transform: inherit;
        line-height: 30px;

        @media (max-width: $tablet) {
          font-size: 18px;
          line-height: 28px;
          width: 370px;
          margin-left: 0%;
        }

        @media (max-width: $mobile) {
          width: 100%;
        }
      }

      span {
        margin: 12px 0 28px;
      }
    }
  }
}

.clients-hero-section {
  margin: 50px 0 100px;

  .container {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 100px;

    @media (max-width: $mobile) {
      justify-content: center;
    }
  }

  .work-side-nav {
    @media (max-width: $mobile) {
      display: none;
    }
  }

  .clients-content {
    .inner-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      background-color: $lightBlue;
      padding: 70px 50px;
      gap: 100px 40px;

      @media (max-width: 1440px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (max-width: $laptop) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: $tablet) {
        grid-template-columns: repeat(1, 1fr);
      }

      @media (max-width: $mobile) {
        padding: 0;
        gap: 0;
        background-color: transparent;
      }

      .col {
        @media (max-width: $mobile) {
          border-top: 1px solid $nonphotoblue;

          &:last-child {
            border-bottom: 1px solid $nonphotoblue;
          }
        }

        h6 {
          color: $midBlue;

          @media (max-width: $mobile) {
            margin-bottom: 0px;
            padding: 20px 0;
            color: $black;

            &::after {
              width: 15px;
              height: 2px;
              left: auto;
              right: 0;
              top: 48%;
              transform: translateX(-43%);
              background: $blue;
            }

            &::before {
              content: "";
              width: 2px;
              top: 50%;
              height: 15px;
              right: 13px;
              position: absolute;
              background: $blue;
              transform: translateY(-50%);
              transition: $transition;
            }
          }

          &.active {
            color: $blue;

            &::before {
              transform: translateY(-50%) rotate(90deg);
            }
          }
        }

        ul {
          list-style: none;

          @media (min-width: 768px) {
            display: block !important;
            height: 100% !important;
            overflow: visible !important;
          }

          @media (max-width: $mobile) {
            padding-bottom: 30px;
            display: none;
          }

          li,
          a {
            font-size: 14px;
            line-height: 18px;
            color: $black;
            font-family: $akkuratProRegular;
          }

          li {
            margin-top: 12px;
          }

          a {
            display: inline-block;
            color: $midBlue;

            &:hover {
              color: $blue;
            }
          }
        }
      }
    }
  }
}

.work-side-nav {
  position: sticky;
  top: 280px;
  max-width: 158px;
  z-index: 1;

  @media (max-width: $tablet) {
    top: 118px;
  }

  @media (max-width: $mobile) {
    order: 2;
    max-width: 100%;
    margin-top: 0;
  }

  .icon-list {
    list-style: none;

    li {
      margin-bottom: 30px;

      &.active {
        a {
          color: $darkBlue !important;

          // &::after {
          //   opacity: 0;
          // }

          &::before {
            opacity: 1;
          }
        }
      }

      @media (max-width: $mobile) {
        margin-bottom: 20px;
      }

      a {
        @extend .btn-default-arrow;
        // prevent arrows being gapped from text due to block
        display: inline !important;
        // alter style per request
        font-family: $akkuratProRegular !important;
        font-weight: 400 !important;
        color: $black !important;

        &:hover {
          color: $darkBlue !important;
        }

        // Commented out styles that are extended from .btn-default-arrow
        // font-size: 14px;
        // line-height: 18px;
        // font-family: $conduitITCStdMedium;
        // letter-spacing: 0.05em;
        // text-transform: uppercase;
        // color: $black;
        // position: relative;
        // padding-left: 30px;
        // display: inline-block;

        // @media (max-width: $mobile) {
          // padding-left: 15px;
          // style is extended from .caption-title-regular
          // font-family: $akkuratProLight;
        // }

        &:hover {
        //   color: $blue;

        //   &::after {
        //     opacity: 0;
        //   }

          &::before {
            opacity: 1;
          }
        }

        // &::after,
        &::before {
          content: "";
          position: absolute;
        //   background-image: url(../../images/icons/common/arrow-thik.svg);
          // width: 20px;
          // height: 17px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          // left: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: $transition;

          // @media (max-width: $mobile) {
          //   width: 10px;
          //   height: 14px;
          // }
        // }

        // &::before {
          left: -30px;
          opacity: 0;
          width: 25px;
          height: 25px;

          @media (max-width: $mobile) {
            left: -15px;
            width: 10px;
            height: 14px;
          }
        }
      }



      &.healthcare {
        a {
          &::before {
            background-image: url(../../images/work/iconIndustryHealth.svg);
          }
        }
      }

      &.government {
        a {
          &::before {
            background-image: url(../../images/work/iconIndustryGovt.svg);
          }
        }
      }

      &.telecom {
        a {
          &::before {
            background-image: url(../../images/work/iconIndustryTelecom.svg);
          }
        }
      }

      &.finance {
        a {
          &::before {
            background-image: url(../../images/work/iconIndustryFinance.svg);
          }
        }
      }

      &.energy {
        a {
          &::before {
            background-image: url(../../images/work/iconIndustryEnergy.svg);
          }
        }
      }

      &.associations {
        a {
          &::before {
            background-image: url(../../images/work/iconIndustryAssocs.svg);
          }
        }
      }

      &.real-estate {
        a {
          &::before {
            background-image: url(../../images/work/iconIndustryRealEstate.svg);
          }
        }
      }
    }
  }

  hr {
    display: block;
    width: 42px;
    height: 1px;
    margin: 30px 0;
    background-color: #1ab5d5;
    border: none;
  }

  .static-list {
    @media (max-width: $mobile) {
      display: none;
    }

    li {
      @extend h6;
      display: block;
      box-sizing: border-box;
      margin: 0 0 20px 0;
      position: relative;
      color: #3c3c3c;
      // Comented out styles that are extended from base h6
      // font-family: $conduitITCStdBold;
      // font-size: 20px;
      // font-weight: 400;
      // line-height: 21px;
      // letter-spacing: 0.05em;
      // text-transform: uppercase;
    }
  }

  .mobile-text {
    display: none;

    @media (max-width: $mobile) {
      display: block;

      .years {
        color: #e2e2e2;
        font-family: $conduitITCStdBold;
        text-transform: uppercase;
        font-size: 38px;
        line-height: 38px;
        display: block;
      }

      .learn-text {
        font-size: 26px;
        line-height: 30px;
        color: #555;
        font-family: $akkuratProLight;
        font-weight: 400;
        margin: 25px 0;
        display: inline-block;
      }
    }
  }

  @media screen and (max-height: 900px) {
    top: 220px;
  
    .static-list li, .icon-list li {
        margin-bottom: 20px;
    }
  }
}

.sticky-work-section {
  .work-container {
    padding-top: 50px;

    @media (max-width: $tablet) {
      padding-top: 0px;
    }
  }

  .sticky-box {
    background-color: $white;
    width: 710px;
    position: relative; // removed "sticky" setting
    // top: 150px;
    padding: 30px 0 30px 150px;
    transition: opacity 0.5s ease-in-out;
    margin-bottom: 80px;

    @media (min-width: 1921px) {
      margin-bottom: 150px;
    }

    @media (max-width: $tablet) {
      width: 100%;
      padding: 80px 0 120px 90px;
      height: auto;
    }

    @media (max-width: $mobile) {
      width: 100%;
      padding: 80px 0 120px 60px;
      height: auto;
    }

    &.bg-1 {
      background-image: url(../../images/work/sticky-bg-01.svg);
      background-repeat: no-repeat;
      background-position-x: 0;
      background-position-y: 76px;
      background-size: 550px 548px;

      @media (max-width: $tablet) {
        background-position-y: 110px;
        background-size: 410px 408px;
      }

      @media (max-width: $mobile) {
        background-position-y: 83px;
        background-size: contain;
      }
    }

    &.bg-2 {
      background-image: url(../../images/work/sticky-bg-02.svg);
      background-repeat: no-repeat;
      background-position-x: 0;
      background-position-y: 76px;
      background-size: 550px 552px;

      @media (max-width: $tablet) {
        background-position-y: 110px;
        background-size: 410px 411px;
      }

      @media (max-width: $mobile) {
        background-position-y: 83px;
        background-size: contain;
      }
    }
  }

  .content {
    width: 100%;
    border-right: 1px solid transparent;

    h3 {
      width: 400px;
      margin-bottom: 10px;
      font-size: 49px;
      line-height: 51px;

      @media (max-width: $tablet) {
        font-size: 30px;
        line-height: 32px;
        width: 280px;
      }

      @media (max-width: $mobile) {
        width: 200px;
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 22px;
      }
    }

    .content-link {
      font-family: $akkuratProRegular;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: $grey;
      width: 140px;
      float: right;
      margin-bottom: 20px;

      &:hover {
        color: $blue;
      }

      @media (max-width: $tablet) {
        float: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 50px 0 20px;
      }

      @media (max-width: $mobile) {
        margin: 30px 0 20px;
      }

      img {
        display: table;
        width: 64px;
        height: auto;
        margin-top: 15px;

        @media (max-width: $tablet) {
          width: 15px;
          margin: 0 10px 0 0px;
        }
      }

      .ipad-none {
        @media (max-width: $tablet) {
          display: none;
        }
      }

      .ipad-block {
        display: none;

        @media (max-width: $tablet) {
          display: block;
        }
      }
    }

    .image-box {
      .expertise {
        position: absolute;
        top: auto;
        left: 100px;
        bottom: 56px;
        transform-origin: left center;
        transform: rotate(-90deg);
        font-family: $akkuratProRegular;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.075em;
        text-transform: uppercase;

        @media (max-width: $tablet) {
          left: 60px;
          bottom: 70px;
        }

        @media (max-width: $mobile) {
          left: 40px;
        }

        // span {
        //   font-family: $conduitITCStdBold;
        // }
      }

      img {
        width: 100%;

        @media (max-width: $tablet) {
          width: 70%;
        }

        @media (max-width: $mobile) {
          width: 90%;
        }
      }
    }
  }

  .sticky-top-bg {
    position: absolute;
    left: 0;
    bottom: 110px;
    z-index: -1;

    @media (max-width: $tablet) {
      display: block;
      width: 410px;
    }

    @media (max-width: $mobile) {
      display: block;
      max-width: 100%;
    }

    img {
      width: 550px;

      @media (max-width: $tablet) {
        width: 100%;
      }
    }
  }
}

.work-hero-intro {
  position: relative;
  .inner-row {
    display: grid;
    align-items: flex-start;
    grid-template-columns: auto auto;
    gap: 145px;

    @media (max-width: $laptop) {
      gap: 0px;
      grid-template-columns: 170px auto;
    }

    @media (max-width: $mobile) {
      grid-template-columns: 1fr;
    }
  }

  .work-banner-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 70%;    
    height: 100%;
    max-height: 750px;
    @media (max-width: $laptop) {
      width: 74%;
    }
    @media (max-width: $tablet) {
      max-height: 550px;
    }
    @media (max-width: $mobile) {
      max-height: 500px;
       width: 100%;
      overflow: hidden;
    }

    @media (min-width: 1921px) {     
      width: 60%;      
    }
   

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
      @media (max-width: $mobile) {
        position: absolute;
        width: 134%;
        right: -40%;
      }
    }

    .img-right-bg {
      z-index: -1;
    }
  }
}

.our-work-sec {
  .our-work-inner {
    display: grid;
    grid-template-columns: 3fr 3fr 2fr 1fr;
    gap: 0;
    align-items: flex-end;

    span {
      @extend h2;
      text-transform: uppercase;
      // commented out styles inherited from base h3
      // font-size: 68px;
      // line-height: 0.78;
      // font-family: $conduitITCStdBold;
      // text-transform: uppercase;
      transform: rotate(-90deg) translate(20px, 50px);
      color: #e2e2e2;
      align-self: center;

      // @media (max-width: 1199px) and (min-width: 992px) {
      //   font-size: 40px;
      // }
    }

    img {
      width: 100%;
    }
  }

  .our-work-detail {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 150px;

    @media (max-width: $laptop) {
      margin-bottom: 100px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #077497;
      color: $white;
      padding: 40px;
      text-transform: uppercase;

      &:hover {
        background-color: $midBlue;

        img {
          filter: brightness(0) invert(1);
        }
      }
    }

    span {
      &:first-child {
        @extend h6;
        max-width: 150px;
        margin-bottom: 0;
        // commmented out styles inherited from base h6
        // font-size: 28px;
        // line-height: 32px;
        // font-family: $conduitITCStdMedium;
      }

      img {
        width: 40px;
      }
    }

    .case-study-img {
      position: relative;

      figure {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100% - 2%);
          opacity: 0.19;
          background-color: #5ecbe1;
        }
      }

      img {
        width: 100%;
      }

      .hr {
        height: 650px;
        position: absolute;
        display: block;
        left: 50%;
        width: 1px;
        z-index: 0;
        margin-top: -2%;
        background-color: #cdcfd0;

        // @media (max-width: $laptop) {
        //   height: 720px;
        // }
      }
    }

    .case-study-rt-img {
      position: relative;
      width: 100%;
      max-width: 270px;

      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 2%);
        opacity: 0.19;
        background-color: #cdcfd0;
      }
    }
  }

  .projects-count {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: 40px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 130px;

    @media (max-width: $mobile) {
      margin-bottom: 50px;
    }

    .num-text {
      text-align: center;
    }

    .count-num {
      display: block;
      font-size: 120px;
      line-height: 63px;
      color: #0098b9;
      font-family: $conduitITCStdBold;
      font-weight: 400;
      margin-bottom: 30px;
    }
  }

  .projects-years {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .count-text {
      max-width: 200px;
      display: block;
      margin: auto;
    }
  }

  .count-text {
    @extend h2;
    text-transform: uppercase;
    // commented out styles inherited from base h3
    // font-size: 68px;
    // line-height: 53px;
    // font-family: $conduitITCStdBold;
    // letter-spacing: 0.025em;
    color: #e2e2e2;
  }
}

.tablet-our-work-sec {
  @media (min-width: 992px) {
    display: none;
  }

  @media (max-width: $tablet) {
    .our-work-inner {
      grid-template-columns: 3fr 4fr 2fr;

      span {
        font-size: 24px;
        text-align: end;
        transform: rotate(-90deg) translate(27px, 70px);
      }

      .our-work-figure {
        position: relative;
        margin-bottom: -8px;
        z-index: -1;

        @media (max-width: $mobile) {
          left: -42px;
        }

        &::after {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.19;
          background-color: #5ecbe1;
        }
      }
    }

    .our-work-detail {
      grid-template-columns: auto;
      margin-left: 33.3%;
      margin-bottom: 0;

      a {
        padding: 10px;

        span {
          &:first-child {
            font-size: 15px;
            line-height: 22px;
            max-width: 80px;
          }

          img {
            width: 15px;
          }
        }
      }

      .case-study-rt-img {
        max-width: 120px;
        margin-left: auto;

        img {
          max-width: 120px;
        }
      }

      div {
        border-left: 1px solid #cdcfd0;
      }
    }

    .projects-count {
      grid-template-columns: auto;
      margin-left: 26%;
      padding: 0;

      .num-text {
        text-align: left;
      }

      .count-num {
        font-size: 67px;
        line-height: 57px;
        margin-bottom: 0;
      }

      .count-text {
        font-size: 38px;
        line-height: 38px;

        @media (max-width: $mobile) {
          // display: none;
        }
      }
    }
  }

  @media (max-width: $mobile) {
    .our-work-detail {
      margin-left: 15%;
      margin-bottom: 50px;
    }

    .projects-count {
      margin-left: 0;
    }
  }

  .our-work-inner {
    @media (max-width: $mobile) {
      grid-template-columns: 1fr 3fr 1fr;

      span {
        white-space: nowrap;
        transform: rotate(-90deg) translate(40px, -10px);
      }
    }
  }
}

.icon-list-content-section {
  .container {
    &.government-adjust {
      @media (min-width: $tablet + 2) {
        padding-left: 30px;

        .inner-row {
          max-width: 100%;
        }
      }

      @media (min-width: $laptop + 2) {
        // 358px width of left nav + padding region
        // minus width of left image and padding of this region
        // minus overall padding adjustment
        padding-left: 138px;
      }
    }
  }
}

.desk-our-work-sec {
  @media (max-width: $tablet) {
    display: none;
  }

  + .tablet-our-work-sec {
    .our-work-inner {
      span {
        transform: rotate(-90deg) translate(-12px, -30px);

        @media (max-width: $mobile) {
          transform: rotate(-90deg) translate(-5px, -40px);
        }
      }

      .our-work-figure {
        left: -50%;
        transform: translateX(15%);
      }
    }
  }
}

.work-sub-hero {
  background-image: linear-gradient(180deg, #f4f8f9 0, #fff 99.44%, #fff 100%);
  padding-top: 50px;
  position: relative;

  @media (max-width: $tablet) {
    overflow: hidden;
  }

  @media (max-width: $mobile) {
    background-image: linear-gradient(180deg, #f4f8f9 0, #fff 99.44%, #fff 100%);
  }

  &::after {
    content: "";
    background-image: url(../../images/work/bkgHurdle.svg);
    width: 80%;
    aspect-ratio: 1655 / 446;
    position: absolute;
    right: 0;
    top: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: $tablet) {
      right: 30%;
      transform: translateX(50%);
      width: 250%;
    }

    @media (max-width: $mobile) {
      width: 400%;
    }
  }

  .inner-row {
    display: flex;
    align-items: flex-start;
    gap: 150px;

    @media (max-width: $laptop) {
      gap: 50px;
    }

    .work-side-nav {
      @media (max-width: $mobile) {
        display: none;
      }
    }

    .hero-content {
      max-width: 900px;
      width: 100%;
      padding-top: 120px;
      position: relative;
      z-index: 1;

      .content {
        max-width: 465px;
        text-align: center;

        h3 {
          text-align: start;

          @media (max-width: $tablet) {
            margin-bottom: 100px;
          }

          @media (min-width: $mobile) {
            font-size: 49px;
            line-height: 52px;
          }
        }

        .arrow-link {
          position: relative;
          display: inline-block;

          .hover-bg {
            position: absolute;
            left: 0;
            opacity: 0;
            transition: $transition;
          }

          .item-icon {
            position: absolute;
            top: 23%;
            left: 29%;
            transition: $transition;
          }

          &:hover {
            .hover-bg {
              opacity: 1;
            }

            .item-icon {
              filter: brightness(20);
            }
          }
        }

        h5 {
          margin-top: 184px;
          text-align: start;

          @media (max-width: $tablet) {
            margin-top: 100px;
          }
        }
      }

      .the-challenge {
        margin-top: 120px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        .inner-content {
          max-width: 600px;

          &.no-sidebar {
            max-width: 630px;
          }

          h4.lead {
            @media (min-width: $mobile) {
              font-size: 36px;
            }
          }
        }

        img {
          max-width: 170px;
          position: relative;
          top: -65px;

          @media (max-width: $tablet) {
            max-width: 100px;
            position: absolute;
            top: -65px;
            right: 0;
          }
        }
      }

      .detail-item {
        margin-top: 150px;
        min-height: 800px;
        @media (max-width: $laptop) {
          min-height: 645px;
        }
        @media (max-width: $mobile) {
          min-height: auto;
        }

        &:not(:first-child){
          @media (max-width: $tablet) {
            .sub-title {
              display: none;
            }
          }
        }

        @media (max-width: $tablet) {
          display: flex;
          flex-direction: column;
        }

        h3 {
          max-width: 300px;
          @media (min-width: 768px) {
            font-size: 49px;
          }

          @media (max-width: $mobile) {
            max-width: 250px;
          }
        }

        h3.number {
          @media (min-width: 992px) {
            display: none;
          }

          margin-bottom: 0;
          color: $lightgrey;
          font-family: $conduitITCStdBold;
        }

        h5 {
          max-width: 500px;

          @media (max-width: $tablet) {
            order: 1;
            max-width: 220px;
            font-size: 15px;
            margin: 40px auto 0;
          }
        }

        .item-image {
          position: relative;
          max-width: 584px;
          padding-top: 90px;

          @media (max-width: $tablet) {
            padding-top: 0px;
          }

          .shape-image {
            position: absolute;
            z-index: -1;
            max-width: 415px;
            right: -90px;
            top: 0;

            @media (max-width: $tablet) {
              right: 0px;
              top: -170px;
              max-width: 350px;
            }

            @media (max-width: $mobile) {
              right: -20px;
              max-width: 250px;
            }
          }

          img:not(.shape-image) {
            width: 100%;

            @media (max-width: $laptop) {
              max-width: 350px;
            }

            @media (max-width: $mobile) {
              max-width: 250px;
            }
          }
        }
      }
    }
  }
}

.quote-text-section {
  margin: 50px 0 150px;

  .item-icon-outer {
    display: none;
    @media (max-width: $tablet) {
      display: block;
    }
    margin-left: 40px;

    hr {
      height: 50px;
      background-color: #cdcfd0;
      width: 1px;
      border: none;
      margin-left: 16px;
    }
  }

  .quote-text {
    background-color: $black;
    max-width: 100%;
    padding: 85px 80px 110px;
    text-align: center;
    position: relative;
    z-index: 1;

    @media (max-width: $tablet) {
      padding: 46px 23px;
    }

    .item-icon {
      @media (max-width: $tablet) {
        display: none;
      }
    }

    p {
      color: $white;
      max-width: 710px;
      margin: 46px auto 30px;
      text-align: left;

      @media (max-width: $tablet) {
        margin-top: 0;
        margin-left: 20px;
      }

      &::before {
        content: '\201c';
      }

      &::after {
        content: '\201d';
      }
    }

    .author {
      color: $white;
      max-width: 710px;
      margin: 0px auto;
      text-align: left;

      .author-name {

        span {
          display: block;
          text-transform: capitalize;
          padding: 0;

          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.work-detail-casestudy {
  margin-bottom: 100px;

  @media (max-width: $tablet) {
    margin-bottom: 60px;
  }

  .hand-picked {

    .modul-box {

      @media (max-width: $tablet) {
        display: none;
      }

      .box-group {
        @media (max-width: $laptop) {
          margin-left: 0 !important;
        }

        .big {
          width: 50%;

          @media (max-width: $laptop) {
            width: calc(50% - 7px);
          }
        }
      }
    }

    .our-work-sec {
      margin-top: -250px;

      .line-hr {
        text-align: left;
        margin-left: 55px;

        .hr {
          height: 236px;
        }
      }

      .projects-count {
        padding-left: 0;

        @media (max-width: $mobile) {
            display: block;
        }
      }

      .btn-primary {
        margin-top: 20px;
        border-color: $green;
        background-color: transparent;

        &:hover {
          background-color: $black;
          border-color: $black;
        }
      }
    }
  }
}

.project-button-grp {
  margin-top: 100px;

  @media (max-width: $mobile) {
    margin-top: 50px;
  }

  .inner-content {
    background-color: $lightBlue;
    padding: 100px 0 140px;
    border-top: 1px solid $blue;

    @media (max-width: $mobile) {
      background-color: transparent;
      padding: 40px 0 0;
    }

    h5 {
      text-align: center;

      @media (max-width: $mobile) {
        text-align: left;
        font-family: $conduitITCStdMedium;
        font-size: 18px;
        max-width: 160px;
        text-transform: uppercase;
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      max-width: 750px;
      margin: 0px auto;

      @media (max-width: $mobile) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

.client-help{
  display: none;

  @media (max-width: $mobile) {
    display: block;
  }

  .inner-row{
    border-top: 1px solid $blue;
    padding-top: 30px;
  }

  h6{
    font-size: 18px;
    max-width: 160px;

    @media (max-width: $mobile) {
      max-width: 100%;
    }
  }

  ul{
    list-style: none;

    li{
      font-size: 14px;
    }
  }
}


.client-logo-section.work-detail{
  
  @media (max-width: $mobile) {
    display: none;
  }
}

.work-blog-post{
  display: none;

  @media (max-width: $mobile) {
    display: block;
  }
}