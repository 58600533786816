.about-us-hero-section {
  position: relative;
  background-size: cover;
  background-position: center;

  .hero-intro {
    position: relative;
    z-index: 1;
    padding: 160px 0px 34px;

    @media (max-width: $mobile) {
      padding: 100px 0px 50px;
    }

    h5 {
      font-size: 53px;
      max-width: 530px;
      color: $white;
      font-family: $canelaLight;

      @media (max-width: $mobile) {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
      }
    }

    p {
      color: $white;
      max-width: 713px;
      margin: 70px auto 30px;
      text-align: center;

      @media (max-width: $mobile) {
        margin: 30px auto 50px;
      }
    }

    svg {
      --size: 20px;
      height: var(--size);
      width: var(--size);
    }

    a {
      span {
        @extend .caption-title-regular;
        color: $white;
        text-transform: uppercase;
      }
    }

    .cta-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }

  &::after {
    content: "";
    background-image: linear-gradient(180deg,
        rgba(7, 116, 151, 0.75) 7.03%,
        rgba(7, 116, 151, 0.76) 10.01%,
        rgba(7, 116, 151, 0.95) 96.76%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.care-about-section {
  
  @media (max-width: $tablet) {
    margin: 100px 0;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 110vh;
    
    @media (max-width: $tablet) {
      display: block;
      position: sticky;
      top: 140px;
      background-color: $white;
      min-height: 500px;
      height: 100%;
      padding: 110px 0 0;
    }

    .title-content {
      max-width: 310px;

           
      @media (min-width: 992px) {
        // padding-top: 40vh;
        padding-top: 250px;
      }

      .inner-title{
        @media (min-width: 992px) {
          position: sticky;
          top:50%;
          transform: translateY(-40%);
          min-height: 365px;          
        }
      }

      @media (max-width: $mobile) {
        max-width: 100%;
        opacity: 1 !important;
      }
    }

    .counter-number {
      opacity: 0;
      transition: opacity 0.3s 0.25s ease-out;
      will-change: opacity;
      @media (min-width: 992px) {
        padding-top: 40vh;
      }

      @media (max-width: $mobile) {
        opacity: 0;
      }

      .inner-counter{
        @media (min-width: 992px) {
          position: sticky;
          top:50%;
          transform: translateY(-40%);
          min-height: 365px;          
        }
      }

      h2.countdown {
        color: $midBlue;
        font-size: 120px;
        line-height: 120px;
        margin-bottom: 20px;
        font-family: $conduitITCStdBold;

        @media (max-width: $mobile) {
          font-size: 80px;
        }
      }

      h3 {
        @extend h2;
        text-transform: uppercase;
        color: $americansilver;
        max-width: 440px;
        // font-family: $akkuratProBold;

        @media (max-width: $mobile) {
          max-width: 100%;
          // font-size: 30px;
          // line-height: 40px;
          margin-bottom: 15px;
        }
      }

      h6, .typography-h6 {
        color: $americansilver;
        text-transform: none;
      }
    }
  }
}

.experience-section {
  margin: 0 0 150px;

  @media (max-width: $tablet) {
    margin: 100px 0px;
  }

  @media (max-width: $mobile) {
    margin: 0px 0px 100px;
  }

  .row {
    display: grid;
    grid-template-columns: 1.5fr 0.8fr;
    align-items: center;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
      gap: 30px;
    }

    .content {
      display: flex;
      align-items: center;

      @media (max-width: $mobile) {
        flex-direction: column;
        gap: 30px;
        align-items: flex-start;
      }

      .inner {
        max-width: 450px;

        @media (max-width: $laptop) {
          max-width: 360px;
        }

        p {
          margin: 35px 0 41px;
        }
      }

      .side-image {
        border-left: 1px solid $nonphotoblue;
        padding: 35px 0px 56px 56px;
        margin-left: 44px;

        @media (max-width: $mobile) {
          margin-left: 0;
          padding: 30px;
          border-top: 1px solid $nonphotoblue;
          border-left: none;
          width: 100%;
          text-align: center;
        }

        img {
          max-width: 137px;
        }

        span {
          display: block;
          font-size: 14px;
          line-height: 18px;
          color: $blue;
          font-family: $akkuratProRegular;
        }
      }
    }

    .work-process {
      .inner {
        max-width: 280px;
        padding: 60px 30px 30px;
        position: relative;
        background-position: center;
        background-size: cover;
        margin-left: auto;

        @media (max-width: $mobile) {
          margin: 0px auto;
        }

        h6,
        .typography-h6,
        span {
          color: $white;
          position: relative;
          margin-bottom: 5px;
        }

        span {
          font-size: 14px;
          display: block;
        }

        .watch-video {
          margin-top: 40px;
        }

        &::before {
          content: "";
          position: absolute;
          background-color: $blue;
          opacity: 0.8;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

.watch-vid-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.85);
  overflow: auto;
  display: none;
  align-items: center;
  justify-content: center;

  .vid-inner {
    max-width: 850px;
    width: calc(100% - 40px);
  }

  .video-iframe {
    width: 100%;
    aspect-ratio: 16/9;
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.4);
  }

  .close-trigger {
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin-left: calc(100% - 10px);

    &::after,
    &::before {
      content: "";
      width: 2px;
      height: 18px;
      background-color: $blue;
      display: inline-block;
      margin-bottom: 10px;
    }

    &::after {
      transform: rotate(45deg);
      margin-right: 8px;
    }

    &::before {
      transform: rotate(-45deg);
      margin-right: -2px;
    }
  }

  &.popup-active {
    display: flex;
  }
}

.our-team-section {
  margin: 150px 0;

  @media (max-width: $tablet) {
    margin: 100px 0px;
  }

  .row {
    display: grid;
    grid-template-columns: 1.1fr 1fr;
    gap: 25px;

    @media (max-width: $laptop) {
      grid-template-columns: 1fr;
    }

    .team-image {
      img {
        width: 100%;
      }
    }

    .content-box {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: $laptop) {
        flex-direction: column;
      }

      @media (max-width: $tablet) {
        flex-direction: row;
        gap: 110px;
      }

      @media (max-width: $mobile) {
        flex-direction: column;
        gap: 30px;
      }

      .join-team {
        max-width: 50%;
        padding: 25px;
        border: 1px solid $buttonBlue;

        @media (max-width: 1439px) {
          padding: 20px;
        }

        @media (max-width: $laptop) {
          max-width: 100%;
        }

        @media (max-width: $laptop) {
          max-width: 50%;
        }

        @media (max-width: $mobile) {
          max-width: 100%;
        }

        .btn-primary {
          margin-top: 30px;
        }
      }

      .simply-put {
        max-width: 50%;
        padding: 25px;
        padding-left: 35px;

        @media (max-width: 1280px) {
          padding: 20px;
          padding-left: 30px;
        }

        @media (max-width: $laptop) {
          max-width: 100%;
          padding: 40px 0;
        }
      }
    }
  }

  .team-content {
    background-color: rgba($color: $blue, $alpha: 0.9);
    padding: 50px 30px;
    max-width: 1000px;
    margin-left: auto;
    margin-top: -125px;
    position: relative;

    @media (max-width: 1366px) {
      margin-top: -110px;
    }

    @media (max-width: $laptop) {
      margin-top: 0px;
    }

    @media (max-width: $tablet) {
      margin-top: 100px;
    }

    @media (max-width: $mobile) {
      margin-top: 60px;
      padding: 20px;
    }

    .inner-row {
      display: grid;
      grid-template-columns: 0.8fr 1.2fr;
      gap: 30px;
      align-items: center;

      @media (max-width: $tablet) {
        grid-template-columns: 1fr;
        gap: 50px;
      }

      .content {
        max-width: 375px;
        width: 100%;

        @media (max-width: $tablet) {
          max-width: 100%;
        }

        h3,
        p {
          color: $white;
        }

        .title-underline {
          &::after {
            background-color: $white;
          }
        }

        .btn-primary {
          margin-top: 47px;

          &:not(:hover) {
            background-color: $white;

            span {
              color: $blue;
            }
          }
        }
      }

      .figure {
        @media (max-width: $mobile) {
          display: none;
        }

        .center {
          text-align: center;
        }

        h6, .typography-h6 {
          color: $white;
        }

        p:not(.typography-h6) {
          color: $white;
          font-family: $akkuratProRegular;
        }

        div {
          margin: 20px 0;
          display: grid;
          grid-template-columns: 0.9fr 3fr 0.9fr;
          align-items: center;
          gap: 20px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.service-icon-section {
  margin: 150px 0;

  @media (max-width: $tablet) {
    margin: 100px 0px;
  }

  .inner-row {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $laptop) {
      grid-template-columns: 1fr;
      gap: 80px;
    }

    .service-col {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media (max-width: $laptop) {
        grid-template-columns: 2fr 1fr;
      }

      @media (max-width: $mobile) {
        display: flex;
        flex-direction: column-reverse;
        gap: 30px;
      }

      p {
        margin: 50px 0px 30px;
      }

      .side-image {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        justify-content: center;
        padding: 28px;
        margin: 0px 30px;
        border-left: 1px solid $non-photo-blue;
        gap: 20px;

        @media (max-width: 1439px) {
          padding: 20px;
        }

        @media (max-width: $mobile) {
          margin: 0px;
          background-color: $lightBlue;
          border-left: none;
          min-height: 200px;
          margin: 0px -20px;
        }

        img {
          width: 80px;
          aspect-ratio: 80/100;
        }
      }
    }
  }
}

.about-sub-hero-section {
  background-color: $lightBlue;
  padding: 60px 0 120px;
  margin-bottom: 200px;

  @media (max-width: $mobile) {
    margin-bottom: 150px;
  }

  .container {
    @media (max-width: $mobile) {
      padding: 0px;
    }
  }

  .inner-row {
    position: relative;
    text-align: center;

    @media (max-width: $tablet) {
      margin: 0px auto;
      max-width: 500px;
    }

    h5 {
      font-size: 35px;

      @media (max-width: $mobile) {
        padding: 0px 20px;
      }
    }

    p {
      margin: 0px auto;
      max-width: 630px;
      width: 100%;

      @media (max-width: $mobile) {
        padding: 0px 20px;
      }
    }

    img {
      margin: 50px auto -200px;
      max-width: 630px;
      width: 100%;
    }
  }
}

.meet-team-section {
  margin: 100px 0;

  .member-content {
    display: flex;
    justify-content: space-between;
    gap: 70px;

    &:not(:last-child) {
      margin-bottom: 100px;
    }

    &:nth-child(odd) {
      @media (min-width: 992px) {
        flex-direction: row-reverse;
      }
    }

    @media (max-width: $tablet) {
      flex-direction: column;
      max-width: 500px;
      margin: 0px auto;
      gap: 50px;
    }

    .profile-image {
      @media (min-width: 992px) {
        max-width: 40%;
      }

      width: 100%;

      img {
        width: 100%;
      }
    }

    .content {
      @media (min-width: 992px) {
        max-width: 60%;
      }

      width: 100%;

      h3 {
        margin-bottom: 0px;
      }

      .designation {
        margin-bottom: 30px;
        font-family: $akkuratProRegular;
        font-size: 14px;
        display: inline-block;
      }

      .social-media-icon {
        display: flex;
        gap: 15px;
        margin-top: 20px;

        img {
          width: 30px;
          height: 30px;
          transition: $transition;

          &:not(:hover) {
            filter: brightness(0);
            opacity: 0.2;
          }
        }
      }
    }
  }
}

.available-positions {
  .title-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    @media (max-width: $mobile) {
      flex-direction: column-reverse;
      gap: 50px;
    }

    .content {
      max-width: 530px;

      p {
        font-style: italic;
      }
    }

    .side-image {
      max-width: 180px;
      width: 100%;
      background-color: rgba(227, 238, 175, 0.18);
      aspect-ratio: 225/225;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        max-width: 70px;
      }
    }
  }

  .ux-architect {
    border-top: 1px solid $nonphotoblue;
    padding-top: 35px;

    h6, .typography-h6 {

      span {
        color: $nonphotoblue;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      gap: 100px;

      @media (max-width: $mobile) {
        flex-direction: column;
        gap: 30px;
      }

      a {
        position: relative;

        .hover-arrow {
          position: absolute;
          left: 0px;
          top: 0px;
          opacity: 0;
          transition: $transition;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.introduce-section {
  margin: 100px 0;

  .inner-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
      max-width: 470px;
    }
  }
}

.icon-list-section {
  .inner-row {
    position: relative;

    .side-link {
      top: 0;
    }

    .icon-content {
      display: flex;
      align-items: center;
      gap: 50px;
      max-width: 900px;
      margin: 0px auto;
      margin-bottom: 100px;

      @media (max-width: $tablet) {
        flex-direction: column;
      }

      img {
        max-width: 250px;
        width: 100%;
      }

      .content {
        border-bottom: 1px solid $nonphotoblue;
        padding-bottom: 30px;

        h6, .typography-h6 {
          color: $black;
        }
      }
    }
  }
}

// Senior UX Architect page
.ux-architect-section {
  padding-top: 73px;

  @media (max-width: $tablet) {
    padding-top: 50px;
  }

  .side-link {
    top: 5px;

    @media (max-width: $laptop) {
      display: none;
    }
  }

  .title {
    h4 {
      color: $grey;
      font-family: $akkuratProLight;
      font-size: 36px;
      margin-bottom: 7px;
    }

    h6, .typography-h6 {
      font-family: $akkuratProRegular;
      font-size: 16px;
      margin-bottom: 22px;
      color: $midBlue;
    }
  }

  .inner-row {
    display: flex;
    justify-content: space-between;
    position: relative;

    @media (max-width: $tablet) {
      flex-direction: column;
    }

    p {
      margin-bottom: 22px;
    }
  }

  .ux-detail {
    max-width: 494px;
    width: 100%;

    h5 {
      margin: 68px 0 20px;
      color: $darkBlue;

      @media (max-width: $tablet) {
        margin: 30px 0 20px;
      }
    }

    ul {
      padding-left: 16px;

      li {
        padding-top: 21px;
        font-size: 18px;
        line-height: 24px;

        &:first-child {
          padding-top: 0;
        }

        &::marker {
          color: $blue;
        }
      }

      & + p {
        margin-top: 64px;
        margin-bottom: 0;

        @media (max-width: $tablet) {
          margin: 30px 0 20px;
        }
      }
    }
  }

  .ux-sidebar {
    max-width: 375px;
    width: 100%;

    h6, .typography-h6 {
      font-size: 28px;
      font-family: $conduitITCStdMedium;
      text-transform: uppercase;
    }

    .ux-darkcolor {
      color: $midBlue;
      border-top: 1px solid $midBlue;
      padding-top: 22px;
    }

    a {
      color: $blue;

      &:hover {
        color: $darkBlue;
      }
    }
  }
}