.how-we-work-intro-section {
    background-color: #2E748C;
    padding: 70px 0px 80px;
    background-size: 331px 165.5px;
    background-position: center;

    @media (max-width: $mobile) {
        padding: 50px 0;
    }

    .intro-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: $mobile) {
            flex-direction: column;
            gap: 20px;
        }

        .description {
            max-width: 530px;
            color: $white;
            font-family: $akkuratProLight;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: normal;
            margin-right: 162px;
            margin-bottom: 0;

            @media (max-width: $tablet) {
                max-width: 50%;
                margin-right: 0px;
            }

            @media (max-width: $mobile) {
                max-width: 100%;
            }
        }

        .title {
            color: $white;
            text-transform: none;
            max-width: 350px;
            margin-bottom: 0px;
            letter-spacing: -0.28px;
        }
    }
}

.how-we-work-l1-hero-section {
    padding: 82px 0px 98px;

    .container {
        position: relative;

        .side-link {
            top: calc(15% + 65px);

            &:hover {
                color: $blue;
            }
        }
    }

    .row {
        &:not(:nth-child(2)) {
            padding-top: 82px;
        }

        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 100px;

        p {
            margin-bottom: 32px;
        }

        img {
            width: 100%;
            max-width: 386px;
          //  margin-left: 10%;
        }

        @media (max-width: $tablet) {
            img {
                width: 100%;
                max-width: 386px;
            }
        }

        @media (max-width: $mobile) {
            &:not(:nth-child(2)) {
                padding-top: clamp_scale(37px, $mobileReferenceWidth, $mobile);
            }

            display: flex;
            align-items: flex-start;
            flex-direction: column-reverse;
            gap: 0;

            &.reverse-column {
                flex-direction: column;
            }

            p {
                margin-bottom: clamp_scale(10px, $mobileReferenceWidth, $mobile);
            }

            img {
                width: clamp_scale(247px, $mobileReferenceWidth, $mobile);
                max-width: unset;
                margin: 0px;
            }

            .arrow-list {
                padding-top: clamp_scale(14px, $mobileReferenceWidth, $mobile);
            }
        }
    }
}