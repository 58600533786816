@use 'variable' as var;

.icon-list-content-section {
    margin: 50px 0 150px;

    @media (max-width: 1439px) {
        margin-top: 0;
    }

    @media (max-width: var.$tablet) {
        margin: 80px 0;
    }

    @media (max-width: var.$mobile) {
        margin: 50px 0;
    }

    .inner-row {
        max-width: 900px;
        margin: 0px auto;

        .item {
            display: grid;
            grid-template-columns: 150px auto 150px;
            gap: 40px;

            @media (max-width: var.$tablet) {
                grid-template-columns: 100px auto 100px;
            }

            @media (max-width: var.$mobile) {
                grid-template-columns: 1fr;
                gap: 30px;
            }

            .item-content {
                margin-top: 80px;

                @media (max-width: var.$tablet) {
                    margin-top: 50px;
                }

                @media (max-width: var.$mobile) {
                    margin-top: 0px;
                }

                a {
                  @include button_text_metrics();
                  color: var.$darkBlue;

                  &:hover {
                    color: var.$blue;
                  }
                }

                h6 {
                    // set to be at the same ratio to the font-size as the h2
                    // font-size is to 30px
                    margin-bottom: 13px;
                }
            }

            img {
                width: 100%;
                max-width: 150px;

                @media (max-width: var.$mobile) {
                    max-width: 100px;
                }
            }

            &:nth-child(odd):not(.l-than-r), &:nth-child(even).l-than-r {
                @media (min-width: 768px) {
                    img {
                        order: 3;
                    }

                    .item-content {
                        order: 2;
                    }
                }

                img {

                    @media (max-width: var.$mobile) {
                        margin-left: auto;
                    }
                }

            }

            // &:nth-child(odd).l-than-r {
            //     @media (min-width: 768px) {
            //         img {
            //             order: ;
            //         }

            //         .item-content {
            //             order: 2;
            //         }
            //     }
            // }

            &:not(:last-child) {
                margin-bottom: 80px;

                @media (max-width: var.$tablet) {
                    margin-bottom: 50px;
                }
            }
        }
    }
}