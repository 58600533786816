@use 'sass:math' as math;

/*@import 'variable';
@import 'typography';*/

.wow{
    visibility: hidden;
}

// UK Home
.home-hero-section {
    padding-top: 92px;
    padding-bottom: 180px;

    @media (max-width: $mobile) {
        padding: 50px 0;
    }

    .hero-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: 1080px;
        margin: 0px auto;

        .page-heading {
            color: $pureBlack;
            margin-bottom: 6px;

            & > h1, & > .creating { /* :not(.header-description) { */
                font-family: $canelaLight;
                display: flex;
                gap: 21px;
                margin-bottom: 24px;

                .inner-matters {
                  margin: 0;
                }

                @media (max-width: $laptop) {
                    img {
                        width: 50px;
                        aspect-ratio: 1/1;
                        object-fit: contain;
                    }
                }

                @media (max-width: $tablet) {
                    img{
                        height: auto;
                    }
                }

                @media (max-width: $mobile) {
                    flex-wrap: wrap;
                    gap: clamp(9px, 2.4vw, 18px);
                    justify-content: flex-start;
                    text-align: left;
                    margin-bottom: 15px;

                    &.human-insights {
                        margin-bottom: clamp(2px, 0.53vw, 4px);
                    }

                    &.forward-thinking {
                        position: relative;
                        display: block;
                        margin-bottom: 0;
                        margin-left: clamp(30px, 8.021vw, 62px);
                        padding-top: clamp(7px, 1.87vw, 14px);
                    }

                    &.experiences {
                        display: grid;
                        grid-template-areas:
                        ". a a a"
                        "c c b .";
                        justify-content: stretch;
                        justify-items: flex-start;
                        grid-template-rows: min-content 1fr;
                        align-items: baseline;
                        grid-template-columns: min-content min-content min-content 1fr;
                        grid-column-gap: clamp(10px, 2.67vw, 20px);
                        grid-row-gap: clamp(5px, 5.03vw, 10px);
                        margin-bottom: 0;

                        .inner-experiences {
                            grid-area: a;
                            justify-self: flex-end;
                        }

                        .inner-change {
                            grid-area: c;
                        }
                    }

                    &.creating {
                        .inner-matters {
                          text-align: right;
                        }
                    }

                    img {
                        object-fit: unset;

                        &.human_face {
                            width: 7.22vw;
                            height: 11.786vw;
                        }

                        &.telescope {
                            position: relative;
                            top: clamp(-7px, -1.87vw, -14px);
                            width: 11.23vw;
                            height: 12.03vw;
                        }
                    }
                }

                .header-description {
                    margin-top: 8px;
                    max-width: 285px;
                    line-height: 32px;
                    text-transform: none;
                    letter-spacing: 0;
                    color: $black;
                    margin-bottom: 0;
                    font-family: $akkuratProLight;

                    @media (max-width: $tablet) {
                        font-size: 18px;
                        line-height: 28px;
                    }

                    @media (max-width: $mobile) {
                        max-width: 100%;
                        padding-right: clamp(88px, 23.98vw, 184px);
                        font-size: clamp(19px, 5.18vw, 40px);
                        line-height: clamp(26px, 7.08vw, 54px);
                        letter-spacing: 0;
                    }
                }

                &:nth-child(1) {
                    margin-left: 42px;

                    @media (max-width: $tablet) {
                        margin-left: 0px;
                    }
                }

                &:nth-child(2) {
                    margin-left: 150px;

                    @media (max-width: $laptop) {
                        margin-left: 185px;
                    }

                    @media (max-width: $tablet) {
                        margin-left: 50px;
                    }

                    @media (max-width: $mobile) {
                        margin-left: clamp(30px, 8.021vw, 62px);
                    }
                }

                &:nth-child(3) {
                    margin-left: 41px;
                    margin-bottom: 25px;

                    img {
                        margin-left: 16px;
                        margin-top: -18px;
                    }

                    @media (max-width: $tablet) {
                        margin-left: 0px;

                        img {
                            margin-left: 0px;
                            margin-top: 0;
                        }
                    }

                    @media (max-width: $mobile) {
                        margin-bottom: 0;

                        img {
                          grid-area: b;
                          justify-self: flex-start;
                          width: 12.032vw;
                          height: 12.032vw;
                        }
                    }
                }

                &:nth-child(4) {
                    margin-left: 244px;

                    @media (max-width: $laptop) {
                        margin-left: 185px;
                    }

                    @media (max-width: $tablet) {
                        margin-left: 50px;
                    }

                    @media (max-width: $mobile) {
                        margin-left: 0;
                        flex-direction: column-reverse;
                    }
                }
            }
        }

        .button-group {
            gap: 24px;
            display: flex;
            margin-left: auto;
            margin-right: 8px;
            justify-content: end;

            @media (max-width: $mobile) {
                // margin-right: auto;
                // margin-top: 30px;
                display: grid;
                grid-auto-flow: row;
                grid-auto-columns: 1fr;
                grid-auto-rows: 1fr;
                grid-template-columns: 1fr 1fr;
                justify-items: stretch;
                align-items: stretch;
                gap: clamp_scale(12px, $mobileReferenceWidth, $mobile);
                width: 100%;
                margin: 0;
            }

            a {
                @media (max-width: $mobile) {
                    text-align: center;
                }

                &.btn-secondary {
                    @media (max-width: $mobile) {
                        padding: clamp_scale(16px, $mobileReferenceWidth, $mobile);
                    }
                }
            }
        }
    }
}

.home-success-section {
    background-color: $offWhite;
    margin-top: 161px;
    margin-bottom: 50px;

    @media (max-width: $mobile) {
        position: relative;
        margin-top: clamp_scale(116px, $mobileReferenceWidth, $mobile);
    }

    .section-top-bg,
    .section-bottom-bg {
        img {
            width: 100%;
            height: auto;
            display: flex;
        }
    }

    .section-top-bg {
        @media (max-width: $mobile) {
            position: absolute;
        }
    }

    .section-bottom-bg{
        @media (max-width: $tablet) {
            margin-top: 60px;
        }

        @media (max-width: $mobile) {
            margin-top: clamp_scale(60px, $mobileReferenceWidth, $mobile);
        }
    }

    .home-success-bg {
        padding-top: 80px;
    }

    .sec-image {
        top: -320px;
        width: 100%;
        display: flex;
        margin-left: auto;
        max-width: 1024px;
        position: relative;
        margin-bottom: -287px;
        height: auto;

        @media (max-width: 1366px) {
            max-width: 70vw;
        }

        @media (max-width: 1200px) {
            max-width: 85vw;
        }

        @media (max-width: $tablet) {
            top: -250px;
            margin-bottom: -200px;
            height: auto;
        }

        @media (max-width: $mobile) {
            position: absolute;
            // chrome (or maybe the spec?) does not like negative numbers in
            // clamp
            top: calc(-1 * clamp_scale(70px, $mobileReferenceWidth, $mobile));
            display: block;
            width: calc(100vw - #{clamp_scale(20px, $mobileReferenceWidth, $mobile)});
            max-width: unset;
            margin: 0;
            margin-left: clamp-scale(20px, $mobileReferenceWidth, $mobile);
        }
    }

    .home-success-content {
        max-width: 725px;
        margin: 0px 170px 80px auto;

        @media (max-width: $laptop) {
            margin: 0px auto;
        }

        @media (max-width: $mobile) {
            padding-top: clamp_scale(131px, $mobileReferenceWidth, $mobile);
        }

        .title {

            h2 {
                letter-spacing: 0;
                color: $pureBlack;
                text-transform: none;

                @media (max-width: $mobile) {
                    width: clamp_scale(200px, $mobileReferenceWidth, $mobile);
                    margin-bottom: 0;
                }
            }

            h5, .typography-h5 {
                margin: 55px 0px 61px;

                @media (max-width: $laptop) {
                    font-size: 20px;
                    margin: 30px 0px 61px;
                }

                @media (max-width: $tablet) {
                    font-size: 16px;
                    line-height: 26px;
                    margin: 30px 0px 61px;
                }

                @media (max-width: $mobile) {
                    margin: #{clamp_scale(29px, $mobileReferenceWidth, $mobile)} 0px #{clamp_scale(45px, $mobileReferenceWidth, $mobile)};
                    // font-size and line-height are spec'd for mobile in
                    // typography, but the media queries above are more specific
                    // and override, so this resets them
                    font-size: clamp_scale(19px, $mobileReferenceWidth, $mobile);
                    line-height: clamp_scale(26px, $mobileReferenceWidth, $mobile);
                }
            }
        }

    }
}

.list-group-default {
    max-width: 594px;

    @media (max-width: $mobile) {
        max-width: unset;
    }

    a {
        display: flex;
        color: $black;
        text-decoration: none;
        background-image: url(../../images/icons/common/arrow.svg);
        background-repeat: no-repeat;
        background-size: 35px;
        background-position: 100% 0%;

        @media (max-width: $mobile) {
            background-size: clamp_scale(19px, $mobileReferenceWidth, $mobile);
        }

        h6, .typography-h6 {
            margin-bottom: 0;
        }

        &:not(:last-child) {
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid $americansilver;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 0%;
                height: 1px;
                background-color: $blue;
                transition: $transition;
            }

            @media (max-width: $mobile) {
                padding-bottom: clamp_scale(15px, $mobileReferenceWidth, $mobile);
                margin-bottom: clamp_scale(16px, $mobileReferenceWidth, $mobile);
            }
        }

        &:last-child {
            @media (max-width: $mobile) {
                position: relative;
                padding-bottom: clamp_scale(15px, $mobileReferenceWidth, $mobile);
                border-bottom: 1px solid $americansilver;

                &:after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 0%;
                    height: 1px;
                    background-color: $blue;
                    transition: $transition;
                }
            }
        }

        &:hover {
            color: $blue;
            transition: $transition;

            &::after {
                width: 100%;
            }
        }

        // @media (max-width: $mobile) {
        //     font-size: 18px;
        //     line-height: 24px;
        // }
    }
}

.client-industry-section {
    margin-top: 49px;

    @media (max-width: $mobile) {
        margin-top: clamp_scale(38px, $mobileReferenceWidth, $mobile);
    }

    .section-title {
        margin-bottom: 75px;

        @media (max-width: $mobile) {
            margin-bottom: clamp_scale(24px, $mobileReferenceWidth, $mobile);
        }
    }

    .slider-main {
        margin-bottom: 0px;

        @media (max-width: 1280px) {
            max-width: 1000px;
            margin: 0px auto;
        }

        @media (max-width: 1200px) {
            max-width: 800px;
        }

        .slider-content {

            .slider-body {

                @media (max-width: 1439px) {
                    padding: 0 10px;
                }

                @media (max-width: $tablet) {
                    max-width: 85%;
                    margin: 0px auto;
                }

                @media (max-width: $mobile) {
                    max-width: 100%;
                    padding: 0px 5px;
                }


                &:hover{

                    h3{
                        color: $blue;
                    }
                }
            }

            @media (min-width: 1440px) {
                &:not(:first-child, .slick-current) {

                    .slider-body {
                        padding-left: 10px;
                    }
                }
            }

            .cover-img {
                overflow: hidden;
                margin-bottom: 18px;
                position: relative;
                display: flex;
                max-width: 589px;

                @media (max-width: $mobile) {
                    margin-bottom: clamp_scale(24px, $mobileReferenceWidth, $mobile);
                    max-width: unset;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: $blue;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: $transition;
                }

                .slider-image {
                    width: 100%;
                    aspect-ratio: 589 / 352;
                    object-fit: cover;

                    @media (max-width: $tablet) {
                        max-width: 100%;
                        height: auto;
                    }
                }

                &:hover {

                    &::after {
                        opacity: 0.2;
                    }
                }
            }
        }

        .slider-body-content {
            max-width: 493px;

            h3 {
                margin-bottom: 13px;
                transition: $transition;
                color: $black;

                @media (max-width: $mobile) {
                    margin-bottom: clamp_scale(12px, $mobileReferenceWidth, $mobile);
                }
            }

            .slider-label {
                color: $blue;
                font-family: $conduitITCStdMedium;
                font-size: 23px;
                line-height: 24px;
                font-weight: 500;
                letter-spacing: 0.46px;
                text-transform: uppercase;

                @media (max-width: $mobile) {
                    font-family: $conduitITCStdMedium;
                    font-size: clamp_scale(23px, $mobileReferenceWidth, $mobile);
                    line-height: clamp_scale(24px, $mobileReferenceWidth, $mobile);
                    letter-spacing: 0.03em;
                    text-transform: uppercase;
                }

                span:first-child {
                    color: $black;
                    font-family: $akkuratProRegular;
                    display: inline-block;

                    @media (max-width: $mobile) {
                        font-size: clamp_scale(13px, $mobileReferenceWidth, $mobile);
                        line-height: clamp_scale(16px, $mobileReferenceWidth, $mobile);
                        letter-spacing: 0.03em;
                    }
                }

                span:last-child {
                    color: $black;
                    font-family: $akkuratProLight;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0;
                    text-transform: none;
                    display: inline-block;

                    @media (max-width: $mobile) {
                        font-size: clamp_scale(15px, $mobileReferenceWidth, $mobile);
                        line-height: clamp_scale(20px, $mobileReferenceWidth, $mobile);
                        letter-spacing: unset;
                        text-transform: none;
                    }
                }
            }

            p {
                margin: 19px 0 20px;
                color: $black;

                @media (max-width: $mobile) {
                    font-size: clamp_scale(15px, $mobileReferenceWidth, $mobile);
                    line-height: clamp_scale(20px, $mobileReferenceWidth, $mobile);
                    margin-top: clamp_scale(13px, $mobileReferenceWidth, $mobile);
                    margin-bottom: clamp_scale(17px, $mobileReferenceWidth, $mobile);
                }
            }

            .slider-category {
                display: flex;
                flex-wrap: wrap;
                gap: 0px 8px;

                @media (max-width: $mobile) {
                    gap: 0px #{clamp_scale(8px, $mobileReferenceWidth, $mobile)};
                }

                a {
                    span {
                        margin-bottom: 5px;
                        color: $darkBlue;
                        display: inline-block;

                        @media (max-width: $mobile) {
                            margin-bottom: clamp_scale(4px, $mobileReferenceWidth, $mobile);
                        }
                        
                        &:hover{
                            color: $blue;
                        }
                    }

                    &:not(:last-child){
                        span {
                            padding-right: 8px;
                            border-right: 1px solid $americansilver;

                            @media (max-width: $mobile) {
                                padding-right: clamp_scale(8px, $mobileReferenceWidth, $mobile);
                            }
                        }
                    }
                }
            }

            .btn-default-arrow {
                margin-top: 29px;

                @media (max-width: $mobile) {
                    margin-top: clamp_scale(24px, $mobileReferenceWidth, $mobile);
                }
            }
        }
    }

    .slick-arrow {
        background-image: url(../../../assets/images/icons/common/slider_arrow.svg);
        background-position: center;
        --iconSize: 42px;
        background-size: var(--iconSize);
        width: var(--iconSize);
        height: var(--iconSize);
        right: calc(-42px - 17px);
        top: 28.7%;

        @media (max-width: $laptop) {
            --iconSize: 32px;
        }

        @media (max-width: $mobile) {
            --iconSize: 42px;
            top: clamp_scale(78px, $mobileReferenceWidth, $mobile);
            right: calc(-1 * clamp_scale(12px, $mobileReferenceWidth, $mobile));
            // can't interpolate the css var value in sass to pass to the
            // function
            width: clamp_scale(42px, $mobileReferenceWidth, $mobile);
            height: clamp_scale(42px, $mobileReferenceWidth, $mobile);
            background-size: clamp_scale(42px, $mobileReferenceWidth, $mobile);
            z-index: 1;
        }

        &::before {
            display: none;
        }

        &.slick-disabled[aria-disabled="true"] {
            display: none !important;
        }

        &.slick-prev {
            left: -59px;
            background-image: url(../../../assets/images/icons/common/slider_arrow_prev.svg);

            @media (max-width: $tablet) {
                left: 0;
            }

            @media (max-width: $mobile) {
                left: calc(-1 * clamp_scale(12px, $mobileReferenceWidth, $mobile));
            }
        }

        &.slick-next {
            right: calc(-42px - 17px);

            @media (max-width: $tablet) {
                right: 0;
            }

            @media (max-width: $mobile) {
                right: calc(-1 * clamp_scale(12px, $mobileReferenceWidth, $mobile));
            }
        }
    }

    .slick-dots {
        bottom: -79px;
        padding-left: 17px;

        @media (max-width: $mobile) {
            bottom: calc(-1 * clamp_scale(49px, $mobileReferenceWidth, $mobile));
            padding-left: 0;
        }

        li {
            margin: 0px;

            @media (max-width: $mobile) {
                width: clamp_scale(20px, $mobileReferenceWidth, $mobile);
                height: clamp_scale(8px, $mobileReferenceWidth, $mobile);

                &:not(:last-child) {
                    padding-right: clamp_scale(12px, $mobileReferenceWidth, $mobile);
                }
            }

            button {
                padding: 0;
                width: 11px;
                height: 11px;
                border-radius: 100%;
                background-color: $oldsilver;

                @media (max-width: $mobile) {
                    width: clamp_scale(8px, $mobileReferenceWidth, $mobile);
                    height: clamp_scale(8px, $mobileReferenceWidth, $mobile);
                }

                &::before {
                    display: none;
                }
            }

            &.slick-active {

                button {
                    background-color: $blue;
                }
            }
        }
    }

}

.case-study-btn-grp {
    display: flex;
    gap: 24px;
    justify-content: end;
    margin: 144px 0 116px;

    @media (max-width: $tablet) {
        justify-content: center;
    }

    @media (max-width: $mobile) {
        justify-content: stretch;
        flex-direction: column;
        flex-wrap: wrap;
        gap: clamp_scale(20px, $mobileReferenceWidth, $mobile);
        margin:
          #{clamp_scale(86px, $mobileReferenceWidth, $mobile)}
          0
          #{clamp_scale(80px, $mobileReferenceWidth, $mobile)};
    }

    a {
        @media (max-width: $mobile) {
            flex: 1 0 0;
            text-align: center;
        }
    }
}

.home-counter-section {
    background-color: $lightBlue;
    padding: 52px 0 78px;
    margin-bottom: 43px;

    @media (max-width: $tablet) {
        padding: 60px 0;
    }

    @media (max-width: $mobile) {
        padding:
          #{clamp_scale(36px, $mobileReferenceWidth, $mobile)}
          0
          #{clamp_scale(49px, $mobileReferenceWidth, $mobile)};
        margin-bottom: clamp_scale(40px, $mobileReferenceWidth, $mobile);
    }

    .counter-row {
        margin-top: 61px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        @media (max-width: $tablet) {
            gap: 20px;
            margin-top: 40px;
        }

        @media (max-width: $mobile) {
            grid-template-columns: repeat(2, 1fr);
            column-gap: clamp_scale(37px, $mobileReferenceWidth, $mobile);
            row-gap: clamp_scale(34px, $mobileReferenceWidth, $mobile);
            margin-top: clamp_scale(41px, $mobileReferenceWidth, $mobile);
        }

        .counter-col {
            h2 {
                margin-bottom: 13px;
                display: flex;
                align-items: self-start;
                gap: 3px;

                @media (max-width: $mobile) {
                    gap: clamp_scale(3px, $mobileReferenceWidth, $mobile);
                    margin-bottom: clamp_scale(7px, $mobileReferenceWidth, $mobile);
                }

                span.count {
                    color: $black;
                    font-family: $canelaLight;
                    font-size: 69px;
                    font-weight: 300;
                    line-height: 80px;
                    letter-spacing: 2.07px;
                    text-transform: uppercase;
                    margin: 0px;

                    @media (max-width: $tablet) {
                        font-size: 50px;
                        line-height: 60px;
                    }

                    @media (max-width: $mobile) {
                        font-size: clamp_scale(34px, $mobileReferenceWidth, $mobile);
                        line-height: clamp_scale(40px, $mobileReferenceWidth, $mobile);
                        letter-spacing: 0.03em;
                    }
                }

                span {
                    color: $black;
                    font-family: $canelaLight;
                    font-size: 55px;
                    font-weight: 300;
                    line-height: 64px;
                    margin-top: 2px;
                    display: inline-block;

                    @media (max-width: $tablet) {
                        font-size: 35px;
                    }

                    @media (max-width: $mobile) {
                        font-size: clamp_scale(30px, $mobileReferenceWidth, $mobile);
                        line-height: clamp_scale(36px, $mobileReferenceWidth, $mobile);
                    }
                }
            }

            p {
                max-width: 200px;

                @media (max-width: $tablet) {
                    font-size: 16px;
                }

                @media (max-width: $mobile) {
                    max-width: 100%;
                    font-size: clamp_scale(15px, $mobileReferenceWidth, $mobile);
                    line-height: clamp_scale(20px, $mobileReferenceWidth, $mobile);
                }
            }
        }
    }
}

.blue-border-counter {
    height: 112px;
    max-width: 796px;
    margin: 0px auto;
    position: relative;

    &::after {
        content: "";
        width: 1px;
        height: 0%;
        left: 0;
        top: 0;
        background-color: $blue;
        position: absolute;
        transition: all linear 1s;

        @media (max-width: $mobile) {
            left: clamp_scale(57px, $mobileReferenceWidth, $mobile);
        }
    }

    &.animate {
        &::after {
            transition-delay: 0.5s;
            height: 100%;
        }
    }

    @media (max-width: $tablet) {
        max-width: 568px;
    }

    @media (max-width: $mobile) {
        max-width: unset;
        margin: 0;
        height: clamp_scale(57px, $mobileReferenceWidth, $mobile);
    }
}

.learn-more-section {
    .inner {
        max-width: 996px;
        margin: 51px auto 149px;

        @media (max-width: $mobile) {
            margin:
                clamp_scale(37px, $mobileReferenceWidth, $mobile)
                0
                clamp_scale(102px, $mobileReferenceWidth, $mobile);
        }

        h2 {
            font-weight: 300;
            color: $pureBlack;
            margin-bottom: 63px;
            letter-spacing: 0px;
            text-transform: none;

            @media (max-width: $tablet) {
                margin-bottom: 40px;
            }

            @media (max-width: $mobile) {
                max-width: clamp(216px, 66%, 454px);
                margin-bottom: clamp_scale(25px, $mobileReferenceWidth, $mobile);
            }
        }

        h5, .typography-h5 {
            max-width: 680px;
            margin-left: 101px;

            @media (max-width: $tablet) {
                font-size: 20px;
                line-height: 30px;
            }

            @media (max-width: $mobile) {
                margin-bottom: clamp_scale(14px, $mobileReferenceWidth, $mobile);
                margin-left: 0;
            }
        }

        .btn-secondary {
            margin-top: 8px;
            margin-left: 102px;

            @media (max-width: $mobile) {
                width: 100%;
                margin-top: clamp_scale(8px, $mobileReferenceWidth, $mobile);
                margin-left: 0;
                text-align: center;
            }
        }
    }
}

.case-study-section {
    display: flex;

    @media (max-width: $mobile) {
        flex-direction: column;
    }

    .side-image {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        width: 50%;

        @media (max-width: $mobile) {
            position: relative;
            // subtract the right padding (20px at 367px reference width)
            width: calc(100% - #{math.div(20px, $mobileReferenceWidth) * 100vw});
            height: clamp_scale(181px, $mobileReferenceWidth, $mobile);
            overflow: hidden;
        }

        img {
            width: 100%;
            max-width: 608px;
            height: auto;
            aspect-ratio: 1210 / 732;

            @media (max-width: $mobile) {
                // vertically center the image in the clip region
                position: absolute;
                top: 50%;
                transform: translate3d(0, -50%, 0);
                display: block;
                max-width: unset;
            }
        }
    }

    .container-inner {
        max-width: 50%;
        width: 100%;

        @media (max-width: $mobile) {
            max-width: 100%;
            padding: 0 clamp_scale(20px, $mobileReferenceWidth, $mobile);
        }

        .contain {
            max-width: 450px;
            width: 100%;
            display: flex;
            margin-left: 10px;

            @media (max-width: $laptop) {
                width: 95%;
            }

            @media (max-width: $mobile) {
                flex-direction: column;
                width: 100%;
                max-width: 100%;
                margin-left: 0;
                padding-top: clamp_scale(29px, $mobileReferenceWidth, $mobile);
            }

            .quote-mark {
                width: 100%;
                max-width: 47px;
                padding-top: 14px;
                padding-left: 4px;
                align-self: flex-start;

                @media (max-width: $tablet) {
                    max-width: 30px;
                }

                @media (max-width: $mobile) {
                    align-self: unset;
                    display: flex;
                    width: clamp_scale(13px, $mobileReferenceWidth, $mobile);
                    height: clamp_scale(11px, $mobileReferenceWidth, $mobile);
                    padding: 0;
                    font-size: 0;
                    line-height: 1;
                }

                svg {
                    display: block;
                    flex: 1 0 auto;
                    width: 100%;
                    height: 100%;
                }
            }

            .quote {

                p:not(.typography-h6) {
                    margin-top: 26px;

                    @media (max-width: $tablet) {
                        margin-top: 15px;
                    }

                    @media (max-width: $mobile) {
                        margin-top: 0;
                        margin-left: clamp_scale(30px, $mobileReferenceWidth, $mobile);
                    }
                }

                hr {
                    background: $americansilver;
                    height: 1px;
                    width: 86px;
                    margin: 29px 0 15px;
                    border: none;

                    @media (max-width: $mobile) {
                        width: clamp_scale(66px, $mobileReferenceWidth, $mobile);
                        margin:
                            #{clamp_scale(18px, $mobileReferenceWidth, $mobile)}
                            0
                            #{clamp_scale(12px, $mobileReferenceWidth, $mobile)}
                            #{clamp_scale(30px, $mobileReferenceWidth, $mobile)};
                    }
                }

                .author {
                    color: $pureBlack;
                    display: flex;
                    gap: 4px;
                    align-items: baseline;
                    @extend body; // match base body style

                    @media (max-width: $mobile) {
                        margin-left: clamp_scale(30px, $mobileReferenceWidth, $mobile);
                        // I believe there are some include calls that are
                        // multiplying the variable css content and causing
                        // cascade issues, for now, nuking these
                        font-size: clamp_scale(15px, $mobileReferenceWidth, $mobile) !important;
                        line-height: clamp_scale(20px, $mobileReferenceWidth, $mobile) !important;
                    }

                    h6, .typography-h6 {
                        margin-bottom: 52px;

                        @media (max-width: $mobile) {
                            margin-bottom: clamp_scale(34px, $mobileReferenceWidth, $mobile);
                        }
                    }
                }

                .btn-default-arrow {
                    @media (max-width: $mobile) {
                        margin-left: clamp_scale(30px, $mobileReferenceWidth, $mobile);
                    }
                }
            }
        }
    }
}

.blue-border-logo {
    height: 270px;
    max-width: 796px;
    margin: 47px auto 47px;
    position: relative;

    &::after {
        content: "";
        width: 1px;
        height: 0%;
        left: 0;
        top: 0;
        background-color: $blue;
        position: absolute;
        transition: all linear 1s;
    }

    &.animate {

        &::after {
            transition-delay: 0.5s;
            height: 100%;
        }
    }

    @media (max-width: $tablet) {
        max-width: 568px;
        height: 170px;
    }

    @media (max-width: $mobile) {
        margin-top: clamp_scale(20px, $mobileReferenceWidth, $mobile);
        margin-bottom: clamp_scale(40px, $mobileReferenceWidth, $mobile);
        margin-left: clamp_scale(57px, $mobileReferenceWidth, $mobile);
        max-width: 100%;
        height: clamp_scale(128px, $mobileReferenceWidth, $mobile);
    }
}

.client-logo-section {
    background-color: $offWhite;
    padding: 68px 0;

    @media (max-width: $mobile) {
        padding:
            clamp_scale(38px, $mobileReferenceWidth, $mobile)
            0
            clamp_scale(40px, $mobileReferenceWidth, $mobile);
    }

    .container {
        max-width: 952px;

        h6, .typography-h6 {
            text-align: center;
            margin-bottom: 85px;

            @media (max-width: $mobile) {
                margin-bottom: clamp_scale(22px, $mobileReferenceWidth, $mobile);
            }
        }

        .logo-row {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            justify-items: center;
            align-items: center;
            gap: 76px 24px;
            
            &:not(:first-of-type) {
                margin-top: 76px;

                @media (max-width: $mobile) {
                    margin-top: clamp_scale(28px, $mobileReferenceWidth, $mobile);
                }
            }
            
            @media (max-width: $mobile) {
                display: grid;
                gap:
                    #{clamp_scale(28px, $mobileReferenceWidth, $mobile)}
                    #{clamp_scale(8px, $mobileReferenceWidth, $mobile)};
                grid-template-columns: repeat(3, 1fr);
                margin-left: clamp_scale(28px, $mobileReferenceWidth, $mobile);
                margin-right: clamp_scale(28px, $mobileReferenceWidth, $mobile);
            }

            img {
                width: 100%;

                @media (max-width: $mobile) {
                    height: auto;
                }
            }

            .logo-col {
                @media (max-width: $mobile) {
                    display: none;
                }

                &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                    @media (max-width: $mobile) {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .logo-cta{
            text-align: center;
            margin-top: 80px;

            @media (max-width: $mobile) {
                display: flex;
                flex-flow: row nowrap;
                justify-content: stretch;
                width: 100%;
                margin-top: clamp_scale(26px, $mobileReferenceWidth, $mobile);
            }

            a {
                @media (max-width: $mobile) {
                    flex: 1 0 auto;
                }
            }
        }
    }
}

.latest-posts + .client-logo-section {
    // combines with padding from latest posts that extends past dangling
    // callout block to yield 116px
    margin-top: 96px;
}


.blue-border-news {
    height: 96px;
    max-width: 796px;
    margin: 40px auto 58px;
    position: relative;

    &::after {
        content: "";
        width: 1px;
        height: 0%;
        left: 0;
        top: 0;
        background-color: $blue;
        position: absolute;
        transition: all linear 1s;
    }

    &.animate {
        &::after {
            transition-delay: 0.5s;
            height: 100%;
        }
    }

    @media (max-width: $tablet) {
        max-width: 568px;
    }

    @media (max-width: $mobile) {
        max-width: 100%;
        height: clamp_scale(56px, $mobileReferenceWidth, $mobile);
        margin-top: clamp_scale(32px, $mobileReferenceWidth, $mobile);
        margin-bottom: clamp_scale(37px, $mobileReferenceWidth, $mobile);
        margin-left: clamp_scale(57px, $mobileReferenceWidth, $mobile);
    }
}

.news-section {

    h2.section-title {
        font-weight: 300;
        color: $pureBlack;
        margin-bottom: 76px;
        letter-spacing: 2.2px;

        @media (max-width: $mobile) {
            margin-bottom: clamp_scale(27px, $mobileReferenceWidth, $mobile);
            letter-spacing: 0.04em;
        }
    }

    .news-row {
        display: flex;
        justify-content: space-between;

        @media (max-width: $laptop) {
            gap: 24px;
        }

        @media (max-width: $tablet) {
            flex-direction: column;
        }

        @media (max-width: $mobile) {
            gap: clamp_scale(24px, $mobileReferenceWidth, $mobile);
        }

        .news-body {
            max-width: 692px;
            width: 100%;

            @media (max-width: $tablet) {
                margin-bottom: 30px;
            }

            @media (max-width: $mobile) {
                max-width: unset;
                margin-bottom: clamp_scale(51px, $mobileReferenceWidth, $mobile);
            }

            a {
                text-decoration: none;
                display: inline-block;
            }

            .cover-img {
                overflow: hidden;
                margin-bottom: 21px;
                position: relative;
                display: flex;

                @media (max-width: $mobile) {
                    height: clamp_scale(162px, $mobileReferenceWidth, $mobile);
                    margin-bottom: clamp_scale(19px, $mobileReferenceWidth, $mobile);
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: $blue;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: $transition;
                }

                img {
                    width: 100%;
                    height: auto;
                    transition: $transition;

                    @media (max-width: $tablet) {
                        height: auto;
                    }

                    @media (max-width: $mobile) {
                        position: absolute;
                        top: 50%;
                        transform: translate3d(0, -50%, 0);
                    }
                }
            }

            &:hover {
                .cover-img {
                    img {
                        transform: scale(1.05);

                        @media (max-width: $mobile) {
                            transform: translate3d(0, -50%, 0) scale(1.05);
                        }
                    }

                    &::after {
                        opacity: 0.2;
                    }
                }
            }

            .category {
                color: $black;
            }

            .post-title {
                color: $black;
                max-width: 615px;
                margin-top: 2px;
                margin-bottom: 13px;
                transition: $transition;

                @media (max-width: $mobile) {
                    max-width: unset;
                    margin-top: clamp_scale(4px, $mobileReferenceWidth, $mobile);
                    margin-bottom: clamp_scale(9px, $mobileReferenceWidth, $mobile);
                }
            }

            .post-author {
                display: flex;
                align-items: center;
                gap: 13px;
                margin-bottom: 25px;

                @media (max-width: $mobile) {
                    margin-bottom: clamp_scale(17px, $mobileReferenceWidth, $mobile);
                    gap: clamp_scale(13px, $mobileReferenceWidth, $mobile);
                }

                img {
                    aspect-ratio: 1/1;
                    max-width: 36px;
                    border-radius: 50%;

                    @media (max-width: $mobile) {
                        width: clamp_scale(36px, $mobileReferenceWidth, $mobile);
                        max-width: unset;
                        height: auto;
                    }
                }

                span {
                    color: $darkBlue;
                }
            }

            .post-summary {
                color: $pureBlack;
                font-family: $akkuratProLight;
                font-size: 18px;
                line-height: 24px;
                max-width: 588px;

                @media (max-width: $mobile) {
                    max-width: unset;
                    font-size: clamp_scale(15px, $mobileReferenceWidth, $mobile);
                    line-height: clamp_scale(20px, $mobileReferenceWidth, $mobile);
                }
            }

            .btn-default-arrow {
                margin-top: 10px;

                @media (max-width: $mobile) {
                    margin-top: clamp_scale(17px, $mobileReferenceWidth, $mobile);
                }
            }


            &:hover{
                .post-title{
                    color: $blue;
                }
            }
        }

        .news-sidebar {
            max-width: 387px;
            width: 100%;

            @media (max-width: $tablet) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                max-width: 100%;
                gap: 30px;
            }

            @media (max-width: $mobile) {
                grid-template-columns: 1fr;
                gap: clamp_scale(35px, $mobileReferenceWidth, $mobile);
            }

            .inner-sec {
                display: flex;
                align-items: start;
                gap: 16px;

                @media (max-width: $tablet) {
                    flex-direction: column;
                }

                @media (max-width: $mobile) {
                    flex-direction: row;
                    flex-wrap: nowrap;
                    gap: clamp_scale(16px, $mobileReferenceWidth, $mobile);
                }

                &:not(:last-child) {
                    margin-bottom: 39px;

                    @media (max-width: $tablet) {
                        margin-bottom: 0px;
                    }
                }

                &:nth-child(2) {
                    margin-bottom: 33px;

                    @media (max-width: $tablet) {
                        margin-bottom: 0px;
                    }
                }

                .side-image {
                    width: 72px;
                    aspect-ratio: 1/1;

                    @media (max-width: $mobile) {
                        width: clamp_scale(72px, $mobileReferenceWidth, $mobile);
                        height: clamp_scale(72px, $mobileReferenceWidth, $mobile);
                    }
                }

                .contain {

                    h6, .typography-h6 {
                        margin-bottom: 7px;
                        margin-top: 12px;

                        @media (max-width: $mobile) {
                            margin-top: clamp_scale(16px, $mobileReferenceWidth, $mobile);
                            margin-bottom: clamp_scale(4px, $mobileReferenceWidth, $mobile);
                        }

                        // special override to handle irregular design
                        &[data-title="Talk"] {
                            @media (max-width: $mobile) {
                                margin-top: clamp_scale(8px, $mobileReferenceWidth, $mobile);
                            }
                        }
                    }

                    hr {
                        background: $americansilver;
                        border: none;
                        height: 1px;
                        width: 299px;

                        @media (max-width: $mobile) {
                            width: 100%;
                        }
                    }

                    p {
                        margin: 18px 0px 17px;

                        @media (max-width: $mobile) {
                            margin:
                                #{clamp_scale(16px, $mobileReferenceWidth, $mobile)}
                                0
                                #{clamp_scale(8px, $mobileReferenceWidth, $mobile)};
                            font-size: clamp_scale(15px, $mobileReferenceWidth, $mobile);
                            line-height: clamp_scale(20px, $mobileReferenceWidth, $mobile);
                        }
                    }
                }
            }

            .view-more-news {
                text-align: center;
                margin-top: 48px;

                @media (max-width: $tablet) {
                    margin-top: auto;
                    text-align: left;
                }

                @media (max-width: $mobile) {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: stretch;
                    margin-top: clamp_scale(8px, $mobileReferenceWidth, $mobile);
                    text-align: center;
                }

                a {
                    @media (max-width: $mobile) {
                        flex: 1 0 auto;
                    }
                }
            }
        }
    }
}