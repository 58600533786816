@use "sass:math" as math;

/* ========== Colors ========== */
$lightBlue: #eff9fb;
$blue: #1ab5d5;
$buttonBlue: #42d3f1;
$darkBlue: #0d6e8a;
$midBlue: #0098b9;
$white: #ffffff;
$offWhite: #f9fdfe;
$black: #3c3c3c;
$pureBlack: #081e27;
$grey: #555555;
$americansilver: #cdcfd0;
$oldsilver: #858585;
$nonphotoblue: #a3e1ee;
$desire: #e83c57;
$non-photo-blue: #a3e1ee;
$deepsaffron: #f7902f;
$green: #bad739;
$purple: #933c91;
$BatteryChargedBlue: #1ab5d5;
$Charleston: #2a2a2a;
$lightgrey: #eee;
$bubbles: #e8f8fb;
$quicksilver: #adac9c;
$lightOrange: #ffe0c1;

/* ========== Colors ========== */

$transition: all 0.3s;

/* ========== Global CSS & Class ========== */

$laptop: 1199px;
$tablet: 991px;
$mobile: 767px;
$small_laptop: 1024px;
$large_screens: 1440px;
$contact_us_screen: 1800px;

/* ========== Fonts ========== */
@font-face {
  font-family: "Akkurat-Pro-Regular";
  src: url("../../fonts/lineto-akkurat-pro-regular.eot"),
    url("../../fonts/lineto-akkurat-pro-regular.woff2") format("woff2"),
    url("../../fonts/lineto-akkurat-pro-regular.woff") format("woff");
  font-display: swap;
}

$akkuratProRegular: "Akkurat-Pro-Regular";

@font-face {
  font-family: "Akkurat-Pro-Light";
  src: url("../../fonts/lineto-akkurat-pro-light.eot"),
    url("../../fonts/lineto-akkurat-pro-light.woff2") format("woff2"),
    url("../../fonts/lineto-akkurat-pro-light.woff") format("woff");
  font-display: swap;
}

$akkuratProLight: "Akkurat-Pro-Light";

@font-face {
  font-family: "Akkurat-Pro-Light-italic";
  src: url("../../fonts/lineto-akkurat-pro-lightitalic.eot"),
    url("../../fonts/lineto-akkurat-pro-lightitalic.woff2") format("woff2"),
    url("../../fonts/lineto-akkurat-pro-lightitalic.woff") format("woff");
  font-display: swap;
}

$akkuratProLightItalic: "Akkurat-Pro-Light-italic";

@font-face {
  font-family: "Akkurat-Pro-Bold";
  src: url("../../fonts/lineto-akkurat-pro-bold.eot"),
    url("../../fonts/lineto-akkurat-pro-bold.woff2") format("woff2"),
    url("../../fonts/lineto-akkurat-pro-bold.woff") format("woff");
  font-display: swap;
}

$akkuratProBold: "Akkurat-Pro-Bold";

@font-face {
  font-family: "Canela-Light";
  src: url("../../fonts/Canela-Light-Web.woff2") format ("woff2"),
    url("../../fonts/Canela-Light-Web.woff") format("woff");
  font-display: swap;
}

$canelaLight: "Canela-Light";

@font-face {
  font-family: "ConduitITCStd-Medium";
  src: url("../../fonts/ConduitITCStd-Medium.woff2") format ("woff2"),
    url("../../fonts/ConduitITCStd-Medium.woff") format("woff");
  font-display: swap;
}

$conduitITCStdMedium: "ConduitITCStd-Medium";

@font-face {
  font-family: "ConduitITCStd-Bold";
  src: url("../../fonts/ConduitITCStd-Bold.woff2") format ("woff2"),
    url("../../fonts/ConduitITCStd-Bold.woff") format("woff");
  font-display: swap;
}

$conduitITCStdBold: "ConduitITCStd-Bold";

@font-face {
  font-family: "ConduitITCStd-Light";
  src: url("../../fonts/ConduitITCStd-Light.woff2") format ("woff2"),
    url("../../fonts/ConduitITCStd-Light.woff") format("woff");
  font-display: swap;
}

$conduitITCStdLight: "ConduitITCStd-Light";

@font-face {
  font-family: "ConduitITCStd-Regular";
  src: url("../../fonts/ConduitITCStd-Regular.woff2") format ("woff2"),
    url("../../fonts/ConduitITCStd-Regular.woff") format("woff");
  font-display: swap;
}

$conduitITCStdRegular: "ConduitITCStd-Regular";

@function strip-unit($value) {
  @if type-of($value) == "number" and not unitless($value) {
    @return math.div($value, $value * 0 + 1);
  }

  @return $value;
}

@function clamp_scale($minValue, $referenceWidth, $maxWidth) {
  @return clamp(
    #{strip-unit($minValue) * 1px},
    #{(math.div($minValue, $referenceWidth) * 100) * 1vw},
    #{math.round(strip-unit(math.div($minValue, $referenceWidth) * $maxWidth)) * 1px}
  );
}

$mobileReferenceWidth: 376px;

/* ========== Global CSS & Class ========== */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
  // scroll-behavior: smooth;
}

body {
  font-family: $akkuratProLight;
  color: $black;
  font-size: 18px;
  font-weight: 500;
  line-height: 144.444%; /* 26px at 18px font size */
}

.h-100 {
  height: 100%;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-transform-none {
  text-transform: none;
}

// Container
.container {
  width: 100%;
  padding: 0px 20px;
  margin: 0px auto;

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
  }

  @media (min-width: 1440px) {
    max-width: 1240px;
  }

  @media (max-width: $mobile) {
    padding: 0 clamp(10px, 5.348vw, 40px);
  }
}

// Custom Select Box CSS
.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.rev-select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: $white;
  width: 85px;
  height: 25px;
}

.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: $black;
  font-size: 11px;
  font-family: $akkuratProBold;
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.select-styled::after {
  content: "";
  --size: 4px;
  width: var(--size);
  height: var(--size);
  border: 2px solid transparent;
  border-color: transparent transparent $darkBlue $darkBlue;
  position: absolute;
  top: 30%;
  right: 10px;
  transform: translateY(-50%);
  rotate: -45deg;
  border-radius: 2px;
}

.select-styled.active::after {
  top: 50%;
  border-color: $darkBlue $darkBlue transparent transparent;
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $blue;
  font-size: 11px;
  font-family: $akkuratProBold;
}

.select-options li {
  margin: 0;
  padding: 5px 0;
  text-indent: 15px;
}

.select-options li:hover {
  color: $white;
  background: $black;
}

.select-options li[rel="hide"] {
  display: none;
}

@mixin button_text_metrics {
  font-family: $akkuratProBold;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  @media (max-width: $mobile) {
    font-size: clamp(13px, 3.54vw, 16px);
    line-height: clamp(16px, 4.36vw, 20px);
    letter-spacing: 0.04em;
  }
}

@mixin button_size {
  padding: 15px 30px;
  transition: $transition;
  display: inline-block;
  text-decoration: none;
  border: 1px solid;
  border-radius: 2px;
  color: $pureBlack;
  font-family: $akkuratProBold;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  @media (max-width: $mobile) {
    padding: clamp_scale(16px, $mobileReferenceWidth, $mobile) clamp_scale(25px, $mobileReferenceWidth, $mobile);
    font-size: clamp(13px, 3.54vw, 27px);
    line-height: clamp(16px, 4.36vw, 33px);
    letter-spacing: 0.04em;
  }

  &:hover {
    background-color: $darkBlue;
    color: $white;
  }
}

.btn-primary {
  @include button_size();
  background-color: $buttonBlue;
  border-color: $buttonBlue;
}

.btn-secondary {
  @include button_size();
  background-color: $lightBlue;
  border-color: $buttonBlue;
}

.btn-warning {
  @include button_size();
  background-color: $deepsaffron;
  border-color: $deepsaffron;
  color: $white;

  &:hover {
    background-color: $black;
    color: $white;
    border-color: $black;
  }
}

.btn-small {
  font-size: 14px;
  padding: 8px 30px;
  font-family: $conduitITCStdMedium;
}

.btn-default-arrow {
  text-decoration: none;
  color: $darkBlue;
  font-family: $akkuratProBold;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  display: inline-block;
  position: relative;

  @media (max-width: $mobile) {
      font-size: clamp_scale(13px, $mobileReferenceWidth, $mobile);
      line-height: clamp_scale(16px, $mobileReferenceWidth, $mobile);
      letter-spacing: 0.04em;
  }

  &::after {
    content: "";
    position: absolute;
    background-image: url(../../images/icons/common/btn_arrow.svg);
    aspect-ratio: 1/1;
    width: 24px;
    right: -27px;
    transition: $transition;

    @media (max-width: $mobile) {
        top: 50%;
        right: calc(-1 * #{clamp_scale(27px, $mobileReferenceWidth, $mobile)});
        width: clamp_scale(24px, $mobileReferenceWidth, $mobile);
        background-size: cover;
        transform: translateY(-50%);
    }
  }

  &:hover {
    &::after {
      right: -40px;

      @media (max-width: $mobile) {
          right: calc(-1 * #{clamp_scale(40px, $mobileReferenceWidth, $mobile)});
      }
    }
  }
}

.btn-border{
  @include button_size();
  text-transform: capitalize;
  border-color: $blue;
  position: relative;
  background-color: transparent;
  z-index: 1;
  color: $blue;
  font-family: $akkuratProLight;
  font-size: 18px;
  line-height: 26px;

  &::before{
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 15px);
    height: calc(100% - 15px);
    background: $white;
    z-index: -1;
  }

  &:hover{
    background-color: $black;
    color: $white;
    border-color: $black;

    &::before{
      opacity: 0;
    }
  }
}

// separated from watch-vide-btn so it can be used independently but avoid
// breaking existing uses of watch-vide-btn
@mixin watch-vide-btn-after {
  &::after {
    content: "";
    position: absolute;
    background-image: url(../../images/icons/common/play.svg);
    --size: 22px;
    width: var(--size);
    height: var(--size);
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    transition: $transition;
  }

  &:hover::after {
    filter: brightness(20);
  }
}

@mixin watch-vide-btn {
  position: relative;
  padding: 0 40px 0 30px;
  color: $blue;
  background-color: $white;
  font-size: 14px;
  line-height: 35px;
  display: inline-block;
  text-transform: uppercase;
  font-family: $conduitITCStdMedium;
  letter-spacing: 0.05em;
  transition: $transition;

  &:hover {
    background-color: $black;
    color: $white;
  }

  @include watch-vide-btn-after;
}

// variation to attach to btn-secondary, etc. to make it look like a watch video
// button
.watch-video {
  position: relative;
  padding-right: 40px;

  &::after {
    content: "";
    position: absolute;
    background-image: url(../../images/icons/common/play.svg);
    --size: 22px;
    width: var(--size);
    height: var(--size);
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    transition: $transition;
  }
}

.watch-vid-btn {
  position: relative;
  padding: 0 40px 0 30px;
  color: $blue;
  background-color: $white;
  font-size: 14px;
  line-height: 35px;
  display: inline-block;
  text-transform: uppercase;
  font-family: $conduitITCStdMedium;
  letter-spacing: 0.05em;
  transition: $transition;

  &::after {
    content: "";
    position: absolute;
    background-image: url(../../images/icons/common/play.svg);
    --size: 22px;
    width: var(--size);
    height: var(--size);
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    transition: $transition;
  }

  &:hover {
    background-color: $black;
    color: $white;

    &::after {
      filter: brightness(20);
    }
  }
}

.visit-vid-btn {
  @extend .btn-secondary;
  position: relative;
  display: inline-block;
  padding-right: 40px;
  transition: $transition;

  &::after {
    content: "";
    position: absolute;
    background-image: url(../../images/icons/common/iconExternalLink.svg);
    top: 50%;
    --size: 22px;
    width: var(--size);
    height: var(--size);
    right: 15px;
    transform: translateY(-50%);
  }
}

.title-underline {
  position: relative;

  &::after {
    content: "";
    width: 42px;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -18px;
    background-color: $blue;
  }
}

.sub-title {
  // Commented out font and color styles, adding extend of caption-title-regular
  // font-size: 14px;
  // line-height: 17px;
  // font-family: $akkuratProBold;
  // color: $blue;
  text-transform: uppercase;
  @extend .caption-title-regular;
  margin-bottom: 30px;
  display: block;

  &.underline {
    position: relative;

    &::after {
      content: "";
      width: 42px;
      height: 1px;
      position: absolute;
      left: 0;
      background-color: $blue;
    }
  }

  &.top {
    &::after {
      top: -12px;
    }
  }

  &.bottom {
    &::after {
      bottom: -12px;
    }
  }
}

.arrow-list {
  list-style: none;

  li {
    margin-bottom: 15px;
    background-image: url(../../images/services/list-arrow.svg);
    background-repeat: no-repeat;
    padding-left: 20px;
    background-position: 0% 50%;

    a {
      color: $black;
      font-family: $akkuratProLight;
      font-size: 23px;
      line-height: 32px;
      padding-bottom: 6px;

      &:hover {
        color: $blue;
      }
    }
  }
}

@mixin small-title-label {
  font-family: $akkuratProRegular;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: $mobile) {
      font-size: clamp_scale(13px, $mobileReferenceWidth, $mobile);
      line-height: clamp_scale(20px, $mobileReferenceWidth, $mobile);
  }

  &.bold {
    font-weight: 500;
  }

  &.uppercase {
    letter-spacing: 0.56px;
    text-transform: uppercase;
  }
}

.small-title-label {
  @include small-title-label();
}

.down-arrow {
  &.animation {
    position: relative;
    animation: arrowAnimation 2s linear infinite;

    @keyframes arrowAnimation {
      0% {
        top: 0px;
      }

      25% {
        top: 5px;
      }

      50% {
        top: 0px;
      }

      75% {
        top: -5px;
      }

      100% {
        top: 0px;
      }
    }
  }
}

.side-link {
  position: absolute;
  left: -160px;
  top: calc(80% + 6px);
  transform: rotate(-90deg);
  color: $darkBlue;
  font-family: "Akkurat-Pro-Bold";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  &:hover {
    color: $blue;
  }
}

.caption-title-regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 142.857%; /* 20px at 14px font size */
  font-family: $akkuratProRegular;
}

.caption-title-light {
  font-size: 14px;
  font-weight: 300;
  line-height: 142.857%; /* 20px at 14px font size */
  font-family: $akkuratProLight;
}

@mixin contact-form-input {
    padding: 2px 16px;
    margin-bottom: 9px;
    color: $grey;
    background-color: $lightBlue;
    border: none;
    font-size: 18px;
    line-height: 40px;
    display: block;
    width: 100%;
    font-family: $akkuratProLight;

    &::placeholder{
      color: $grey;
    }
    &:focus{
      outline: none;
      border-bottom: 1px solid $blue;
      &::placeholder{
        color: transparent;
      }
    }
}

@media (min-width: 1200px) {
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }
  .order-3 {
    order: 3;
  }
  .order-4 {
    order: 4;
  }
  .order-5 {
    order: 5;
  }
}
@media (max-width: $laptop) and (min-width: 992px) {
  .order-laptop-0 {
    order: 0;
  }
  .order-laptop-1 {
    order: 1;
  }
  .order-laptop-2 {
    order: 2;
  }
  .order-laptop-3 {
    order: 3;
  }
  .order-laptop-4 {
    order: 4;
  }
  .order-laptop-5 {
    order: 5;
  }
  .order-laptop-6 {
    order: 6;
  }
}
@media (max-width: $tablet) and (min-width: 640px) {
  .order-tablet-0 {
    order: 0;
  }
  .order-tablet-1 {
    order: 1;
  }
  .order-tablet-2 {
    order: 2;
  }
  .order-tablet-3 {
    order: 3;
  }
  .order-tablet-4 {
    order: 4;
  }
  .order-tablet-5 {
    order: 5;
  }
  .order-tablet-6 {
    order: 6;
  }
  .order-tablet-7 {
    order: 7;
  }
  .order-tablet-8 {
    order: 8;
  }
}
@media (max-width: 640px) {
  .order-mobile-0 {
    order: 0;
  }
  .order-mobile-1 {
    order: 1;
  }
  .order-mobile-2 {
    order: 2;
  }
  .order-mobile-3 {
    order: 3;
  }
  .order-mobile-4 {
    order: 4;
  }
  .order-mobile-5 {
    order: 5;
  }
  .order-mobile-6 {
    order: 6;
  }
  .order-mobile-7 {
    order: 7;
  }
  .order-mobile-8 {
    order: 8;
  }
}
