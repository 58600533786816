.approach-hero-section {
    margin-top: 0;
    padding: 160px 0 220px;
    float: left;
    width: 100%;
    height: 100%;
    min-height: 670px;
    position: relative;
    z-index: 1;

    @media (max-width: $tablet) {
        padding: 100px 0 0px;
        margin-bottom: -20vh;
    }

    @media (max-width: $mobile) {
        padding: 50px 0 0px;
        margin-bottom: -15vh;
    }

    .scroll-container {
        height: 130vh;
        margin-top: -50vh;
    }

    .scroll-container .s-container {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: 85vh;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;

        @media (max-width: $tablet) {}
    }

    .container {
        height: 300px;
    }

    .inner-row {
        display: flex;
        align-items: center;

        @media (max-width: $tablet) {
            padding-bottom: 0vh;
        }

        .title-part {
            position: relative;
            min-height: 600px;
            display: flex;
            align-items: center;
            float: left;
            width: 50%;
            max-width: 100%;

            @media (max-width: $tablet) {
                max-width: 100%;
                width: 100%;
                height: auto;
                min-height: auto;
                margin-top: 0vh;
            }

            .inner {
                position: sticky;
                top: 310px;

                @media (max-width: $tablet) {
                    margin-bottom: 0vh;
                    position: unset;
                }
            }

            h2 {
                // Changed to h2 from h1, and commented out font related styles
                // that should be inherited from base.
                // font-family: $conduitITCStdMedium;
                // font-size: 85px;
                // color: $grey;
                max-width: 465px;

                // @media (max-width: 1439px) {
                //     font-size: 60px;
                // }

                // @media (max-width: $mobile) {
                //     font-size: 11.5vw;
                // }
            }
        }

        .right_box.r_boxox {
            text-align: right;
            height: 400px;
            // max-width: 50%;
            // width: 100%;
            float: left;
            width: 50%;
            max-width: 100%;

            @media (max-width: $tablet) {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }

            .content {

                .divCubeParts {
                    position: relative;
                    transform: none;

                    #cubeParts {
                        height: 1536px;
                        width: 350px;
                        position: relative;
                        transform: translateY(-34%);
                        left: 0;

                        @media (max-width: $tablet) {
                            width: 200px;
                            transform: translateY(-27%);
                        }
                    }

                    .animation-bottom-part {
                        position: relative;
                        top: -540px;
                        transition: all 0.5s;

                        @media (max-width: $laptop) {
                            display: none;
                        }

                        img {
                            position: absolute;
                            left: -251px;
                            top: -140px;

                            @media (max-width: 1439px) {
                                left: -285px;
                                top: -141px;
                            }
                        }
                    }
                }
            }
        }

        .right-box {
            width: 40%;
            float: right;
            padding-top: 0;
            height: 100vh;
            opacity: 1;
            transition: opacity 0.3s 0.25s ease-out;
            will-change: opacity;
        }

        .r_boxox {
            transform: translateY(-50px);
        }
    }
}

.approach-intro-section {
    position: relative;
    padding-bottom: 110px;

    .container {
        position: relative;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 950px;
        height: 600px;
        background-image: url(../../images/our-perspective/border_bottom_1.svg);
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: contain;
        opacity: 0;
        transition: $transition;
        transition-delay: 0.3s;
    }

    &.line-active {

        &::before {
            opacity: 1;
        }
    }

    @media (max-width: $laptop) {
        &.line-active {
            &::before {
                display: none;
            }
        }
    }

    @media (max-width: $tablet) {
        background: none;
    }

    .content {
        max-width: 540px;

        @media (max-width: $laptop) {
            padding-right: 20px;
        }

        // .banner-bottom-content {
        //     text-transform: none;
        // }
    }
}

.work-people-section {
    margin-top: 400px;
    max-width: 560px;

    @media (max-width: $laptop) {
        margin-top: 200px;
    }

    .title-intro {
        padding: 40px;
        background-color: $white;

        @media (max-width: $mobile) {
            padding: 20px 0;
        }
    }
}

.create-section {
    margin: 150px 0 80px;

    @media (max-width: $tablet) {
        margin: 80px 0 0;
    }

    .container {
        background-color: $midBlue;

        object {
            display: block;
            margin: 0px auto;

            &.create-shape-top {
                margin-left: 175px;

                @media (max-width: 1439px) {
                    width: 100%;
                    max-width: 50%;
                }

                @media (max-width: $laptop) {
                    margin-left: 75px;
                    margin-bottom: 10px;
                }

                @media (max-width: $tablet) {
                    max-width: 60%;
                    margin-left: 10px;
                }

                @media (max-width: $mobile) {
                    margin-left: 0px;
                    max-width: 100%;
                }
            }

            &.create-shape-bottom {
                margin-right: 50px;

                @media (max-width: 1439px) {
                    width: 100%;
                    max-width: 50%;
                    margin-right: 80px;
                }

                @media (max-width: $tablet) {
                    margin-right: 8px;
                    max-width: 52%;
                    margin-top: 5px;
                }

                @media (max-width: $mobile) {
                    max-width: 100%;
                }
            }
        }

        .content {
            max-width: 630px;
            margin: -45px auto -35px;

            @media (max-width: $tablet) {
                margin-top: -32px;
                max-width: 520px;
            }

            @media (max-width: $mobile) {
                max-width: 290px;
                margin: -30px auto;
                position: relative;
                padding: 50px 0;
            }

            h3 {
                color: $white;
                // commented out font styles that should be provided from base
                // font-size: 49px;

                @media (max-width: $tablet) {
                //     font-size: 40px;
                    margin-bottom: 22px;
                }

                // @media (max-width: $mobile) {
                //     font-size: 34px;
                // }
            }

            // .btn-small {

            //     &:not(:hover) {
            //         border-color: transparent;
            //         background-color: $white;
            //         color: $blue;
            //     }
            // }
        }
    }
}

.how-work-section {
    padding: 150px 0;
    background-color: #f5f9f9;
    background-image: linear-gradient(to top, #ffffff 0%, #f5f9f9 3%);

    @media (max-width: $tablet) {
        padding: 100px 0;
    }

    .sub-title {
        margin-bottom: -125px;
        max-width: 50%;

        &.sticky {
            position: sticky;
            top: 134px;
            background: linear-gradient(to bottom, #f5f9f9 75%, rgba(245, 249, 249, 0) 85%);
            padding: 50px 0;
            z-index: 2;

            @media (max-width: $tablet) {
                position: relative;
                top: 0;
                margin-bottom: 0px;
            }

            &::after {
                top: 35px;
            }
        }
    }

    .inner-row {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
        }

        .content {

            .bottom-shadow {
                background: linear-gradient(to bottom, rgba(245, 249, 249, 0), #f5f9f9 15% 100%);
                width: 100%;
                --heightSpace: 125px;
                height: var(--heightSpace);
                display: inline-block;
                position: sticky;
                top: calc(100% - var(--heightSpace));
                z-index: 2;
                
                @media (max-width: $tablet) {
                    display: none;
                }
            }

            .mobile-tab-image {
                max-width: 210px;
                margin: 50px auto;

                @media (min-width: 992px) {
                    display: none;
                }
            }

            .content-second {
                margin-top: 285px;

                &.fifth-content {
                    margin-bottom: 150px;

                    @media (max-width: $tablet) {
                        margin-bottom: 0px;                        
                    }
                }

                @media (max-width: $tablet) {
                    position: sticky;
                    top: 50px;
                    background-color: #f5f9f9;
                    padding-bottom: 100px;
                    padding-top: 50px;
                    margin-top: 100px;
                    min-height: 90vh;
                }

                @media (max-width: $mobile) {
                    position: unset;
                    margin-top: 0;
                    padding-top: 0;
                    padding-bottom: 50px;
                }

                h5 {
                    color: $blue;
                    margin-bottom: 60px;
                }

                .content-image {
                    position: relative;
                    margin-top: 100px;

                    @media (max-width: $mobile) {
                        margin-top: 40px;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        background-color: #467cc2;
                        clip-path: polygon(0 0, 63% 0, 100% 100%, 0% 100%);
                        opacity: .2;
                        max-width: 415px;
                        aspect-ratio: 1/1;

                        @media (max-width: $tablet) {
                            display: none;
                        }
                    }

                    .image {
                        position: relative;
                        z-index: 1;
                        transform: translateX(-50%);

                        @media (max-width: $tablet) {
                            transform: translateX(0%);
                            position: unset;
                        }
                    }

                    .image {
                        max-width: 250px;
                        left: 50%;
                        padding-top: 50px;

                        @media (max-width: $tablet) {
                            padding-top: 0;
                        }

                        @media (max-width: $mobile) {
                            max-width: 100%;
                        }


                        img {
                            width: 100%;

                            @media (max-width: $tablet) {
                                display: none;
                            }
                        }

                        .btn-primary {
                            display: inline-flex;
                            align-items: center;
                            gap: 10px;
                            width: 280px;
                            // color: $white;
                            position: absolute;
                            left: 50%;
                            top: 60%;

                            @media (max-width: $tablet) {
                                position: unset;
                            }

                            @media (max-width: $mobile) {
                                width: 100%;
                                justify-content: space-between;

                                span {
                                    line-height: 0;
                                }
                            }

                            svg#iconArrowReadMore {
                              fill: $pureBlack;
                              transition: all 0.3s;
                            }

                            &:hover svg#iconArrowReadMore {
                              fill: $white;
                              transition: all 0.3s;
                            }
                        }
                    }

                    .quote-container {
                      position: relative;
                      left: 65%;
                      max-width: 270px;
                      transform: translateX(-50%);
                    }

                    .content-quotes {
                        @extend .quote, .floatedQuote;
                        --space: 30px;
                        margin-top: var(--space);
                        padding-top: var(--space);
                        max-width: 270px;

                        @media (max-width: $tablet) {
                            display: none;
                        }

                        &::before {
                            @include floatedQuoteMark;
                        }
                    }

                    .quote-rule {
                        position: relative;
                        z-index: 1;
                    }

                    .author {
                        position: relative;
                        z-index: 1;
                        margin-top: 10px;
                        display: inline-block;

                        @media (max-width: $tablet) {
                            display: none;
                        }

                        .attribution-title {
                          @extend body;
                          text-transform: none;
                        }
                    }
                }

                &:nth-child(4) {

                    .content-image {

                        &::before {
                            background-color: #e2d44c;
                        }
                    }
                }

                &:nth-child(5) {

                    .content-image {

                        &::before {
                            background-color: #0faab7;
                        }
                    }
                }

                &:nth-child(6) {

                    .content-image {

                        &::before {
                            background-color: #61caf8;
                        }
                    }
                }
            }
        }

        .right-img {

            @media (max-width: $tablet) {
                display: none;
            }

            .spreated-brands {
                max-width: 350px;
                height: 350px;
                margin-left: auto;
                position: sticky;
                top: 50%;
                transform: translateY(-60%);
                margin-bottom: 250px;

                // @media (max-width: 1600px) {
                //     top: 135px;
                // }

                // @media (max-width: 1536px) {
                //     top: 205px;
                // }

                // @media (max-width: 1440px) {
                //     top: 190px;
                // }

                // @media (max-width: 1366px) {
                //     top: 170px;
                // }

                // @media (max-width: 1280px) {
                //     top: 140px;
                // }

                // @media (max-width: 1199px) {
                //     top: 150px;
                // }

                #cubeParts2 {
                    height: 1536px;
                    width: 350px;
                    position: relative;
                    transform: translateY(-31%);
                    left: 0;
                }
            }
        }
    }
}

.product-section {
    margin: 0 0 150px;

    .container {
        @media (max-width: $mobile) {
            padding: 0;
        }
    }

    .inner-row {
        display: grid;
        grid-template-columns: 1.3fr 1fr;
        align-items: center;
        background-color: $lightBlue;
        padding: 100px 50px;

        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
            gap: 50px;
            padding: 50px 30px;
        }

        .content {

            @media (max-width: $tablet) {
                order: 2;
            }

            h6 {
                // color: $blue;
                margin-top: 80px;
            }
        }

        .side-image {
            text-align: center;

            @media (max-width: $tablet) {
                order: 1;
            }

            img {

                @media (max-width: $mobile) {
                    max-width: 70%;
                }
            }
        }
    }
}

.about-experience {
    margin: 100px 0;

    .inner-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $mobile) {
            flex-direction: column-reverse;
            gap: 50px;
        }

        .content {
            max-width: 620px;
            border-left: 1px solid $nonphotoblue;
            padding: 25px 20px;

            @media (max-width: $tablet) {
                max-width: 400px;
            }

            // h4 {
            //     color: $blue;
            //     font-size: 36px;

            //     @media (max-width: $tablet) {
            //         font-size: 28px;
            //     }

            //     @media (max-width: $tablet) {
            //         font-size: 24px;
            //     }
            // }

            p {
                // font-size: 14px;
                margin-bottom: 10px;
            }

            a {
              @extend .caption-title-regular;
                // font-size: 14px;
                color: $darkBlue;

                svg {
                  fill: $darkBlue;
                  margin-right: 5px;
                }
            }
        }

        .side-image {
            padding: 25px 30px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                height: 100%;
                width: 75%;
                top: 0;
                right: 0;
                z-index: -1;
                background-color: $lightBlue;
            }

            img {
                width: 100%;
            }
        }
    }
}

.ipad-block {
    display: none;
}

// html,body{
//     min-height: 100%;
//     height: 100%;
// }