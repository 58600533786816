// Service Page
.page-sub-header-section {
  border-top: 1px solid $lightgrey;
  border-bottom: 1px solid $lightgrey;
  position: sticky;
  top: 166px;
  width: 100%;
  z-index: 3;
  background-color: $white;
  transition: $transition;

  &.sub-header-minimize {
    top: 90px;
  }

  @media (max-width: $laptop) {
    top: 140px;
  }

  @media (max-width: $tablet) {
    display: none;
  }

  .inner-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .label {
      display: flex;
      align-items: center;
      padding: 10px 0;

      span {
        color: $grey;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.56px;
        font-family: $akkuratProRegular;
        margin-top: 2px;
      }

      h5 {
        margin-bottom: 0;
      }

      .title {
        display: flex;

        h5:first-child {
          position: relative;
          color: $darkBlue;
          padding-right: 13px;
          margin-right: 12px;

          &::after {
            content: "";
            position: absolute;
            top: 4px;
            right: 0;
            width: 1px;
            height: 20px;
            background-color: $blue;
          }
        }
      }
    }

    .page-mega-menu {
      display: flex;

      .page-mega-menu-action {
        position: relative;
        padding: 10px 43px 10px 23px;
        border-right: 1px solid $lightgrey;
        border-left: 1px solid $lightgrey;
        cursor: pointer;
        transition: $transition;
        height: 100%;
        display: flex;
        align-items: center;
        font-family: $akkuratProRegular;

        span {
          color: $darkBlue;
          font-size: 14px;
          line-height: 20px;
          transition: $transition;
        }

        &::after {
          content: "";
          position: absolute;
          width: 13px;
          height: 7px;
          background-image: url(../../images/icons/common/dropdown-arrow.svg);
          background-size: 12px;
          background-repeat: no-repeat;
          right: 17px;
          top: 53%;
          transform: translateY(-50%);
        }

        .close {
          display: none;
        }

        &:hover {
          background-color: $blue;

          span {
            color: $white;
          }

          &::after {
            filter: brightness(100);
          }
        }
      }

      .mega-menu {
        background-color: $black;
        position: absolute;
        width: 100%;
        left: 0;
        top: calc(100% + 1px);
        padding: 20px 0;
        display: none;
        z-index: 2;
        overflow: hidden;
        border-bottom: 1px solid #5ecbe1;

        .container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          position: relative;

          @media (max-width: $mobile) {
            padding-right: 20px;
          }
        }

        span {
          color: #a3e1ee;
          margin-bottom: 22px;
          display: inline-block;
          text-transform: uppercase;
        }

        .menu {
          list-style: none;

          .menu-item {
            margin-bottom: 5px;

            .menu-link {
              color: $white;
              font-size: 15px;
              border-bottom: 1px solid transparent;
              font-family: $akkuratProRegular;

              &:hover {
                border-color: $blue;
              }
            }

            &.active {
              .menu-link {
                border-color: $blue;
              }
            }

            .sub-menu-main {
              background-color: $midBlue;
              padding: 20px 50px;
              position: absolute;
              top: -20px;
              left: calc(50% + 100px);
              width: 100%;
              height: calc(100% + 40px);
              display: none;

              @media (max-width: 1439px) {
                left: calc(35% + 100px);
              }

              @media (max-width: $laptop) {
                left: calc(20% + 100px);
              }

              h6 {
                color: #a3e1ee;
                font-size: 14px;
                font-family: $akkuratProRegular;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0;
              }

              .sub-menu {
                display: flex;
                gap: 30px;

                .menu {
                  min-width: max-content;
                  display: grid;
                  grid-template-columns: 50% 50%;
                  gap: 0 50px;

                  .menu-item {
                    margin-bottom: 5px;
                    padding: 5px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.service-design-sub {
    .label {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 0 5px;

      span {
        color: $darkBlue;
        margin-bottom: 2px;
        margin-top: 3px;
      }
    }

    .page-mega-menu {
      .page-mega-menu-action {
        padding: 24px 52px 15px 23px;

        &::after {
          right: 25px;
          top: 55%;
        }
      }

      .mega-menu {
        .menu-inner {
          display: flex;
          width: 100%;
          justify-content: end;

          .main-menu {
            max-width: 750px;
            width: 100%;

            @media (max-width: 1439px) {
              max-width: 900px;
            }

            .menu {
              .menu-item {
                padding: 10px;
                margin-bottom: 0px;
                cursor: pointer;
                border-color: transparent;

                .sub-menu-main {
                  cursor: default;

                  .sub-menu {
                    .menu {
                      .menu-item {
                        .menu-link {
                          padding-bottom: 5px;

                          &:hover {
                            border-color: $white;
                          }
                        }

                        &.active {
                          .menu-link {
                            border-color: $white;
                          }
                        }
                      }
                    }
                  }
                }

                .menu-link {
                  border-color: transparent;
                }

                &.active {
                  background-color: $midBlue;

                  .sub-menu-main {
                    display: block;
                    z-index: 2;
                  }
                }

                &:hover {
                  background-color: $midBlue;

                  .sub-menu-main {
                    display: block;
                    z-index: 3;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.active {
    background-color: $midBlue;
    border-color: #5ecbe1;

    .inner-row {
      .label {
        * {
          color: $white !important;
        }

        h5 {
          &::after {
            background-color: #ffffff !important;
          }
        }
      }

      .page-mega-menu {
        .page-mega-menu-action {
          border-color: #5ecbe1;

          span {
            color: $white;
          }

          .close {
            display: block;
          }

          .open {
            display: none;
          }

          &::after {
            filter: brightness(100);
            transform: translateY(-50%) rotate(180deg);
          }
        }

        .mega-menu {
          display: block;
        }
      }
    }
  }
}

.service-intro-section {
  background-color: #2e748c;
  background-image: url(../../images/services/akendiPattern.svg);
  padding: 70px 0px 80px;
  background-size: 331px 165.5px;
  background-position: center;

  @media (max-width: $mobile) {
    padding: 50px 0;
  }

  .intro-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: $mobile) {
      flex-direction: column;
      gap: 20px;
    }

    .description {
      max-width: 530px;
      color: $white;
      font-family: $akkuratProLight;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: normal;
      margin-right: 162px;
      margin-bottom: 0;

      @media (max-width: $tablet) {
        max-width: 50%;
        margin-right: 0px;
      }

      @media (max-width: $mobile) {
        max-width: 100%;
      }
    }

    .title {
      color: $white;
      text-transform: none;
      max-width: 350px;
      margin-bottom: 0px;
      letter-spacing: -0.28px;
    }
  }
}

.service-l1-hero-section {
  padding: 82px 0px 98px;

  @media (max-width: $mobile) {
    padding: 80px 0;
  }

  .row {
    display: flex;
    justify-content: space-between;
    position: relative;

    @media (max-width: $tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 100px;
    }

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
      gap: 100px;
    }

    &::after {
      content: "";
      position: absolute;
      height: 430px;
      width: 1px;
      background-color: $non-photo-blue;
      left: 51.3%;
      top: 54%;
      transform: translate(-50%, -50%) rotate(16deg);

      @media (max-width: $tablet) {
        transform: translate(-50%, -50%);
      }

      @media (max-width: $mobile) {
        display: none;
      }
    }

    .side-image {
      position: relative;
      max-width: 225px;

      @media (max-width: $mobile) {
        max-width: 50%;
      }

    }

    .content {
      @media (max-width: $laptop) {
        margin-top: 60px;
      }

      h6 {
        position: relative;
        margin-bottom: 28px;

        &::before {
          content: "";
          position: absolute;
          width: 26px;
          height: 1px;
          left: 0;
          top: -7px;
        }
      }

      h2 {
        margin-bottom: 23px;
      }
    }

    .side-link {
      position: absolute;
      left: -160px;
      top: calc(80% + 6px);
      transform: rotate(-90deg);
      color: $darkBlue;
      font-family: $akkuratProBold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.64px;
      text-transform: uppercase;

      &:hover {
        color: $blue;
      }
    }

    .service-left {
      max-width: 585px;
      width: 100%;
      display: flex;
      align-items: flex-start;

      @media (max-width: $laptop) {
        flex-direction: column;
        max-width: 350px;
      }

      .side-image {
        @media (min-width: 1200px) {
          top: 10px;
        }
      }

      .content {
        margin-left: -10px;

        h6 {
          &::before {
            background-color: #e16f26;
          }
        }
      }
    }

    .service-right {
      max-width: 465px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-top: 168px;
      margin-right: -20px;

      @media (max-width: $laptop) {
        flex-direction: column-reverse;
        max-width: 300px;
      }

      @media (max-width: $tablet) {
        margin-top: 0px;
      }

      .side-image {
        @media (min-width: 1200px) {
          top: 7px;
          left: -20px;
        }
      }

      .content {
        h6 {
          &::before {
            background-color: #1ab5d5;
          }
        }
      }
    }
  }
}

.service-bottom-section {
  h6 {
    color: $black;
    font-size: 23px;
    line-height: 24px;
    letter-spacing: 0.46px;
    margin-bottom: 13px;

    @media (max-width: $mobile) {
      margin-bottom: 30px;
    }
  }

  .listing {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    @media (max-width: $mobile) {
      grid-template-columns: repeat(1, 1fr);
    }

    li {
      &:not(:first-child) {
        @media (min-width: 767px) {
          border-left: 1px solid $blue;
          padding: 8px 0px 8px 24px;
        }
      }

      @media (max-width: $mobile) {
        border-left: 1px solid $blue;
        padding: 5px 0px 5px 10px;
        margin-bottom: 20px;
      }

      h5 {
        margin-bottom: 0px;
      }
    }
  }
}

.all-service-section {
  margin-top: 78px;

  .inner {
    background-color: $lightBlue;
    padding: 38px 57px 48px;

    @media (max-width: $tablet) {
      padding: 30px 40px;
    }

    @media (max-width: $mobile) {
      padding: 50px 20px;
    }

    h6 {
      text-align: center;
      margin-bottom: 43px;
    }

    .row {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 25px;
      margin-top: 40px;
      font-size: 14px;
      line-height: 20px;

      @media (max-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
        gap: 50px 25px;
      }

      @media (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
        gap: 50px;
      }

      .listing {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        font-family: $akkuratProRegular;

        span {
          margin-bottom: 9px;
          display: inline-block;
          color: $black;
          letter-spacing: 0.56px;
          text-transform: uppercase;
        }

        a {
          display: inline-block;
          color: $darkBlue;
          border-bottom: 1px solid transparent;

          &:hover {
            border-color: $darkBlue;
          }
        }
      }
    }
  }
}

.work-history-section {
  margin-top: 104px;
  margin-bottom: -28px;

  @media (max-width: $mobile) {
    margin-top: 80px;
  }

  .row {
    display: grid;
    grid-template-columns: 4fr 1fr;
    padding-top: 6px;

    @media (max-width: $laptop) {
      grid-template-columns: 3fr 1fr;
    }

    @media (max-width: $tablet) {
      grid-template-columns: 2fr 1fr;
      gap: 40px;
    }

    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &:not(:first-child) {
      margin-top: 63px;

      @media (max-width: $mobile) {
        margin-top: 100px;
      }
    }

    .content {
      max-width: 587px;

      p {
        color: $black;
        font-family: $akkuratProLight;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 27px;
      }

      h3 {
        margin-bottom: 42px;
        position: relative;
        margin-left: 2px;
        letter-spacing: 0.7px;

        &::after {
          content: "";
          position: absolute;
          width: 42px;
          height: 1px;
          background-color: $blue;
          left: 0px;
          bottom: -10px;
        }
      }
    }

    .side-image {
      min-height: 274px;
      padding-left: 55px;
      border-left: 1px solid $non-photo-blue;
      display: flex;

      img.two-images {
        max-width: 111px;
        width: 50%;
      }

      @media (max-width: $tablet) {
        img {
          max-width: 120px;
          width: 100%;
        }

        img.two-images {
          max-width: 111px;
          width: 50%;
        }
      }

      @media (max-width: $mobile) {
        padding-left: 0px;
        border: none;
        min-height: auto;
      }
    }
  }
}

// Service Design
.service-header {
  margin: 0;
  height: 552px;
  padding: 51px 0 0;
  position: relative;
  background-color: #eff9fb;

  .container {
    position: relative;
  }

  .get-touch {
    top: 292px;
    left: -139px;
    color: $darkBlue;
    position: absolute;
    transform: rotate(-90deg);
    font-family: $akkuratProBold;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.64px;
    text-transform: uppercase;

    &:hover {
      color: $blue;
    }
  }

  .service-head-intro {
    display: flex;
    margin: 0 auto;
    max-width: 660px;
    text-align: center;
    flex-direction: column;

    @media (max-width: 767px) {
      margin: 0 auto 30px;
    }

    h2, .typography-h2 {
      margin-bottom: 16px;
    }

    h3 {
      font-weight: 300;
      margin: 0 0 25px;
      text-transform: capitalize;
    }

    p, .description {
      color: $black;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      line-height: 133.333%;
      font-family: $akkuratProLight;
    }
  }

  .service-head-img {
    left: 21px;
    top: 190px;
    width: 652px;
    height: 390px;
    display: flex;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-color: $americansilver;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      top: 0;
      left: 0;
      width: 100%;
      position: inherit;
    }

    .service-head-bg-image {
      position: absolute;
      overflow: hidden;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      img {
        width: 100%;
        height: max-content;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    .service-head-content {
      bottom: 38px;
      right: -86px;
      display: flex;
      min-width: 530px;
      position: absolute;
      flex-direction: column;
      padding: 30px 38px 16px;
      justify-content: center;
      background-color: rgba(13, 110, 138, 0.9);

      @media (max-width: 991px) {
        left: 0;
        right: 0;
        bottom: -50px;
        min-width: 90%;
        margin: 0 auto;
      }

      @media (max-width: 767px) {
        width: 90%;
        padding: 30px;
        min-width: 80%;
      }

      h6 {
        color: $white;
        margin-bottom: 21px;
        letter-spacing: 0.46px;
        text-transform: uppercase;
        font-family: $conduitITCStdMedium;
      }

      ul {
        padding-left: 30px;

        li {
          color: $white;
          font-size: 18px;
          margin: 0 0 10px;
          font-weight: 500;
          line-height: 133.333%;
          font-family: $akkuratProLight;
        }
      }
    }
  }
}

.service-approaches {
  padding: 178px 0 59px;

  @media (max-width: 767px) {
    padding: 250px 0 70px;
  }

  .service-approach-intro {
    display: flex;
    max-width: 460px;
    margin: 0 0 27px;

    h5, .typography-h5 {
      color: $grey;
    }
  }

  .approaches-services {
    .service-sub {
      display: flex;
      align-items: flex-start;
      gap: 57px;

      @media (max-width: 1439px) {
        gap: 37px;
      }

      @media (max-width: $laptop) {
        flex-direction: column;
      }

      &.desire {
        .title {
          h4, .typography-h4 {
            &::after {
              background-color: $desire;
            }
          }
        }

        .content-main {
          .side-image {
            top: -13px;
          }
        }
      }

      &.deepsaffron {
        .title {
          h4, .typography-h4 {
            &::after {
              background-color: $deepsaffron;
            }
          }

          .side-image {
            left: -2px;
            top: 3px;
          }
        }
      }

      &.green {
        .title {
          h4, .typography-h4 {
            &::after {
              background-color: $green;
            }
          }
        }
      }

      &.blue {
        .title {
          h4, .typography-h4 {
            &::after {
              background-color: $blue;
            }
          }
        }
      }

      &.purple {
        .title {
          h4, .typography-h4 {
            &::after {
              background-color: $purple;
            }
          }
        }
      }

      .title {
        max-width: 350px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 38px;

        @media (max-width: 1439px) {
          max-width: 300px;
          gap: 28px;
        }

        @media (max-width: $laptop) {
          max-width: 200px;
        }

        @media (max-width: $tablet) {
          max-width: 130px;
        }

        h4, .typography-h4 {
          position: relative;

          &::after {
            content: "";
            left: 0;
            position: absolute;
            bottom: -5px;
            width: 27px;
            height: 4px;
            opacity: 0.8;
          }
        }
      }

      .content-main {
        display: flex;
        align-items: flex-start;
        gap: 57px;
        max-width: 793px;
        width: 100%;

        @media (max-width: 1439px) {
          gap: 37px;
        }

        @media (max-width: $laptop) {
          max-width: 100%;
        }

        .inner {
          margin-bottom: 32px;
          border-bottom: 1px solid $non-photo-blue;

          .content {
            max-width: 590px;
            width: 100%;
            position: relative;
            padding-right: 72px;
            padding-bottom: 32px;

            @media (max-width: $laptop) {
              max-width: 100%;
            }

            .sub-title {
              margin-bottom: 11px;
              transition: $transition;
              cursor: pointer;

              a.title {
                display: inline-block;
                max-width: fit-content;
                color: $black;

                &:hover {
                  color: $blue;
                }
              }

              a:not(.title) {
                color: $darkBlue;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.56px;
                text-transform: uppercase;
                border-left: 1px solid $blue;
                padding-left: 8px;
                margin-left: 5px;
                font-family: $akkuratProRegular;
              }

              &:hover {
                color: $blue;
              }
            }

            .link-arrow {
              position: absolute;
              right: 0;
              bottom: 24px;
              aspect-ratio: 48/48;

              .hover-arrow {
                position: absolute;
                opacity: 0;
                left: 0;
                transition: $transition;
              }

              &:hover {
                .hover-arrow {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .side-image {
        max-width: 146px;
        width: 100%;
        position: relative;

        img {
          width: 100%;
        }

        @media (max-width: $laptop) {
          display: none;
        }
      }
    }
  }
}

.latest-posts {
  padding: 50px 0px;

  &.custom-space {
    padding: 100px 0 200px;
  }

  .latest-main-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 58px;

    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
      gap: clamp_scale(40px, $mobileReferenceWidth, $mobile);

      a {
        align-self: stretch;
        text-align: center;
      }
    }
  }

  .title {
    position: relative;

    &:after {
      left: 0;
      width: 42px;
      content: "";
      height: 1px;
      bottom: -18px;
      position: absolute;
      background-color: $BatteryChargedBlue;
    }
  }

  .latest-posts-row {
    @media (min-width: 992px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }

    .latest-posts-box {
      height: auto;

      @media (max-width: $tablet) {
        padding-bottom: 50px;
      }

      @media (max-width: $mobile) {
        padding-bottom: 10px;
      }
    }

    .slick-arrow {
      display: none !important;
    }

    .slick-dots {
      text-align: left;
      bottom: -40px;

      li {
        button {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #eeeeee;
          font-size: 0;
          overflow: hidden;
          padding: 0;

          &::before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background-color: $blue;
          }
        }
      }
    }
  }

  .latest-post-bg {
    height: 235px;
    display: block;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgb(0, 152, 185, 0.3);

    @media (max-width: 767px) {
      margin: 0 0 50px;
    }

    &:after {
      content: "";
      background-color: $blue;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0;
      opacity: 0.3;
      transition: $transition;
    }

    &:hover {
      &::after {
        opacity: 0;
      }

      .latest-post-title {
        h6 {
          color: $blue;
          transform: $transition;
        }
      }
    }

    .latest-post-info {
      left: 0;
      right: 0;
      z-index: 1;
      padding: 32px 20px 20px;
      width: 77%;
      height: 225px;
      bottom: -30px;
      margin: 0 auto;
      position: absolute;
      background: rgba(231, 245, 250, 0.9);
    }

    .latest-post-author {
      @extend .caption-title-regular;
      display: flex;
      color: $black;
      margin: 0 0 22px;
      align-items: center;
      justify-content: end;

      span {
        width: 32px;
        height: 32px;
        margin-left: 11px;
        border-radius: 100%;
        display: inline-block;
        background-size: 100%;
      }
    }

    .latest-post-title {
      h6 {
        color: $black;
        line-height: 104.348%;
        letter-spacing: 0.46px;
        font-family: $conduitITCStdMedium;
        transition: $transition;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}

.service-l3-hero-section {
  padding: 51px 0 102px;

  .container {
    position: relative;
  }

  .inner-row {
    display: grid;
    grid-template-columns: 1.07fr 1fr;
    gap: 100px;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
    }

    .side-image {
      .side-bg-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        img {
          width: 100%;
          height: max-content;
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }

      @media (max-width: $tablet) {
        aspect-ratio: 530/388;
        width: 100%;
        object-fit: cover;
      }
    }

    .content {
      position: relative;

      h2, .typography-h2 {
        margin-bottom: 39px;
      }

      h4, .typography-h4 {
        color: $blue;
        margin-bottom: 32px;
        line-height: 36px;
        letter-spacing: -0.28px;
      }

      p {
        margin-bottom: 28px;
      }

      .service-pdf {
        display: inline-block;

        .label {
          border-right: 1px solid $blue;
          margin-right: 10px;
          padding-right: 13px;
          letter-spacing: 0.16px;
        }

        .pdf {
          padding-right: 20px;
          background-image: url(../../images/services/download.svg);
          background-repeat: no-repeat;
          background-position: center right;
        }

        span,
        a {
          @extend .small-title-label;
          font-size: 14px;
          line-height: 20px;
          display: inline-block;

          &:not(:hover) {
            color: $darkBlue;
          }
        }

        &:hover {

          a,
          span {
            color: $blue;
          }

          .pdf {
            background-image: url(../../images/services/download-hover.svg);
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: 0px;
        bottom: -56px;
        max-width: 551px;
        width: 100%;
        height: 0.86px;
        background-color: $non-photo-blue;
      }
    }

    .side-image {
      background-size: cover;
      background-position: center;
      position: relative;

      .image-content {
        background-color: $darkBlue;
        color: $white;
        max-width: 324px;
        padding: 37px 32px 47px 32px;
        position: absolute;
        bottom: -58px;
        right: 25px;

        @media (max-width: $mobile) {
          bottom: -140px;
          right: 13px;
        }

        h6 {
          color: $white;
          margin-bottom: 28px;
        }

        ul {
          padding-left: 21px;

          li {
            color: $white;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 16px;
            letter-spacing: 0.17px;
          }
        }
      }
    }
  }

  .side-link {
    position: absolute;
    left: -139px;
    top: 70%;
    transform: rotate(-90deg);
    color: $darkBlue;
    font-family: $akkuratProBold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.64px;
    text-transform: uppercase;

    &:hover {
      color: $blue;
    }
  }
}

.how-do-section {
  @media (max-width: $tablet) {
    margin-top: 60px;
  }

  @media (max-width: $mobile) {
    margin-top: 100px;
  }

  .inner-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;

    @media (max-width: $tablet) {
      grid-template-columns: 1fr;
      gap: 100px;
    }

    .how-do-section-inner {
      .content {
        display: flex;
        flex-direction: column;
        height: 100%;

        h3 {
          margin-bottom: 24px;
        }

        .number-listing {
          max-width: 460px;
          list-style: none;

          @media (max-width: $tablet) {
            max-width: 100%;
            margin-bottom: 50px;
          }

          li {
            margin-bottom: 24px;
            display: flex;
            align-items: flex-start;
            gap: 24px;

            span {
              color: $lightgrey;
              font-family: $conduitITCStdMedium;
              font-size: 86px;
              font-weight: 700;
              line-height: 88px;
              text-transform: uppercase;
            }

            p {
              margin-top: 8px;
            }
          }
        }

        .learn-mor-grp {
          margin-top: auto;
          margin-bottom: 4px;

          h6 {
            margin-bottom: 12px;
          }
        }
      }
    }

    .what-get-section {
      .image-group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        max-width: 531px;
        margin-left: auto;
        margin-top: 4px;

        img {
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
        }
      }

      .content {
        max-width: 488px;
        margin-top: 74px;
        margin-left: auto;

        @media (max-width: $tablet) {
          max-width: 100%;
        }

        @media (max-width: $tablet) {
          margin-top: 50px;
        }

        p {
          color: $black;
          font-family: $akkuratProLight;
          font-size: 23px;
          font-weight: 500;
          line-height: 32px;
          margin-bottom: 32px;
        }

        ul {
          list-style: none;
          max-width: 384px;

          @media (max-width: $tablet) {
            max-width: 100%;
          }

          li {
            background-image: url(../../images/icons/common/check.svg);
            background-repeat: no-repeat;
            background-size: 39px;
            background-position: left top;
            color: $black;
            font-family: $akkuratProLight;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            padding-left: 55px;

            &:not(:last-child) {
              margin-bottom: 26px;
              padding-bottom: 21px;
              border-bottom: 1px solid $non-photo-blue;
            }
          }
        }
      }
    }
  }
}

.icon-link-section {
  margin: 140px 0 0;

  h6 {
    margin-bottom: 85px;
  }

  .inner-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 801px;
    gap: 42px;
    margin-left: 22px;

    @media (max-width: $tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $mobile) {
      gap: 50px 30px;
    }

    .link-box {
      padding-right: 40px;

      @media (min-width: 992px) {
        &:not(:last-child) {
          border-right: 1px solid $non-photo-blue;
        }
      }

      @media (max-width: $tablet) {
        &:nth-child(odd) {
          border-right: 1px solid $non-photo-blue;
        }
      }

      @media (max-width: $mobile) {
        padding: 0 20px;
      }

      img {
        max-width: 60px;
        width: 100%;
        aspect-ratio: 1/1;
      }

      h5 {
        color: $black;
        margin: 17px 0 0;
        position: relative;
        transition: $transition;

        &::after {
          content: "";
          position: absolute;
          left: -22px;
          top: 9px;
          background-image: url(../../images/services/list-arrow.svg);
          width: 14px;
          height: 12px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &:hover {
        h5 {
          color: $blue;
        }
      }
    }
  }
}

.approach-service-section {
  margin: 145px 0 99px;
  position: relative;

  .inner-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: $tablet) {
      flex-direction: column;
      gap: 80px;
    }
  }

  .approach {
    max-width: 488px;
    width: 100%;

    @media (min-width: 992px) {
      position: sticky;
      top: 110px;
    }

    .image-title {
      display: flex;
      align-items: center;
      max-width: 315px;
      gap: 24px;
      margin: 50px 0 31px;

      img {
        max-width: 112px;
        width: 100%;
      }

      span {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.56px;
        font-family: $akkuratProRegular;
        text-transform: uppercase;
      }
    }

    .btn-primary {
      margin-top: 31px;
    }
  }

  .service {
    max-width: 337px;
    width: 100%;
    margin-right: 50px;

    @media (max-width: $tablet) {
      max-width: 100%;
    }

    h6 {
      margin-bottom: 61px;
    }

    .list-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;

      @media (max-width: $tablet) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: $mobile) {
        grid-template-columns: 1fr;
      }

      .service-list {
        h4 {
          margin-bottom: 20px;
          position: relative;

          &::after {
            content: "";
            left: 0;
            position: absolute;
            bottom: -5px;
            width: 27px;
            height: 4px;
          }
        }

        &.desire {
          h4 {
            &::after {
              background-color: $desire;
            }
          }

          .arrow-list {
            li {
              &.active {
                &::after {
                  background-color: $desire;
                }
              }
            }
          }
        }

        &.deepsaffron {
          h4 {
            &::after {
              background-color: $deepsaffron;
            }
          }

          .arrow-list {
            li {
              &.active {
                &::after {
                  background-color: $deepsaffron;
                }
              }
            }
          }
        }

        &.green {
          h4 {
            &::after {
              background-color: $green;
            }
          }

          .arrow-list {
            li {
              &.active {
                &::after {
                  background-color: $green;
                }
              }
            }
          }
        }

        &.blue {
          h4 {
            &::after {
              background-color: $blue;
            }
          }

          .arrow-list {
            li {
              &.active {
                &::after {
                  background-color: $blue;
                }
              }
            }
          }
        }

        &.purple {
          h4 {
            &::after {
              background-color: $purple;
            }
          }

          .arrow-list {
            li {
              &.active {
                &::after {
                  background-color: $purple;
                }
              }
            }
          }
        }

        .arrow-list {
          li {
            padding: 4px 0 4px 32px;
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0px;
            }

            &.active {
              padding: 8px 32px 4px;
              position: relative;
              background: none;

              &::after {
                content: "";
                width: 100%;
                height: 100%;
                background: var(--bgColor);
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                opacity: 0.15;
              }

              a {
                color: $black;
                border: none;
              }
            }
          }
        }
      }
    }
  }
}

.selected-projects-section {
  margin: 100px 0;

  .title-part {
    margin-bottom: 60px;
  }

  .inner {
    @media (min-width: 1200px) {
      display: grid;
      grid-template-columns: 58.7% 40.6%;
      gap: 10px;
    }

    .project-row {
      @media (min-width: 992px) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
      }

      @media (max-width: $laptop) {
        margin-bottom: 10px;
      }

      @media (max-width: $tablet) {
        margin-bottom: 60px;
      }

      .project-link {
        display: inline-block;
        position: relative;
        width: 100%;
        // max-height: 165px;
        height: 100%;

        @media (max-width: $tablet) {
          height: auto;
          max-height: none;
        }

        img {
          width: 100%;
          height: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
          display: flex;

          @media (max-width: $tablet) {
            height: auto;
          }
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgb(0 0 0 / 75%);
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 20px;
          opacity: 0;
          transition: $transition;

          @media (max-width: $tablet) {
            opacity: 1;
            bottom: 0;
            top: auto;
            height: 30%;
            gap: 10px;
          }

          p {
            color: $white;
            position: relative;
            text-transform: uppercase;
            font-family: $akkuratProRegular;
            word-wrap: break-word;
          }

          span {
            color: $white;
            font-size: 14px;
            line-height: 20px;
            font-family: $akkuratProRegular;
          }
        }

        @media (min-width: 992px) {
          &:hover {
            .overlay {
              opacity: 1;
            }
          }
        }
      }

      .slick-arrow {
        display: none !important;
      }

      .slick-dots {
        text-align: left;
        bottom: -40px;

        li {
          button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #eeeeee;
            font-size: 0;
            overflow: hidden;
            padding: 0;

            &::before {
              display: none;
            }
          }

          &.slick-active {
            button {
              background-color: $blue;
            }
          }
        }
      }
    }

    .completed-project {
      background-color: $darkBlue;
      background-image: url(../../images/services/akendiPattern.svg);
      background-size: contain;
      background-position: center;
      padding: 15px 40px 15px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 1439px) {
        padding: 12px 40px 12px 20px;
      }

      @media (max-width: $laptop) {
        min-height: 222px;
      }

      @media (max-width: $tablet) {
        padding: 50px 40px;
      }

      @media (max-width: $mobile) {
        padding: 50px 20px;
      }

      .number {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2px;

        @media (max-width: $tablet) {
          margin-bottom: 20px;
        }

        h2 {
          color: $white;
          margin-bottom: 0;
          font-size: 69px;
          letter-spacing: 2.07px;

          @media (max-width: $mobile) {
            font-size: 50px;
          }

          span {
            font-size: 56px;
            position: relative;
            top: -11px;
            left: -7px;

            @media (max-width: $mobile) {
              font-size: 40px;
            }
          }
        }

        .btn-default-arrow {
          color: $white;
          top: 4px;

          @media (max-width: $mobile) {
            left: -20px;
          }

          &::after {
            filter: brightness(20);
          }

          &::before {
            content: "";
            width: 1px;
            height: 55px;
            background-color: #ffffff;
            position: absolute;
            left: -17px;
            top: -18px;
          }
        }
      }

      p {
        color: $white;
      }
    }
  }
}

.how-we-work-section {
  margin-top: 50px;

  .inner-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #f6f6f5;
    padding: 64px 29px 8px 68px;

    @media (max-width: $tablet) {
      padding: 60px;
      flex-direction: column;
      gap: 50px;
    }

    @media (max-width: $mobile) {
      padding: 30px;
    }

    .title-content {
      max-width: 350px;
      width: 100%;

      @media (max-width: $tablet) {
        max-width: 100%;
      }

      h3 {
        margin-bottom: 44px;

        @media (max-width: $mobile) {
          margin-bottom: 20px;
        }
      }

      p {
        line-height: 26px;
      }
    }

    .link-list {
      max-width: 678px;
      width: 100%;

      @media (max-width: $tablet) {
        max-width: 100%;
      }

      ul {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 9px 95px;
        max-width: 430px;
        width: 100%;
        margin-bottom: -38px;
        margin-left: 103px;
        margin-top: 4px;
        position: relative;
        z-index: 1;

        @media (max-width: $laptop) {
          gap: 10px 20px;
          margin-left: 80px;
        }

        @media (max-width: $tablet) {
          max-width: 100%;
          margin: 0px auto;
        }

        li {
          z-index: 1;
        }
      }

      a {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        font-family: $akkuratProRegular;
        color: $darkBlue;
        border-bottom: 1px solid transparent;

        &:hover {
          border-color: $darkBlue;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}

.way-to-work-section {
  margin: 98px 0px 148px;

  .inner-row {
    background-color: $lightBlue;
    padding: 61px 56px 66px 63px;

    @media (max-width: $mobile) {
      padding: 60px 20px;
    }

    h3 {
      margin-bottom: 93px;

      @media (max-width: $mobile) {
        margin-bottom: 44px;
      }
    }

    .work-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 96px 58px;

      @media (max-width: $mobile) {
        grid-template-columns: 1fr;
        gap: 50px;
      }

      .col-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 35px;

        @media (max-width: $laptop) {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        h6 {
          margin-bottom: 36px;

          @media (max-width: $mobile) {
            margin-bottom: 16px;
          }
        }

        img {
          max-width: 232px;
          width: 100%;
        }
      }
    }
  }
}